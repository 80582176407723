import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const Deseoso = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])

    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <img src="https://app.sonria.tv/img/deseo-agrado.jpg" width="70px"></img><br/>
                <h4>Agrado</h4>
                <b>¿Cuál es el Sentido de la Emoción que se nos dispara?</b><br/>
                El apego y el miedo, brotan de la imaginación. Confundimos la experiencia 
                interna de "gozo" con un grupo de circunstancias o factores llamados 
                "placer".

                    <br/><br/>
        
                <p className="peque"><i><b>Raíz de la palabra: </b>
                A (abordaje a la Acción). Grado (agradable Grato), del latín Gratus.</i></p>
                <br/>
                <NavLink className="btn btn-info" href="/">Regresar</NavLink>
                </>
                )
            }
        </div>
        </main>
    )
}

export default withRouter(Deseoso)