import React from 'react'
import {db} from '../firebase'
import moment from 'moment'
import 'moment/locale/es'
import '../index.css';

const Firestore = (props) => {

    const [metas, setMetas] = React.useState([])
    const [meta, setMeta] = React.useState('')
    const [tresMeses, setTresMeses] = React.useState('')
    const [accionUno, setAccionUno] = React.useState('')
    const [taskforceUno, setTaskforceUno] = React.useState('')
    const [estrategiaUno, setEstrategiaUno] = React.useState('')
    const [aprendizajeUno, setAprendizajeUno] = React.useState('')
    const [relacionUno, setRelacionUno] = React.useState('')
    const [logroUno, setLogroUno] = React.useState('')
    const [recursosUno, setRecursosUno] = React.useState('')
    const [accionDos, setAccionDos] = React.useState('')
    const [taskforceDos, setTaskforceDos] = React.useState('')
    const [estrategiaDos, setEstrategiaDos] = React.useState('')
    const [aprendizajeDos, setAprendizajeDos] = React.useState('')
    const [relacionDos, setRelacionDos] = React.useState('')
    const [logroDos, setLogroDos] = React.useState('')
    const [recursosDos, setRecursosDos] = React.useState('')
    const [accionTres, setAccionTres] = React.useState('')
    const [taskforceTres, setTaskforceTres] = React.useState('')
    const [estrategiaTres, setEstrategiaTres] = React.useState('')
    const [aprendizajeTres, setAprendizajeTres] = React.useState('')
    const [relacionTres, setRelacionTres] = React.useState('')
    const [logroTres, setLogroTres] = React.useState('')
    const [recursosTres, setRecursosTres] = React.useState('')
    const [modoEdicion, setModoEdicion] = React.useState(false)
    const [mensaje, setMensaje] = React.useState(null)
    const [id, setId] = React.useState('')
  
  
    React.useEffect(() => {
  
      const obtenerDatos = async () => {
  
        try {
  
          const data = await db.collection("metas:" + props.user.email).get()
          const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          console.log(arrayData)
          setMetas(arrayData)
          
        } catch (error) {
          console.log(error)
        }
  
      }
  
      obtenerDatos()
  
    }, [props.user.email])
  
    const agregar = async (e) => {
      e.preventDefault()
  
      if(!meta.trim()){
        setMensaje('Ingrese Meta')
        return
      }
      if(!tresMeses.trim()){
        setMensaje('Completa la fecha a 3 meses')
        return
      }
      if(!accionUno.trim()){
        setMensaje('Completa la accion 1')
        return
      }
      if(!taskforceUno.trim()){
        setMensaje('Completa el taskforce 1')
        return
      }
      if(!estrategiaUno.trim()){
        setMensaje('Completa la estrategia 1')
        return
      }
      if(!aprendizajeUno.trim()){
        setMensaje('Completa el aprendizaje 1')
        return
      }
      if(!relacionUno.trim()){
        setMensaje('Completa la relación 1')
        return
      }
      if(!logroUno.trim()){
        setMensaje('Completa el logro 1')
        return
      }
      if(!recursosUno.trim()){
        setMensaje('Completa los recursos 1')
        return
      }
      if(!accionDos.trim()){
        setMensaje('Completa la accion 2')
        return
      }
      if(!taskforceDos.trim()){
        setMensaje('Completa el taskforce 2')
        return
      }
      if(!estrategiaDos.trim()){
        setMensaje('Completa la estrategia 2')
        return
      }
      if(!aprendizajeDos.trim()){
        setMensaje('Completa el aprendizaje 2')
        return
      }
      if(!relacionDos.trim()){
        setMensaje('Completa la relación 2')
        return
      }
      if(!logroDos.trim()){
        setMensaje('Completa el logro 2')
        return
      }
      if(!recursosDos.trim()){
        setMensaje('Completa los recursos 2')
        return
      }
      if(!accionTres.trim()){
        setMensaje('Completa la accion 3')
        return
      }
      if(!taskforceTres.trim()){
        setMensaje('Completa el taskforce 3')
        return
      }
      if(!estrategiaTres.trim()){
        setMensaje('Completa la estrategia 3')
        return
      }
      if(!aprendizajeTres.trim()){
        setMensaje('Completa el aprendizaje 3')
        return
      }
      if(!relacionTres.trim()){
        setMensaje('Completa la relación 3')
        return
      }
      if(!logroTres.trim()){
        setMensaje('Completa el logro 3')
        return
      }
      if(!recursosTres.trim()){
        setMensaje('Completa los recursos 3')
        return
      }

  
      try {
  
        const nuevaMeta = {
          name: meta,
          tresMeses: tresMeses,
          accionUno: accionUno,
          taskforceUno: taskforceUno,
          estrategiaUno: estrategiaUno,
          aprendizajeUno: aprendizajeUno,
          relacionUno: relacionUno,
          logroUno: logroUno,
          recursosUno: recursosUno,
          accionDos: accionDos,
          taskforceDos: taskforceDos,
          estrategiaDos: estrategiaDos,
          aprendizajeDos: aprendizajeDos,
          relacionDos: relacionDos,
          logroDos: logroDos,
          recursosDos: recursosDos,
          accionTres: accionTres,
          taskforceTres: taskforceTres,
          estrategiaTres: estrategiaTres,
          aprendizajeTres: aprendizajeTres,
          relacionTres: relacionTres,
          logroTres: logroTres,
          recursosTres: recursosTres,
          fecha: Date.now()
        }
        const data = await db.collection("metas:" + props.user.email).add(nuevaMeta)
  
        setMetas([
          ...metas,
          {...nuevaMeta, id: data.id}
        ])
  
        setMeta('')
        setTresMeses('')
        setAccionUno('')
        setTaskforceUno('')
        setEstrategiaUno('')
        setAprendizajeUno('')
        setRelacionUno('')
        setLogroUno('')
        setRecursosUno('')
        setAccionDos('')
        setTaskforceDos('')
        setEstrategiaDos('')
        setAprendizajeDos('')
        setRelacionDos('')
        setLogroDos('')
        setRecursosDos('')
        setAccionTres('')
        setTaskforceTres('')
        setEstrategiaTres('')
        setAprendizajeTres('')
        setRelacionTres('')
        setLogroTres('')
        setRecursosTres('')
        setMensaje('La meta fue agregada. Ver arriba.')
        
      } catch (error) {
        console.log(error)
      }
  
      console.log(meta)
    }
  
    const eliminar = async (id) => {
      try {
        
        await db.collection("metas:" + props.user.email).doc(id).delete()
  
        const arrayFiltrado = metas.filter(item => item.id !== id)
        setMetas(arrayFiltrado)
  
      } catch (error) {
        console.log(error)
      }
    }
  
    const activarEdicion = (item) => {
      setModoEdicion(true)
      setMeta(item.name)
      setTresMeses(item.tresMeses)
      setAccionUno(item.accionUno)
      setTaskforceUno(item.taskforceUno)
      setEstrategiaUno(item.estrategiaUno)
      setAprendizajeUno(item.aprendizajeUno)
      setRelacionUno(item.relacionUno)
      setLogroUno(item.logroUno)
      setRecursosUno(item.recursosUno)
      setAccionDos(item.accionDos)
      setTaskforceDos(item.taskforceDos)
      setEstrategiaDos(item.estrategiaDos)
      setAprendizajeDos(item.aprendizajeDos)
      setRelacionDos(item.relacionDos)
      setLogroDos(item.logroDos)
      setRecursosDos(item.recursosDos)
      setAccionTres(item.accionTres)
      setTaskforceTres(item.taskforceTres)
      setEstrategiaTres(item.estrategiaTres)
      setAprendizajeTres(item.aprendizajeTres)
      setRelacionTres(item.relacionTres)
      setLogroTres(item.logroTres)
      setRecursosTres(item.recursosTres)
      setId(item.id)
    }
  
    const editar = async (e) => {
      e.preventDefault()
      if(!meta.trim()){
        setMensaje('Completa la Meta')
        return
      }
      if(!tresMeses.trim()){
        setMensaje('Completa la fecha a 3 meses')
        return
      }
      if(!accionUno.trim()){
        setMensaje('Completa la accion 1')
        return
      }
      if(!taskforceUno.trim()){
        setMensaje('Completa el taskforce 1')
        return
      }
      if(!estrategiaUno.trim()){
        setMensaje('Completa la estrategia 1')
        return
      }
      if(!aprendizajeUno.trim()){
        setMensaje('Completa el aprendizaje 1')
        return
      }
      if(!relacionUno.trim()){
        setMensaje('Completa la relación 1')
        return
      }
      if(!logroUno.trim()){
        setMensaje('Completa el logro 1')
        return
      }
      if(!recursosUno.trim()){
        setMensaje('Completa los recursos 1')
        return
      }
      if(!accionDos.trim()){
        setMensaje('Completa la accion 2')
        return
      }
      if(!taskforceDos.trim()){
        setMensaje('Completa el taskforce 2')
        return
      }
      if(!estrategiaDos.trim()){
        setMensaje('Completa la estrategia 2')
        return
      }
      if(!aprendizajeDos.trim()){
        setMensaje('Completa el aprendizaje 2')
        return
      }
      if(!relacionDos.trim()){
        setMensaje('Completa la relación 2')
        return
      }
      if(!logroDos.trim()){
        setMensaje('Completa el logro 2')
        return
      }
      if(!recursosDos.trim()){
        setMensaje('Completa los recursos 2')
        return
      }
      if(!accionTres.trim()){
        setMensaje('Completa la accion 3')
        return
      }
      if(!taskforceTres.trim()){
        setMensaje('Completa el taskforce 3')
        return
      }
      if(!estrategiaTres.trim()){
        setMensaje('Completa la estrategia 3')
        return
      }
      if(!aprendizajeTres.trim()){
        setMensaje('Completa el aprendizaje 3')
        return
      }
      if(!relacionTres.trim()){
        setMensaje('Completa la relación 3')
        return
      }
      if(!logroTres.trim()){
        setMensaje('Completa el logro 3')
        return
      }
      if(!recursosTres.trim()){
        setMensaje('Completa los recursos 3')
        return
      }

      try {
        
        await db.collection("metas:" + props.user.email).doc(id).update({
          name: meta,
          tresMeses: tresMeses,
          accionUno: accionUno,
          taskforceUno: taskforceUno,
          estrategiaUno: estrategiaUno,
          aprendizajeUno: aprendizajeUno,
          relacionUno: relacionUno,
          logroUno: logroUno,
          recursosUno: recursosUno,
          accionDos: accionDos,
          taskforceDos: taskforceDos,
          estrategiaDos: estrategiaDos,
          aprendizajeDos: aprendizajeDos,
          relacionDos: relacionDos,
          logroDos: logroDos,
          recursosDos: recursosDos,
          accionTres: accionTres,
          taskforceTres: taskforceTres,
          estrategiaTres: estrategiaTres,
          aprendizajeTres: aprendizajeTres,
          relacionTres: relacionTres,
          logroTres: logroTres,
          recursosTres: recursosTres,
          fecha: Date.now()
        })
        const arrayEditado = metas.map(item => (
          item.id === id ? {id: item.id, fecha: item.fecha, name: meta,
            tresMeses: tresMeses, accionUno: accionUno, taskforceUno: taskforceUno,
            estrategiaUno: estrategiaUno, aprendizajeUno: aprendizajeUno,
            relacionUno: relacionUno, logroUno: logroUno, recursosUno: recursosUno,
            accionDos: accionDos, taskforceDos: taskforceDos,
            estrategiaDos: estrategiaDos, aprendizajeDos: aprendizajeDos,
            relacionDos: relacionDos, logroDos: logroDos, recursosDos: recursosDos,
            accionTres: accionTres, taskforceTres: taskforceTres, estrategiaTres: estrategiaTres,
            aprendizajeTres: aprendizajeTres, relacionTres: relacionTres,
            logroTres: logroTres, recursosTres: recursosTres
          } : item
        ))
        setMetas(arrayEditado)
        setModoEdicion(false)
        setMeta('')
        setTresMeses('')
        setAccionUno('')
        setTaskforceUno('')
        setEstrategiaUno('')
        setAprendizajeUno('')
        setRelacionUno('')
        setLogroUno('')
        setRecursosUno('')
        setAccionDos('')
        setTaskforceDos('')
        setEstrategiaDos('')
        setAprendizajeDos('')
        setRelacionDos('')
        setLogroDos('')
        setRecursosDos('')
        setAccionTres('')
        setTaskforceTres('')
        setEstrategiaTres('')
        setAprendizajeTres('')
        setRelacionTres('')
        setLogroTres('')
        setRecursosTres('')
        setId('')
        setMensaje('La meta fue eliminada.')
      } catch (error) {
        console.log(error)
      }
    }

    return (
          <>
             <div>
                <br />
                    <h5>Mis Metas declaradas</h5>
                    <ul className="lista">
                        {
                        metas.map(item => (
                            <li key={item.id}>
                              <a className="btn btn-light" 
                                 data-toggle="collapse" 
                                 href={"#"+item.id} 
                                 role="button" 
                                 aria-expanded="false" 
                                 aria-controls="collapseExample">
                                   Meta: {item.name} - Fecha: {item.tresMeses}
                              </a>
                              <br /> 
                              <div className="collapse" id={item.id}>
                            <div className="card card-body">
                            <div>
                                 <h5>Acción Uno: {item.accionUno} <br /></h5>
                                    <ul>
                                      <li>Taskforce: {item.taskforceUno}</li>
                                      <li>Estrategia: {item.estrategiaUno}</li>
                                      <li>Aprendizaje: {item.aprendizajeUno}</li>
                                      <li>Relación: {item.relacionUno}</li>
                                      <li>Logro: {item.logroUno}</li>
                                      <li>Recurso: {item.recursosUno}</li>
                                    </ul>
                            </div>
                            <br/>
                            <div>
                                 <h5>Acción Dos: {item.accionDos} <br /></h5>
                                    <ul>
                                      <li>Taskforce: {item.taskforceDos}</li>
                                      <li>Estrategia: {item.estrategiaDos}</li>
                                      <li>Aprendizaje: {item.aprendizajeDos}</li>
                                      <li>Relación: {item.relacionDos}</li>
                                      <li>Logro: {item.logroDos}</li>
                                      <li>Recurso: {item.recursosDos}</li>
                                    </ul>
                            </div>
                            <br/>
                            <div>
                                 <h5>Acción Tres: {item.accionTres} <br /></h5>
                                    <ul>
                                      <li>Taskforce: {item.taskforceTres}</li>
                                      <li>Estrategia: {item.estrategiaTres}</li>
                                      <li>Aprendizaje: {item.aprendizajeTres}</li>
                                      <li>Relación: {item.relacionTres}</li>
                                      <li>Logro: {item.logroTres}</li>
                                      <li>Recurso: {item.recursosTres}</li>
                                    </ul>
                            </div>
                            </div>
                            </div>
                            <br/>
                            <button 
                                className="btn btn-danger btn-sm mb-3 ml-2"
                                onClick={() => eliminar(item.id)}
                            >
                                Eliminar
                            </button>
                            <button 
                                className="btn btn-warning btn-sm ml-2 mb-3"
                                onClick={() => activarEdicion(item)}
                            >
                                Editar
                            </button>
                            </li>
                        ))
                        }
                    </ul>
                </div>
                <div>
                    <h5>
                    <a className="btn btn-light" 
                       data-toggle="collapse" 
                       href="#collapseForm" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                        {
                        modoEdicion ? 'Editar Meta' : 'Agregar Meta'
                        }
                        </a> 
                    </h5>
                    <div className="collapse" id="collapseForm">
                    <div className="card card-body">
                    <form onSubmit={modoEdicion ? editar : agregar}>
                        <input 
                        type="text"
                        className="form-control"
                        placeholder="Ingrese Meta"
                        onChange={e => setMeta(e.target.value)}
                        value={meta}
                        />
                        <br />
                        <input 
                        type="text"
                        className="form-control"
                        placeholder="Ingrese fecha a 3 meses"
                        onChange={e => setTresMeses(e.target.value)}
                        value={tresMeses}
                        />
                        <br />
                        <h5>Acción Uno</h5>
                        <input 
                        type="text"
                        className="form-control"
                        placeholder="Ingrese la 1° acción a llevar a cabo"
                        onChange={e => setAccionUno(e.target.value)}
                        value={accionUno}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="Taskforce: ¿Quiénes lo acompañan?"
                        onChange={e => setTaskforceUno(e.target.value)}
                        value={taskforceUno}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Cómo va a llevarla a cabo? Táctica y Estrategia"
                        onChange={e => setEstrategiaUno(e.target.value)}
                        value={estrategiaUno}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Qué necesito aprender para lograrlo?"
                        onChange={e => setAprendizajeUno(e.target.value)}
                        value={aprendizajeUno}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Qué relación me falta generar?"
                        onChange={e => setRelacionUno(e.target.value)}
                        value={relacionUno}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Cómo sabré cuando logré los resultados?"
                        onChange={e => setLogroUno(e.target.value)}
                        value={logroUno}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="Recursos asignados"
                        onChange={e => setRecursosUno(e.target.value)}
                        value={recursosUno}
                        />
                        <br />
                        <h5>Acción Dos</h5>
                        <input 
                        type="text"
                        className="form-control"
                        placeholder="Ingrese la 2° acción a llevar a cabo"
                        onChange={e => setAccionDos(e.target.value)}
                        value={accionDos}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="Taskforce: ¿Quiénes lo acompañan?"
                        onChange={e => setTaskforceDos(e.target.value)}
                        value={taskforceDos}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Cómo va a llevarla a cabo? Táctica y Estrategia"
                        onChange={e => setEstrategiaDos(e.target.value)}
                        value={estrategiaDos}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Qué necesito aprender para lograrlo?"
                        onChange={e => setAprendizajeDos(e.target.value)}
                        value={aprendizajeDos}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Qué relación me falta generar?"
                        onChange={e => setRelacionDos(e.target.value)}
                        value={relacionDos}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Cómo sabré cuando logré los resultados?"
                        onChange={e => setLogroDos(e.target.value)}
                        value={logroDos}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="Recursos asignados"
                        onChange={e => setRecursosDos(e.target.value)}
                        value={recursosDos}
                        />
                        <br />
                        <h5>Acción Tres</h5>
                        <input 
                        type="text"
                        className="form-control"
                        placeholder="Ingrese la 3° acción a llevar a cabo"
                        onChange={e => setAccionTres(e.target.value)}
                        value={accionTres}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="Taskforce: ¿Quiénes lo acompañan?"
                        onChange={e => setTaskforceTres(e.target.value)}
                        value={taskforceTres}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Cómo va a llevarla a cabo? Táctica y Estrategia"
                        onChange={e => setEstrategiaTres(e.target.value)}
                        value={estrategiaTres}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Qué necesito aprender para lograrlo?"
                        onChange={e => setAprendizajeTres(e.target.value)}
                        value={aprendizajeTres}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Qué relación me falta generar?"
                        onChange={e => setRelacionTres(e.target.value)}
                        value={relacionTres}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="¿Cómo sabré cuando logré los resultados?"
                        onChange={e => setLogroTres(e.target.value)}
                        value={logroTres}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="Recursos asignados"
                        onChange={e => setRecursosTres(e.target.value)}
                        value={recursosTres}
                        />
                        <br />
                        {
                        mensaje && (
                            <div className="alert alert-info">
                                {mensaje}
                            </div>
                        )
                        }
                        <button 
                        className={
                            modoEdicion ? 'btn btn-warning btn-block custom mt-2' : 'btn btn-dark btn-block custom mt-2'
                        }
                        type="submit"
                        >
                        {
                            modoEdicion ? 'Editar' : 'Agregar'
                        }
                        </button>
                        <br />
                    </form>
                </div>
              </div>
         </div>
         <br /> <br />
      </>
    )
}

export default Firestore