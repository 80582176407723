import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const Esperanzado = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])

    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <img src="https://app.sonria.tv/img/esperanza.jpg" width="70px"></img><br/>
                <h4>Esperanza</h4>
                <b>¿Cuál es el Sentido de la Emoción que se nos dispara?</b><br/>
                Búsqueda de respuestas, recursos o alternativas para enfrentar conflictos 
                emocionales o amenazas, internas o externas.
                    <br/><br/>
    
                <p className="peque"><i><b>Raíz de la palabra: </b>
                Del Latín: Speare (tener esperanza). Ansia (Procedente). Algo que va a venir. 
                Del Indoeuropeo: Spe (Expandirse). En griego se vincula al verbo (sacar, 
                extender).</i></p>
                <br/>
                <NavLink className="btn btn-info" href="/">Regresar</NavLink>
                </>
                )
            }
        </div>
        </main>
    )
}

export default withRouter(Esperanzado)