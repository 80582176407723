import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyAlKd-Z-gdRyz543apqNzzinQo5KENaNB0",
    authDomain: "sonriatv-9a706.firebaseapp.com",
    databaseURL: "https://sonriatv-9a706.firebaseio.com",
    projectId: "sonriatv-9a706",
    storageBucket: "sonriatv-9a706.appspot.com",
    messagingSenderId: "481301640169",
    appId: "1:481301640169:web:133a696462403c94b1d178"
};

app.initializeApp(firebaseConfig);

const db = app.firestore();
const auth = app.auth();

/** To sign up using google
* Link for developer documentation (https://firebase.google.com/docs/auth/web/google-signin)
*/
const provider = new app.auth.GoogleAuthProvider();
provider.setCustomParameters({
  promt: "select_account",
  esRegistro: true
});

const providerf = new app.auth.FacebookAuthProvider();

const storage = app.storage()

export {db, auth, storage}
export const signInWithGoogle = () => auth.signInWithPopup(provider);
export const signInWithFacebook = () => auth.signInWithPopup(providerf);