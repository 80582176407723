import React from 'react'
import {db} from '../firebase'
import Moment from 'react-moment';
import 'moment/locale/es'
import '../index.css';

const TestEfectividad = (props) => {

    const [tests, setTests] = React.useState([])
    const [discusiones, setDiscusiones] = React.useState('')
    const [comportamientos, setComportamientos] = React.useState('')
    const [trabajando, setTrabajando] = React.useState('')
    const [disculpa, setDisculpa] = React.useState('')
    const [sacrificios, setSacrificios] = React.useState('')
    const [debilidades, setDebilidades] = React.useState('')
    const [reuniones, setReuniones] = React.useState('')
    const [decisiones, setDecisiones] = React.useState('')
    const [moral, setMoral] = React.useState('')
    const [temasdif, setTemasdif] = React.useState('')
    const [decepcionar, setDecepcionar] = React.useState('')
    const [compartir, setCompartir] = React.useState('')
    const [acuerdos, setAcuerdos] = React.useState('')
    const [desafian, setDesafian] = React.useState('')
    const [credito, setCredito] = React.useState('')
    const [mensaje, setMensaje] = React.useState('')
    const [sumaUno, setSumaUno] = React.useState(0)
    const [sumaDos, setSumaDos] = React.useState(0)
    const [sumaTres, setSumaTres] = React.useState(0)
    const [sumaCuatro, setSumaCuatro] = React.useState(0)
    const [sumaCinco, setSumaCinco] = React.useState(0)
    const [promedio, setPromedio] = React.useState('')
    const [devoluciona, setDevoluciona] = React.useState('')
    const [devolucionb, setDevolucionb] = React.useState('')
    const [devolucionc, setDevolucionc] = React.useState('') 
    const [devolucionaa, setDevolucionaa] = React.useState('')
    const [devolucionbb, setDevolucionbb] = React.useState('')
    const [devolucioncc, setDevolucioncc] = React.useState('')   

  
    React.useEffect(() => {
  
      const obtenerDatos = async () => {
  
        try {

            if (promedio >= 8){
                setDevolucionaa('La disfunción probablemente no es un problema para el equipo.')
            }
            else if ((promedio >= 6) && (promedio <= 7.9)) {
                setDevolucionbb('La disfunción puede ser un problema.')
            }
            else if ((promedio >= 3) && (promedio <= 5.9)) {
                setDevolucioncc('La disfunción es un problema y necesita resolverse.')
            }
  
          const data = await db.collection("testEfectividad:" + props.user.email).get()
          const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          console.log(arrayData)
          setTests(arrayData)
          
        } catch (error) {
          console.log(error)
          console.log("Promedio: " + promedio)
          
        }
  
      }
  
      obtenerDatos()
      setMensaje('')
      
  
    }, [props.user.email])
  
    const agregar = async (e) => {
      e.preventDefault()
      
      try {
  
        const nuevoTest = {
          discusiones: discusiones,
          comportamientos: comportamientos,
          trabajando: trabajando,
          disculpa: disculpa,
          sacrificios: sacrificios,
          debilidades: debilidades,
          reuniones: reuniones,
          decisiones: decisiones,
          moral: moral,
          temasdif: temasdif,
          decepcionar: decepcionar,
          compartir: compartir,
          acuerdos: acuerdos,
          desafian: desafian,
          credito: credito,
          sumaUno: parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir),
          sumaDos: parseFloat(discusiones) + parseFloat(reuniones) + parseFloat(temasdif),
          sumaTres: parseFloat(trabajando) + parseFloat(decisiones) + parseFloat(acuerdos),
          sumaCuatro: parseFloat(comportamientos) + parseFloat(decepcionar) + parseFloat(desafian),
          sumaCinco: parseFloat(sacrificios) + parseFloat(moral) + parseFloat(credito),
          promedio: ((parseFloat(parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir)) + 
          parseFloat(parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir)) + 
          parseFloat(parseFloat(trabajando) + parseFloat(decisiones) + parseFloat(acuerdos)) + 
          parseFloat(parseFloat(comportamientos) + parseFloat(decepcionar) + parseFloat(desafian)) + 
          parseFloat(parseFloat(sacrificios) + parseFloat(moral) + parseFloat(credito)))/5),
          devolucionaa: devolucionaa,
          devolucionbb: devolucionbb,
          devolucioncc: devolucioncc,
          fecha: Date.now()
        }
      
        setPromedio((parseFloat(parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir)) + 
        parseFloat(parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir)) + 
        parseFloat(parseFloat(trabajando) + parseFloat(decisiones) + parseFloat(acuerdos)) + 
        parseFloat(parseFloat(comportamientos) + parseFloat(decepcionar) + parseFloat(desafian)) + 
        parseFloat(parseFloat(sacrificios) + parseFloat(moral) + parseFloat(credito)))/5)
        
        setSumaUno(parseInt(disculpa) + parseInt(debilidades) + parseInt(compartir))
        setSumaDos(parseInt(discusiones) + parseInt(reuniones) + parseInt(temasdif)) 
        setSumaTres(parseInt(trabajando) + parseInt(decisiones) + parseInt(acuerdos))
        setSumaCuatro(parseInt(comportamientos) + parseInt(decepcionar) + parseInt(desafian)) 
        setSumaCinco(parseInt(sacrificios) + parseInt(moral) + parseInt(credito)) 
        

        if (((parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir) + 
        parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir) + 
        parseFloat(trabajando) + parseFloat(decisiones) + parseFloat(acuerdos) + 
        parseFloat(comportamientos) + parseFloat(decepcionar) + parseFloat(desafian) + 
        parseFloat(sacrificios) + parseFloat(moral) + parseFloat(credito)) /5) >= 8){
            setDevoluciona('La disfunción probablemente no es un problema para el equipo.')
        }
        else if ((((parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir) + 
        parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir) + 
        parseFloat(trabajando) + parseFloat(decisiones) + parseFloat(acuerdos) + 
        parseFloat(comportamientos) + parseFloat(decepcionar) + parseFloat(desafian) + 
        parseFloat(sacrificios) + parseFloat(moral) + parseFloat(credito)) /5) >= 6) && (((parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir) + 
        parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir) + 
        parseFloat(trabajando) + parseFloat(decisiones) + parseFloat(acuerdos) + 
        parseFloat(comportamientos) + parseFloat(decepcionar) + parseFloat(desafian) + 
        parseFloat(sacrificios) + parseFloat(moral) + parseFloat(credito)) /5) <= 7.9)) {
            setDevolucionb('La disfunción puede ser un problema.')
        }
        else if ((((parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir) + 
        parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir) + 
        parseFloat(trabajando) + parseFloat(decisiones) + parseFloat(acuerdos) + 
        parseFloat(comportamientos) + parseFloat(decepcionar) + parseFloat(desafian) + 
        parseFloat(sacrificios) + parseFloat(moral) + parseFloat(credito)) /5) >= 3) && (((parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir) + 
        parseFloat(disculpa) + parseFloat(debilidades) + parseFloat(compartir) + 
        parseFloat(trabajando) + parseFloat(decisiones) + parseFloat(acuerdos) + 
        parseFloat(comportamientos) + parseFloat(decepcionar) + parseFloat(desafian) + 
        parseFloat(sacrificios) + parseFloat(moral) + parseFloat(credito)) /5) <= 5.9)) {
            setDevolucionc('La disfunción es un problema y necesita resolverse.')
        }
        
        const data = await db.collection("testEfectividad:" + props.user.email).add(nuevoTest)
  
        setTests([
          ...tests,
          {...nuevoTest, id: data.id}
        ])
        
        setMensaje('El Test de Efectividad fue guardado correctamente.')

        
      } catch (error) {
        console.log(error)
        
      }
      console.log("Suma Uno: " + sumaUno)
      console.log("Suma Dos: " + sumaDos)
      console.log("Suma Tres: " + sumaTres)
      console.log("Suma Cuatro: " + sumaCuatro)
      console.log("Suma Cinco: " + sumaCinco)
      console.log("Promedio: " + promedio)
      console.log("Devoluciona: " + devoluciona)
      console.log("Devolucionb: " + devolucionb)
      console.log("Devolucionc: " + devolucionc)
      console.log("Devolucionaa: " + devolucionaa)
      console.log("Devolucionbb: " + devolucionbb)
      console.log("Devolucioncc: " + devolucioncc)
        
    }
      


    return (
        <main>
            <div className="contenedor">
        
            <div>
            <br/>
            <h5>Test de Efectividad de un Equipo</h5>
            Utilice la siguiente escala para indicar qué tanto cada afirmación se aplica 
            a su equipo. <br/>
            En la columna de puntaje escribe tu respuesta para cada una de las 
            afirmaciones. Asegúrate de evaluar cada frase honestamente, sin pensar
            demasiado la respuesta.<br/>
            3 = Usualmente<br/>
            2 = Algunas veces<br/>
            1 = Rara vez<br/>
            <br/>
                    <ul className="lista">
                        {
                        tests.map(item => (         
                            <li key={item.id}>
                              <a className="btn btn-light" 
                                 data-toggle="collapse" 
                                 href={"#"+item.id} 
                                 role="button" 
                                 aria-expanded="false" 
                                 aria-controls="collapseExample">
                                   Fecha y Hora: <Moment format="DD-MM-YYYY">{item.fecha}</Moment>
                              </a>
                              <br /> 
                            <div className="collapse" id={item.id}>
                            <div className="card card-body">
                                Ausencia de confianza: {item.sumaUno}<br/>
                                Temor al conflicto: {item.sumaDos}<br/>
                                Falta de compromiso: {item.sumaTres}<br/>
                                Evasión de responsabilidad: {item.sumaCuatro}<br/>
                                Falta de atención a los resultados: {item.sumaCinco}<br/>
                                <b>Promedio: {item.promedio}</b> <br />
                                <p className="peque">Un puntaje de 8-9: indica que la disfunción probablemente no es 
                                un problema para el equipo.<br/>
                                Un puntaje de 6 a 7: significa que la disfunción puede ser 
                                un problema.<br/>
                                Un puntaje de 3 a 5: significa que la disfunción es un 
                                problema y necesita resolverse.</p>
                                {item.devolucionaa}
                                {item.devolucionbb}
                                {item.devolucioncc}

                            </div>
                            </div>
                            </li>
                        ))
                        }
                    </ul>
                    
        </div>
        <div>
                    <br/>
                    <h5>
                    <a className="btn btn-secondary" 
                       data-toggle="collapse" 
                       href="#collapseForm" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                          Realizar Test
                    </a>              
                    </h5>
                    <div className="collapse" id="collapseForm">
                    <div className="card card-body">
        
                    <form onSubmit={agregar}>
                        <p>Son apasionados y desprevenidos en sus discusiones sobre 
                            diferentes temas.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={discusiones === '3'} 
                                        onChange={e => setDiscusiones(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="2" 
                                        checked={discusiones === '2'} 
                                        onChange={e => setDiscusiones(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="1" 
                                        checked={discusiones === '1'} 
                                        onChange={e => setDiscusiones(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Los miembros del equipo señalan a sus compañeros los comportamientos 
                           improductivos que pueden estar teniendo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={comportamientos === '3'} 
                                        onChange={e => setComportamientos(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={comportamientos === '2'} 
                                        onChange={e => setComportamientos(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={comportamientos === '1'} 
                                        onChange={e => setComportamientos(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Saben en qué están trabajando sus colegas y cada uno contribuye al 
                            bien colectivo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={trabajando === '3'} 
                                        onChange={e => setTrabajando(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={trabajando === '2'} 
                                        onChange={e => setTrabajando(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={trabajando === '1'} 
                                        onChange={e => setTrabajando(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Se disculpan rápida y genuinamente entre ellos cuando dicen o hacen algo 
                            inapropiado y posiblemente dañino para el equipo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={disculpa === '3'} 
                                        onChange={e => setDisculpa(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={disculpa === '2'} 
                                        onChange={e => setDisculpa(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={disculpa === '1'} 
                                        onChange={e => setDisculpa(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Gustosamente hacen sacrificios (como presupuesto, funciones, personas) en 
                            sus departamentos o áreas especializadas por el bien del equipo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={sacrificios === '3'} 
                                        onChange={e => setSacrificios(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={sacrificios === '2'} 
                                        onChange={e => setSacrificios(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={sacrificios === '1'} 
                                        onChange={e => setSacrificios(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Abiertamente reconocen sus debilidades y errores.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={debilidades === '3'} 
                                        onChange={e => setDebilidades(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={debilidades === '2'} 
                                        onChange={e => setDebilidades(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={debilidades === '1'} 
                                        onChange={e => setDebilidades(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Las reuniones del equipo son interesantes y no son aburridas.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={reuniones === '3'} 
                                        onChange={e => setReuniones(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={reuniones === '2'} 
                                        onChange={e => setReuniones(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={reuniones === '1'} 
                                        onChange={e => setReuniones(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Cuando se tienen que retirar de una reunión, lo hacen con la tranquilidad de 
                            que sus miembros respetarán las decisiones acordadas durante la reunión, aunque 
                            haya habido desacuerdo inicial.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={decisiones === '3'} 
                                        onChange={e => setDecisiones(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={decisiones === '2'} 
                                        onChange={e => setDecisiones(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={decisiones === '1'} 
                                        onChange={e => setDecisiones(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>La moral del equipo se afecta significativamente cuando no logran lo que 
                            se proponen todos.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={moral === '3'} 
                                        onChange={e => setMoral(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={moral === '2'} 
                                        onChange={e => setMoral(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={moral === '1'} 
                                        onChange={e => setMoral(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Durante las reuniones, los temas más difíciles se ponen sobre la mesa 
                            para resolverse.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={temasdif === '3'} 
                                        onChange={e => setTemasdif(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={temasdif === '2'} 
                                        onChange={e => setTemasdif(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={temasdif === '1'} 
                                        onChange={e => setTemasdif(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Realmente les mortifica decepcionar a sus compañeros.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={decepcionar === '3'} 
                                        onChange={e => setDecepcionar(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={decepcionar === '2'} 
                                        onChange={e => setDecepcionar(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={decepcionar === '1'} 
                                        onChange={e => setDecepcionar(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Saben de la vida de los miembros del equipo y se sienten cómodos compartiendo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={compartir === '3'} 
                                        onChange={e => setCompartir(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={compartir === '2'} 
                                        onChange={e => setCompartir(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={compartir === '1'} 
                                        onChange={e => setCompartir(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Las discusiones finalizan con acuerdos y decisiones concretas para la acción.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={acuerdos === '3'} 
                                        onChange={e => setAcuerdos(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={acuerdos === '2'} 
                                        onChange={e => setAcuerdos(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={acuerdos === '1'} 
                                        onChange={e => setAcuerdos(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Desafían sus planes y perspectivas entre sí.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={desafian === '3'} 
                                        onChange={e => setDesafian(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={desafian === '2'} 
                                        onChange={e => setDesafian(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={desafian === '1'} 
                                        onChange={e => setDesafian(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Los miembros del equipo son lentos en buscar crédito por sus propias 
                            contribuciones y son rápidos para señalar las contribuciones y 
                            logros de otros.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3" 
                                        checked={credito === '3'} 
                                        onChange={e => setCredito(e.target.value)}
                                    />
                                    3
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2" 
                                        checked={credito === '2'} 
                                        onChange={e => setCredito(e.target.value)}
                                    />
                                    2
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1" 
                                        checked={credito === '1'} 
                                        onChange={e => setCredito(e.target.value)}
                                    />
                                    1
                                </p>
                                </div>
                            </div>                        
                        <br/>
                        <p>Ausencia de confianza: {parseInt(disculpa) + parseInt(debilidades) + parseInt(compartir)}</p><br/>
                        <p>Temor al conflicto: {parseInt(discusiones) + parseInt(reuniones) + parseInt(temasdif)}</p><br/>
                        <p>Falta de compromiso: {parseInt(trabajando) + parseInt(decisiones) + parseInt(acuerdos)}</p><br/>
                        <p>Evasión de responsabilidad: {parseInt(comportamientos) + parseInt(decepcionar) + parseInt(desafian)}</p><br/>
                        <p>Falta de atención a los resultados: {parseInt(sacrificios) + parseInt(moral) + parseInt(credito)}</p><br/>
                        {
                        mensaje && (
                            <div className="alert alert-info">
                                {mensaje}
                                <br/>
                            </div>
                        )
                        }
                        {
                        promedio && (
                            <div className="alert alert-info">
                                <h5>Promedio del Test: {promedio}</h5>
                                <br/>
                            </div>
                        )
                        }
                        {
                        devoluciona && (
                            <div className="alert alert-success">
                                {devoluciona}
                            </div>     
                        )
                        }
                        {
                        devolucionb && (
                            <div className="alert alert-warning">
                                {devolucionb}
                            </div>       
                        )
                        }
                        {
                        devolucionc && (
                            <div className="alert alert-danger">
                                {devolucionc}
                            </div>       
                        )
                        }
                        
                        <br/>
                        <div>
                        <button 
                        className='btn btn-dark btn-block mt-2'
                        type="submit"
                        >
                        Ver Resultado y Guardar Test
                        </button>
                        </div>
                        <br /><br />
                    </form>
                    </div>
                </div>
            </div> 
     </div>  
        </main>
    )
}

export default TestEfectividad