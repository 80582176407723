import React from 'react'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const Cree = () => {
    return (
        <main>
        <div className="contenedor">
            <h3>Programa CREE</h3>
                <NavLink className="btn btn-secondary custom" href="/mitestefectividad">
                    Test de Efectividad
                </NavLink>
        </div>
        </main>
    )
}

export default Cree
