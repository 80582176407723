import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const Agradecido = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])

    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <img src="https://app.sonria.tv/img/gratitud.jpg" width="70px"></img><br/>
                <h4>Gratitud</h4>
                <b>¿Cuál es el Sentido de la Emoción que se nos dispara?</b><br/>
                La completitud es una característica de un ente real o imaginario cuya 
                percepción nos permite la revelación de su significado. Viviendo en un 
                estado de serena ambición sin necesidades emergentes. Dónde sirve 
                a otros con alegría y actitud sostenida.
                    <br/><br/>
        
                <p className="peque"><i><b>Raíz de la palabra: </b>
                Del griego philos (amor)y anthropos (ser humano). Del latín 
                amórem (amos). Amor, Agape, que se concentra en la declaración y
                la acción de dar sin esperar nada a cambio. Por eso es posible 
                amar al enemigo.</i></p>
                <br/>
                <NavLink className="btn btn-info" href="/">Regresar</NavLink>
                </>
                )
            }
        </div>
        </main>
    )
}

export default withRouter(Agradecido)