import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import Audiencia from './Audiencia'
import ListaDatos from './ListaDatos'
import '../index.css';

const Clases = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])

    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <h3>Mis Clases: 
                <ListaDatos user={user}/></h3>
                
                <Audiencia user={user}/>
                </>
                )
            }
        </div>
        </main>
    )
}

export default withRouter(Clases)
