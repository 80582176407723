import React from 'react';
import {NavLink,Navbar,Nav,NavDropdown} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import '../index.css';
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'


const Navbary = (props) => {

  const cerrarSesion = () => {
      auth.signOut()
      .then(() => {
          props.history.push('/login')
      })
  }

    return(
        <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand href="/">
          <img
            src="https://app.sonria.tv/img/sonriatv-logo.jpg"
            width="156"
            height="40"
            className="d-inline-block align-top"
            alt="SonriaTVlogo"
          />
      </Navbar.Brand>
      <div>
      <FontAwesomeIcon icon={faSearch} />
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </div>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
        <NavLink href="/">Inicio</NavLink>
        {
          props.firebaseUser !== null ? (
            <NavLink href="/perfil">Perfil</NavLink>
          ) : null
        } 
        <NavLink href="/intensivos">Intensivos</NavLink>
          <NavDropdown title="Niveles de Conciencia" id="collasible-nav-dropdown">
            <NavDropdown.Item href="/misalud">Mi Salud</NavDropdown.Item>
            <NavDropdown.Item href="/miscompromisos">Mis Compromisos</NavDropdown.Item>
            <NavDropdown.Item href="/misemociones">Mis Emociones</NavDropdown.Item>
            <NavDropdown.Item href="/metas">Mi Trabajo</NavDropdown.Item>
            <NavDropdown.Item href="/clases">La Carrera</NavDropdown.Item>
            <NavDropdown.Item href="#">El Liderazgo</NavDropdown.Item>
            <NavDropdown.Item href="#">♥️ de Servicio</NavDropdown.Item>
            <NavDropdown.Item href="#">El legado</NavDropdown.Item>          
          </NavDropdown>
         {/*<NavLink href="/misalud">Mi Salud</NavLink>
        <NavLink href="">Mis Compromisos</NavLink>
        <NavLink href="/misemociones">Mis Emociones</NavLink>
        <NavLink href="/metas">Mi Trabajo</NavLink>
        <NavLink href="/clases">La Carrera</NavLink>
        <NavLink href="">El Liderazgo</NavLink>
        <NavLink href="">♥️ de Servicio</NavLink>
        <NavLink href="">El legado</NavLink>*/}
        {
          props.firebaseUser !== null ? (
            <button 
            className="btn btn-dark"
            onClick={() => cerrarSesion()}
            >Cerrar Sesión</button>
          ) : (
            <NavLink className="btn btn-dark mr-2" href="/login">Login</NavLink>
          )
        }
        {/*
          <NavLink href="/agenda">Agenda</NavLink>
          <NavDropdown title="Canales" id="collasible-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Coaching</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Mentoring</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Psicologia</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Marketing y Negocios</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Vida Sana</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Tecnologia</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Valor Social</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Subir Video</NavDropdown.Item>
          </NavDropdown>
        */}
        </Nav>
    
      </Navbar.Collapse>
    </Navbar>   
            
        </div>
    )
}

export default withRouter(Navbary)