import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import '../index.css';
import { Link } from 'react-router-dom';

const MiSalud = () => {

    return (
        <main>
        <div className="contenedor">
            <h3>Test de Creencias de Ellis</h3>
            <p>¿Sabías que si repetimos una y otra vez un pensamiento, éste acaba por 
                integrarse en el subconsciente? ¿Que es posible cambiar los valores y creencias 
                que hay almacenados en nuestro subconsciente y que son limitadoras de nuestro 
                desarrollo?</p>

                <h5>Acerca del Test</h5>
                <p>El test de Sean Ellis surgió de la consultora 12in6 liderada por Sean Ellis 
                especializada en ayudar a las startup a escalar (lo que se llama etapa de 
                transición de crecimiento). Este test era de obligado cumplimiento antes de ser 
                aceptada como cliente del servicio dentro de la consultora.</p>

                <h5>¿Qué da como resultado el Test de Creencias de Ellis?</h5>
                <p>La forma básica de percibir, de pensar, de valorar y de actuar sobre la base de 
                una visión particular de la Realidad. Nos muestra cómo actúa cada Paradigma sobre 
                nosotros y las formas de cosmovisionar el mundo.</p>

                <p>En este artículo puedes leer información sobre el test:<br/>
                <a href="https://sonria.com/test-ellis/" target="_blank">
                    Acerca del test
                </a></p>

                <h5>¿Estás listo para descubrir tus creencias  irracionales?</h5>
                <Link className="btn btn-dark mr-2" to="/mitestcreencias">
                    Realizar Test de Ellis
                </Link>
                <p></p>
                <br/><br/>
            <h3>Tu Plan de Salud Integrativa</h3>
            <p>En Sonria estamos comprometidos a la creación de un indicador de salud 
            como el avalado por la OMS en 1947 (1), que afirma que la salud es "un estado 
            de completo bienestar físico, mental, social y no solamente la ausencia de 
            afecciones o enfermedades".</p>

            <h5>Recomendaciones de la OMS</h5>

            <p>1 - Aprovechar la contribución de las medicinas tradicionales y 
            complementarias a la atención de la salud centrada en las personas.<br/>
            2 - Promover la utilización segura y eficaz de las terapias complementarias 
            mediante la reglamentación.<br/>
            3 -Fomentar la cobertura sanitaria universal por medio de la integración de las
            terapias complementarias en los sistemas nacionales de salud.<br/>
            <br/>
            Los siguientes países ya han recogido estas recomendaciones con respecto 
            a una medicina integrativa: Alemania, Suiza, Suecia, Dinamarca, Francia, 
            Estados Unidos, Japón y China.<br/>
            <br/>
            En Argentina desde el 2002 la A.A.M.I busca nuevos espacios 
            de confluencia para la acción y reflexión. Y aunque en España no existen leyes,
            políticas nacionales, planes ni agencias oficiales relacionadas con las medicinas
            no convencionales, el Magazine CuerpoMente dice que 9.000 médicos prescriben 
            habitualmente homeopatía, 3.000 practican la acupuntura y existen más de 80.000 
            profesionales que tributan bajo el epígrafe de naturópata, acupuntor y otras 
            técnicas parasanitarias.</p>

            <h5>¿Qué es la Salud Integrativa?</h5>

            <p>El Consorcio de Centros Académicos de Salud para la Medicina Integrativa la define como "la práctica de la medicina que reafirma la importancia de la relación entre tratante y paciente, atiende a la persona como un todo, está informado acerca de las pruebas, y hace uso de todas las aproximaciones terapéuticas apropiadas, profesionales de la salud y disciplinas para conseguir una salud y curación óptimas".

            La Medicina Integrativa busca incorporar conceptos nuevos a la vez que invita 
            a  abandonar viejos hábitos disfuncionales.</p>

            <h5>¿Cuáles son sus principios?</h5>

            <p>1 - Cuidado centrado en el paciente y no en la enfermedad.<br/>
            2 - Identificación de la salud como vitalidad positiva y no sólo la ausencia 
            de enfermedad.<br/>
            3 - Individualidad bioquímica.<br/>
            4 - Balance dinámico entre factores externos e internos (factores genéticos, 
            ambientales, etc.).<br/>
            5 - Considerar al Ser Humano en todas sus Dimensiones: Cuerpo, Mente, Espíritu,
            para un completo abordaje.<br/>
            6 - Promover no sólo el incremento del tiempo de vida sino el tiempo de vida 
            con óptima salud.
            </p>
            <br/>
            <img src="https://app.sonria.tv/img/salud.jpg" width="100%"></img>
            <br/><br/>

            <h5>¿Qué Implica cada Dimensión?</h5>
            <h6>Una forma de acompañar a la salud a través de nuestro propio tránsito 
            en la vida.</h6>
            <p>Desde nuestro Modelo MƐT® sugerimos estos abordajes o caminos, que vamos 
            investigando, a medida que transitamos nuestro proceso de transformación 
            personal. La propuesta no es adherir a una u otra terapia, sino acompañar el 
            proceso de restablecimiento de la salud desde múltiples dimensiones, a fin de 
            promover el desarrollo armónico.</p>
            <br/>
            <div className="centrado">
            <div>
                <Link to="/indicador">
                    <img src="https://app.sonria.tv/img/nivel0.png"></img>
                </Link>  <br/>
                Recomendamos leer este artículo antes de realizar el test.<br/>
                <Link className="btn btn-dark mr-2" to="/indicador">
                    Mi indicador de Salud
                </Link>
            </div>
            <br/><br/>
            <div>
                <Link to="/mitestsalud">
                    <img src="https://app.sonria.tv/img/test-salud.jpg"></img>
                </Link>  <br/>
                Si ya leiste el artículo anterior puedes realizar el test.
                <br/>
                <Link className="btn btn-dark mr-2" to="/mitestsalud">
                    Test de Salud Física
                </Link>
            </div>
            </div>  
            <br/><br/>    
            <p className="peque">
            (1) En nuestro compromiso de ser activos en la creación de salud, aquí te 
            ofrecemos nuestro propio enfoque avalado por la Organización Mundial de la Salud
            (OMS), quien declara en estrategia para los años 2014 a 2023 la integración de 
            las medicinas tradicionales y complementarias en los sistemas nacionales de 
            salud.</p>
        </div>
        </main>
    )
}

export default withRouter(MiSalud)