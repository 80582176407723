import React from 'react'
import {db} from '../firebase'
import '../index.css';

const ListaDatos = (props) => {

    const [datos, setDatos] = React.useState([])
  
  
    React.useEffect(() => {
  
      const obtenerDatos = async () => {
  
        try {
  
          const data = await db.collection("datos:" + props.user.email).get()
          const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          console.log(arrayData)
          setDatos(arrayData)
          
        } catch (error) {
          console.log(error)
        }
  
      }
  
      obtenerDatos()
  
    }, [props.user.email])
  
    
    return (
        <div>
               { 
                 datos.map(item => (
                    <h3 key={item.id}>
                        {item.displayName}
                    </h3>
                   ))
                 }
                    
                 
                </div>
    )
}

export default ListaDatos