import React from 'react'
import {db} from '../firebase'
import moment from 'moment'
import 'moment/locale/es'
import '../index.css';

const Datos = (props) => {

    const [datos, setDatos] = React.useState([])
    const [dato, setDato] = React.useState('')
    const [displayName, setdisplayName] = React.useState('')
    const [phoneNumber, setphoneNumber] = React.useState('')
    const [pais, setPais] = React.useState('')
    const [ciudad, setCiudad] = React.useState('')
    const [facebook, setFacebook] = React.useState('')
    const [instagram, setInstagram] = React.useState('')
    const [habilitaCarga, setHabilitaCarga] = React.useState(false)
    const [modoEdicion, setModoEdicion] = React.useState(false)
    const [id, setId] = React.useState('')
    const [error, setError] = React.useState(null)
  
  
    React.useEffect(() => {
  
      const obtenerDatos = async () => {
  
        try {
  
          const data = await db.collection("datos:" + props.user.email).get()
          const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          console.log(arrayData)
          setDatos(arrayData)
          
        } catch (error) {
          console.log(error)
        }
  
      }
  
      obtenerDatos()
  
    }, [props.user.email])
  
    const agregar = async (e) => {
      e.preventDefault()
  
     if(!displayName.trim()) {
        console.log("Ingresa tu Nombre y Apellido")
        setError('Ingresa tu Nombre y Apellido')
        return
    }
    if(!phoneNumber.trim()) {
        console.log("Ingresa tu WhatsApp")
        setError('Ingresa tu WhatsApp')
        return
    }
    if(!pais.trim()) {
        console.log("Ingresa tu País")
        setError('Ingresa tu País')
        return
    }
    if(!ciudad.trim()) {
        console.log("Ingresa tu Ciudad")
        setError('Ingresa tu Ciudad')
        return
    }
    console.log("Datos completados!")
  
      try {
  
        const nuevoDato = {
          displayName: displayName,
          phoneNumber: phoneNumber,
          ciudad: ciudad,
          pais: pais,
          facebook: facebook,
          instagram: instagram,
          fecha: Date.now()
        }
        const data = await db.collection("datos:" + props.user.email).add(nuevoDato)
  
        setDatos([
          ...datos,
          {...nuevoDato, id: data.id}
        ])
  
        setdisplayName('')
        setphoneNumber('')
        setCiudad('')
        setPais('')
        setFacebook('')
        setInstagram('')

      } catch (error) {
        console.log(error)
      }
  
      console.log(dato)
    }

    const habilitarCarga = () => {
      setHabilitaCarga(true)
    }

    const activarEdicion = (item) => {
      setModoEdicion(true)
      setHabilitaCarga(true)
      setdisplayName(item.displayName)
      setphoneNumber(item.phoneNumber)
      setCiudad(item.ciudad)
      setPais(item.pais)
      setFacebook(item.facebook)
      setInstagram(item.instagram)
      setId(item.id)
    }

    const editar = async (e) => {
      e.preventDefault()

       if(!displayName.trim()) {
        console.log("Ingresa tu Nombre y Apellido")
        setError('Ingresa tu Nombre y Apellido')
        return
    }
    if(!phoneNumber.trim()) {
        console.log("Ingresa tu WhatsApp")
        setError('Ingresa tu WhatsApp')
        return
    }
    if(!pais.trim()) {
        console.log("Ingresa tu País")
        setError('Ingresa tu País')
        return
    }
    if(!ciudad.trim()) {
        console.log("Ingresa tu Ciudad")
        setError('Ingresa tu Ciudad')
        return
    }

      try {
        
        await db.collection("datos:" + props.user.email).doc(id).update({
          displayName: displayName,
          phoneNumber: phoneNumber,
          ciudad: ciudad,
          pais: pais,
          facebook: facebook,
          instagram: instagram,
          fecha: Date.now()
        })
        const arrayEditado = datos.map(item => (
          item.id === id ? {id: item.id, fecha: item.fecha, displayName: item.displayName,
          phoneNumber:item.phoneNumber, ciudad: item.ciudad, pais: item.pais, 
          facebook:item.facebook, instagram: item.instagram
          } : item
        ))
        setDatos(arrayEditado)
        setHabilitaCarga(false)
        setModoEdicion(false)
        setdisplayName(displayName)
        setphoneNumber('')
        setCiudad('')
        setPais('')
        setFacebook('')
        setInstagram('')
        setId('')
      } catch (error) {
        console.log(error)
      }
    }
    
    return (
        <div className="contenedor">
               { 
                 datos.map(item => (
                    <h5 key={item.id}>
                        Nombre: {item.displayName}<br/>
                        WhatsApp: {item.phoneNumber}<br/>
                        Ciudad: {item.ciudad}<br/>
                        Facebook: {item.facebook}<br/>
                        Instagram: {item.instagram}
                        <br /><br />
                       { !habilitaCarga && <>        
                    <button className="btn btn-warning btn-sm mb-3" onClick={() => activarEdicion(item)}>
                       Editar Datos
                    </button>
                    </> }
                    </h5>
                   ))
                 }
                    <button className="btn btn-info btn-sm mb-3" onClick={() => habilitarCarga()}>
                       Completar Datos
                    </button>
                 
                { habilitaCarga && <>  
                    
            <h3 className="text-center">
                {
                   modoEdicion ? 'Editar Datos' : 'Completar Datos'
                }
            </h3> 
            <hr />
            <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-ux-4">
                <form onSubmit={modoEdicion ? editar : agregar}>
                    {
                        error && (
                            <div className="alert alert-danger">
                                {error}
                            </div>
                        )
                    }
                    <input 
                    type="text" 
                    placeholder="Nombre y Apellido" 
                    className="form-control mb-2"
                    onChange={e => setdisplayName(e.target.value)}
                    value={displayName}
                    />
                    <input 
                    type="text" 
                    placeholder="WhatsApp + cod país Ej: ¨+54 911..." 
                    className="form-control mb-2"
                    onChange={e => setphoneNumber(e.target.value)}
                    value={phoneNumber}
                    />
                    <input 
                    type="text" 
                    placeholder="Ciudad" 
                    className="form-control mb-2"
                    onChange={e => setCiudad(e.target.value)}
                    value={ciudad}
                    />
                    <input 
                    type="text" 
                    placeholder="País" 
                    className="form-control mb-2"
                    onChange={e => setPais(e.target.value)}
                    value={pais}
                    />
                    <input 
                    type="text" 
                    placeholder="Facebook" 
                    className="form-control mb-2"
                    onChange={e => setFacebook(e.target.value)}
                    value={facebook}
                    />
                    <input 
                    type="text" 
                    placeholder="Instagram" 
                    className="form-control mb-2"
                    onChange={e => setInstagram(e.target.value)}
                    value={instagram}
                    />
                <button 
                className="btn btn-dark btn-lg btn-block" 
                type="submit"
                >{
                     modoEdicion ? "Editar" : "Enviar"
                 }
                  
                </button>
            
                </form>
                <br />
                </div>
            </div>
          </> }
           {
            }
        </div>
    )
}

export default Datos