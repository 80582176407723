import React from 'react'
import {auth, storage} from '../firebase'
import {withRouter} from 'react-router-dom'
import Firestore from './Firestore'
import Datos from './Datos'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const Perfil = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])


    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <h3>Mi Perfil</h3>
                <h5>{user.email}</h5>
                <img src={user.photoURL || "http://via.placeholder.com/100"} alt='Foto de perfil' width='60px'></img>
                
                <Datos user={user}/>

                </>
                )
            }
                <NavLink className="btn btn-secondary custom" href="/metas">Revisa tus Metas</NavLink>
        </div>
        </main>
    )
}

export default withRouter(Perfil)
