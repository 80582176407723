import React from 'react'
import {withRouter} from 'react-router-dom'
import '../index.css';
import { Link } from 'react-router-dom';

const IndicadorSalud = () => {

    return (
        <main>
        <div className="contenedor">
            <h3>Mi indicador de Salud</h3>
            <p>En SONRIA, somos conscientes de que necesitamos crear un indicador de salud. 
            Acorde a un concepto de salud como el avalado por la Organización Mundial de la 
            Salud (OMS) en 1947, que afirma que la salud es “un estado de completo bienestar 
            físico, mental, social y no solamente la ausencia de afecciones o enfermedades”.</p>

            <p>Un indicador que vaya más allá de la salud considerada como concepto 
            individual, considerando todos los datos y elementos numéricos que contribuyan 
            a una mirada global. Para eso debemos definir atributos que refieran a las 
            características o cualidades de la salud; y sus dimensiones comprendiendo no 
            solo el bienestar físico, sino el emocional, espiritual, ambiental, mental y 
            social… expuestos a continuación.</p>  

            <h3>La Salud en los Niveles</h3>
            <div className="centrado-grid">
                <div>
                <a href="https://sonria.com/salud-del-cuerpo-fisico" target="_blank">
                    <img src="https://app.sonria.tv/img/cuerpo-fisico.jpg" width="160px"></img>
                    <p>Cuepo Físico</p>
                </a>
                </div>
                <div>
                <a href="https://sonria.com/salud-mental" target="_blank">
                    <img src="https://app.sonria.tv/img/salud-mental.jpg" width="160px"></img>
                    <p>Salud Mental</p>
                </a>
                </div>
                <div>
                <a href="https://sonria.com/salud-emocional" target="_blank">
                    <img src="https://app.sonria.tv/img/salud-emocional.jpg" width="160px"></img>
                    <p>Salud Emocional</p>
                </a>
                </div>
                <div>
                <a href="https://sonria.com/salud-funcional" target="_blank">
                    <img src="https://app.sonria.tv/img/salud-funcional.jpg" width="160px"></img>
                    <p>Salud Funcional</p>
                </a>
                </div>
                <div>
                <a href="https://sonria.com/salud-ecologica-o-ambiental" target="_blank">
                    <img src="https://app.sonria.tv/img/salud-ecologica.jpg" width="160px"></img>
                    <p>Salud Ecológica</p>
                </a>
                </div>
                <div>
                <a href="https://sonria.com/salud-organizacional" target="_blank">
                    <img src="https://app.sonria.tv/img/salud-organizacional.jpg" width="160px"></img>
                    <p>Organizacional</p>
                </a>
                </div>
                <div>
                <a href="https://sonria.com/salud-social" target="_blank">
                    <img src="https://app.sonria.tv/img/salud-social.jpg" width="160px"></img>
                    <p>Salud Social</p>
                </a>
                </div>
                <div>
                <a href="https://sonria.com/armonia-espiritual" target="_blank">
                    <img src="https://app.sonria.tv/img/armonia-espiritual.jpg" width="160px"></img>
                    <p>Armonía Espiritual</p>
                </a>
                </div>
                <br/>
                <Link className="btn btn-secondary mr-2" to="/misalud"><h6>Volver</h6></Link>
            </div>
            <br/>
        </div>
        </main>
    )
}

export default withRouter(IndicadorSalud)