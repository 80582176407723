import React from 'react'
import {withRouter} from 'react-router-dom'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const MisEmociones = () => {

    return (
        <main>
            <div className="contenedor">
            <div className="titular">
                <h4>¿Cómo te sientes hoy?</h4>
                <p>Elige cómo te estás sintiendo en este momento, no cómo te gustaría sentirte.</p>
                </div>
                <div className="smiles">
                <a className="btn" 
                       data-toggle="collapse" 
                       data-target="#collapseAmoroso" 
                       role="button">
                    <img src="https://app.sonria.tv/img/amor-humanidad.jpg" width="70px"></img>
                    <p>Amoroso</p>
                    </a>
                </div>
                <hr></hr>
                <div className="collapse" id="collapseAmoroso">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Amar, Serenidad, Gozo, Dicha, Plenitud, Satisfacción, 
                    Filantropía, Armonía, Equilibrio Dinámico.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/amoroso">Explorar</NavLink>
                </div>
                <br/>
                </div>
                <div className="smiles">
                <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseAgradecido" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/gratitud.jpg" width="70px"></img>
                    <p>Agradecido</p>
                    </a>
                </div>
                <hr></hr>
                <div className="collapse" id="collapseAgradecido">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Paciencia, Dar y Recibir Amor, Placidez, Tranquilidad, Calma, 
                    Sociego, Sentimiento Estético, Religioso, Piedad, Misericordia, 
                    Compasión, Meditacion, Completitud.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/agradecido">Explorar</NavLink>
                </div>
                <br/>
                </div>
                <div className="smiles">
                <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseAlegre" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/alegria.jpg" width="70px"></img>
                    <p>Alegre</p>
                    </a>
                </div>
                <hr></hr>
                <div className="collapse" id="collapseAlegre">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Empatía, Sentimiento, Cómico, Felicidad, Contento, Alborozo, 
                    Bienestar, Júbilo, Regocijo.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/alegre">Explorar</NavLink>
                </div>
                <br/>
                </div>
                <div className="smiles">
                <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseTierno" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/ternura-comprension.jpg" width="70px"></img>
                    <p>Tierno y Comprensivo</p>
                    </a>
                </div>
                <hr></hr>
                <div className="collapse" id="collapseTierno">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Interés, Amistad, Familiaridad, Ternura, Cariño, Cordialidad, Perdón, 
                    Caridad, Solidaridad, Cordialidad, Relajación, Amabilidad.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/comprensivo">Explorar</NavLink>
                </div>
                <br/>
                </div>
                <div className="smiles">
                <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseEntu" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/entusiasmo.jpg" width="70px"></img>
                    <p>Entusiasmado</p>
                    </a>
                </div>
                <hr></hr>
                <div className="collapse" id="collapseEntu">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Admiración, Satisfacción, Estima, Afinidad, 
                    Simpatía, Reconocimiento, Afiliación, Coraje, Expansión.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/entusiasmado">Explorar</NavLink>
                </div>
                <br/>
                </div>
                <div className="smiles">
                <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseDeseo" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/deseo-agrado.jpg" width="70px"></img>
                    <p>Deseoso</p>
                    </a>
                </div>
                <hr></hr>  
                <div className="collapse" id="collapseDeseo">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Respeto, Afecto, Gusto, Orgullo Social, Atracción, Fascinación, Dulzura, 
                    Enamoramiento, Amor Erótico.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/deseoso">Explorar</NavLink>
                </div>
                <br/>
                </div>          
                <div className="smiles">
                <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseSorpren" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/sorpresa.jpg" width="70px"></img>
                    <p>Sorprendido</p>
                    </a>    
                </div>
                <hr></hr>
                <div className="collapse" id="collapseSorpren">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Timidez, Ánimo, Anticipación, Deseo, Ansia, Afán, Anhelo, Nostalgia, 
                    Asombro, Inquietud, Admiración, Curiosidad, Confusión, Perplejidad, 
                    Desconcierto, Pasmo.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/sorprendido">Explorar</NavLink>
                </div>
                <br/>
                </div>  
                <div className="smiles">
                    <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseEspera" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/esperanza.jpg" width="70px"></img>
                    <p>Esperanzado</p>
                    </a>
                </div>
                <hr></hr>
                <div className="collapse" id="collapseEspera">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Viaje de la Decepción a la Satisfacción. Surgen expectativas: Pasmo, 
                    Ilusión, Aspiración, Promesa, Interés - Desinterés, Nerviosismo, 
                    Intranquilidad, Inseguridad, Confusión, Alivio, Tranquilidad.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/esperanzado">Explorar</NavLink>
                </div>
                <br/>
                </div> 
                <div className="smiles">
                <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseDisgus" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/disgusto.jpg" width="70px"></img>
                    <p>Disgustado</p>
                    </a>
                </div>
                <hr></hr>
                <div className="collapse" id="collapseDisgus">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Vanidad, Desgano, Desánimo, Stress, Ansiedad, Impaciencia, Envidia, 
                    Celos, Orgullo, Dolor, Pundonor, Aburrimiento, Fastidio, Hastío, 
                    Repugnancia, Asco, Angustia, Desprecio, Aborrecimiento.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/disgustado">Explorar</NavLink>
                </div>
                <br/>
                </div>
                <div className="smiles">
                    <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseEnoj" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/enfado.jpg" width="70px"></img>
                    <p>Enojado</p>
                    </a>
                </div>
                <hr></hr>
                <div className="collapse" id="collapseEnoj">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Soberbia, Celos, Coacción, Exasperación, Enfado, Amenaza, Ira, Odio, 
                    Frustración, Impotencia, Indignación, Hostilidad, Agresividad, Venganza,
                    Furia, Amargura.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/enojado">Explorar</NavLink>
                </div>
                <br/>
                </div>
                <div className="smiles">
                 <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseTeme" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/miedo.jpg" width="70px"></img>
                    <p>Temeroso</p>
                    </a>
                </div>
                <hr></hr>
                <div className="collapse" id="collapseTeme">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Alarma, Susto, Temos, Riesgo, Peligro, Aprensión, Recelo, Terror, 
                    Espanto, Pavura, Fobias, Insensibilidad, Desasociego, Desesperación, 
                    Coacción, Hostilidad Encubierta, Resentimiento no expresado.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/temeroso">Explorar</NavLink>
                </div>
                <br/>
                </div>
                <div className="smiles">
                    <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseTris" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/tristeza.jpg" width="70px"></img>
                    <p>Triste</p>
                    </a>
                </div>
                <hr></hr>
                <div className="collapse" id="collapseTris">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Inferioridad, Nostalgia, Melancolía, Debilidad, Rencor, Decepción, Infelicidad, 
                    Impotencia, Humillación, Decepción, Desilución, Pena, Propiciación, Desazón, 
                    Pesar, Congoja, Desaliento, Pesimismi, Desconsuelo, Aflicción, 
                    Autodegradación, No Merecer.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/triste">Explorar</NavLink>
                </div>
                <br/>
                </div>
                <div className="smiles">
                    <a className="btn" 
                       data-toggle="collapse" 
                       href="#collapseApatico" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                    <img src="https://app.sonria.tv/img/apatia.jpg" width="70px"></img>
                    <p>Apático</p>
                    </a>
                </div>
                <hr></hr>
                <div className="collapse" id="collapseApatico">
                <div className="card card-body">
                    <b>Universo emocional asociado:</b>
                    Resignación, Autodesprecio, Fracaso, Desesperanza, Abulia, Desamparo, 
                    Miseria, Horror, Vegüenza, Culpa, Agonía, Víctima Pasica, Sacrificio.
                    <br/><br/>
                    <NavLink className="btn btn-info" href="/apatico">Explorar</NavLink>
                </div>
                <br/>
                </div>
            </div>
            <br/>
        </main>
    )
}

export default withRouter(MisEmociones)
