import React from 'react'
import {db} from '../firebase'
import Moment from 'react-moment';
import 'moment/locale/es'
import '../index.css';

const TestCreencias = (props) => {

    const [tests, setTests] = React.useState([])
    const [p001, setP001] = React.useState('')
    const [p002, setP002] = React.useState('')
    const [p003, setP003] = React.useState('')
    const [p004, setP004] = React.useState('')
    const [p005, setP005] = React.useState('')
    const [p006, setP006] = React.useState('')
    const [p007, setP007] = React.useState('')
    const [p008, setP008] = React.useState('')
    const [p009, setP009] = React.useState('')
    const [p010, setP010] = React.useState('')
    const [p011, setP011] = React.useState('')
    const [p012, setP012] = React.useState('')
    const [p013, setP013] = React.useState('')
    const [p014, setP014] = React.useState('')
    const [p015, setP015] = React.useState('')
    const [p016, setP016] = React.useState('')
    const [p017, setP017] = React.useState('')
    const [p018, setP018] = React.useState('')
    const [p019, setP019] = React.useState('')
    const [p020, setP020] = React.useState('')
    const [p021, setP021] = React.useState('')
    const [p022, setP022] = React.useState('')
    const [p023, setP023] = React.useState('')
    const [p024, setP024] = React.useState('')
    const [p025, setP025] = React.useState('')
    const [p026, setP026] = React.useState('')
    const [p027, setP027] = React.useState('')
    const [p028, setP028] = React.useState('')
    const [p029, setP029] = React.useState('')
    const [p030, setP030] = React.useState('')
    const [p031, setP031] = React.useState('')
    const [p032, setP032] = React.useState('')
    const [p033, setP033] = React.useState('')
    const [p034, setP034] = React.useState('')
    const [p035, setP035] = React.useState('')
    const [p036, setP036] = React.useState('')
    const [p037, setP037] = React.useState('')
    const [p038, setP038] = React.useState('')
    const [p039, setP039] = React.useState('')
    const [p040, setP040] = React.useState('')
    const [p041, setP041] = React.useState('')
    const [p042, setP042] = React.useState('')
    const [p043, setP043] = React.useState('')
    const [p044, setP044] = React.useState('')
    const [p045, setP045] = React.useState('')
    const [p046, setP046] = React.useState('')
    const [p047, setP047] = React.useState('')
    const [p048, setP048] = React.useState('')
    const [p049, setP049] = React.useState('')
    const [p050, setP050] = React.useState('')
    const [p051, setP051] = React.useState('')
    const [p052, setP052] = React.useState('')
    const [p053, setP053] = React.useState('')
    const [p054, setP054] = React.useState('')
    const [p055, setP055] = React.useState('')
    const [p056, setP056] = React.useState('')
    const [p057, setP057] = React.useState('')
    const [p058, setP058] = React.useState('')
    const [p059, setP059] = React.useState('')
    const [p060, setP060] = React.useState('')
    const [p061, setP061] = React.useState('')
    const [p062, setP062] = React.useState('')
    const [p063, setP063] = React.useState('')
    const [p064, setP064] = React.useState('')
    const [p065, setP065] = React.useState('')
    const [p066, setP066] = React.useState('')
    const [p067, setP067] = React.useState('')
    const [p068, setP068] = React.useState('')
    const [p069, setP069] = React.useState('')
    const [p070, setP070] = React.useState('')
    const [p071, setP071] = React.useState('')
    const [p072, setP072] = React.useState('')
    const [p073, setP073] = React.useState('')
    const [p074, setP074] = React.useState('')
    const [p075, setP075] = React.useState('')
    const [p076, setP076] = React.useState('')
    const [p077, setP077] = React.useState('')
    const [p078, setP078] = React.useState('')
    const [p079, setP079] = React.useState('')
    const [p080, setP080] = React.useState('')
    const [p081, setP081] = React.useState('')
    const [p082, setP082] = React.useState('')
    const [p083, setP083] = React.useState('')
    const [p084, setP084] = React.useState('')
    const [p085, setP085] = React.useState('')
    const [p086, setP086] = React.useState('')
    const [p087, setP087] = React.useState('')
    const [p088, setP088] = React.useState('')
    const [p089, setP089] = React.useState('')
    const [p090, setP090] = React.useState('')
    const [p091, setP091] = React.useState('')
    const [p092, setP092] = React.useState('')
    const [p093, setP093] = React.useState('')
    const [p094, setP094] = React.useState('')
    const [p095, setP095] = React.useState('')
    const [p096, setP096] = React.useState('')
    const [p097, setP097] = React.useState('')
    const [p098, setP098] = React.useState('')
    const [p099, setP099] = React.useState('')
    const [p100, setP100] = React.useState('')
    const [mensaje, setMensaje] = React.useState('')
    const [sumauno, setSumaUno] = React.useState(0)
    const [sumados, setSumaDos] = React.useState(0)
    const [sumatres, setSumaTres] = React.useState(0)
    const [sumacuatro, setSumaCuatro] = React.useState(0)
    const [sumacinco, setSumaCinco] = React.useState(0)
    const [sumaseis, setSumaSeis] = React.useState(0)
    const [sumasiete, setSumaSiete] = React.useState(0)
    const [sumaocho, setSumaOcho] = React.useState(0)
    const [sumanueve, setSumaNueve] = React.useState(0)
    const [sumadiez, setSumaDiez] = React.useState(0)
    const [bloqueuno, setBloqueUno] = React.useState('')
    const [bloquedos, setBloqueDos] = React.useState('')
    const [bloquetres, setBloqueTres] = React.useState('')
    const [bloquecuatro, setBloqueCuatro] = React.useState('')
    const [bloquecinco, setBloqueCinco] = React.useState('')
    const [bloqueseis, setBloqueSeis] = React.useState('')
    const [bloquesiete, setBloqueSiete] = React.useState('')
    const [bloqueocho, setBloqueOcho] = React.useState('')
    const [bloquenueve, setBloqueNueve] = React.useState('')
    const [bloquediez, setBloqueDiez] = React.useState('')
    
  
    React.useEffect(() => {
  
      const obtenerDatos = async () => {
  
        try {
  
          const data = await db.collection("testCreencias:" + props.user.email).get()
          const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          console.log(arrayData)
          setTests(arrayData)
          
        } catch (error) {
          console.log(error)
          console.log("Suma Uno: " + sumauno)
          console.log("Suma Dos: " + sumados)
          console.log("Suma Tres: " + sumatres)
          console.log("Suma Cuatro: " + sumacuatro)
          console.log("Suma Cinco: " + sumacinco)
          console.log("Suma Seis: " + sumaseis)
          console.log("Suma Siete: " + sumasiete)
          console.log("Suma Ocho: " + sumaocho)
          console.log("Suma Nueve: " + sumanueve)
          console.log("Suma Diez: " + sumadiez)
          
        }
  
      }
  
      obtenerDatos()
      setMensaje('')
      
  
    }, [props.user.email])
  
    const agregar = async (e) => {
      e.preventDefault()

      if(!p001.trim()){
        setMensaje('Por favor completa la pregunta 01.')
        return
      }
      if(!p002.trim()){
        setMensaje('Por favor completa la pregunta 02.')
        return
      }
      if(!p003.trim()){
        setMensaje('Por favor completa la pregunta 03.')
        return
      }
      if(!p004.trim()){
        setMensaje('Por favor completa la pregunta 04.')
        return
      }
      if(!p005.trim()){
        setMensaje('Por favor completa la pregunta 05.')
        return
      }
      if(!p006.trim()){
        setMensaje('Por favor completa la pregunta 06.')
        return
      }
      if(!p007.trim()){
        setMensaje('Por favor completa la pregunta 07.')
        return
      }
      if(!p008.trim()){
        setMensaje('Por favor completa la pregunta 08.')
        return
      }
      if(!p009.trim()){
        setMensaje('Por favor completa la pregunta 09.')
        return
      }
      if(!p010.trim()){
        setMensaje('Por favor completa la pregunta 10.')
        return
      }
      if(!p011.trim()){
        setMensaje('Por favor completa la pregunta 11.')
        return
      }
      if(!p012.trim()){
        setMensaje('Por favor completa la pregunta 12.')
        return
      }
      if(!p013.trim()){
        setMensaje('Por favor completa la pregunta 13.')
        return
      }
      if(!p014.trim()){
        setMensaje('Por favor completa la pregunta 14.')
        return
      }
      if(!p015.trim()){
        setMensaje('Por favor completa la pregunta 15.')
        return
      }
      if(!p016.trim()){
        setMensaje('Por favor completa la pregunta 16.')
        return
      }
      if(!p017.trim()){
        setMensaje('Por favor completa la pregunta 17.')
        return
      }
      if(!p018.trim()){
        setMensaje('Por favor completa la pregunta 18.')
        return
      }
      if(!p019.trim()){
        setMensaje('Por favor completa la pregunta 19.')
        return
      }
      if(!p020.trim()){
        setMensaje('Por favor completa la pregunta 20.')
        return
      }
      if(!p021.trim()){
        setMensaje('Por favor completa la pregunta 21.')
        return
      }
      if(!p022.trim()){
        setMensaje('Por favor completa la pregunta 22.')
        return
      }
      if(!p023.trim()){
        setMensaje('Por favor completa la pregunta 23.')
        return
      }
      if(!p024.trim()){
        setMensaje('Por favor completa la pregunta 24.')
        return
      }
      if(!p025.trim()){
        setMensaje('Por favor completa la pregunta 25.')
        return
      }
      if(!p026.trim()){
        setMensaje('Por favor completa la pregunta 26.')
        return
      }
      if(!p027.trim()){
        setMensaje('Por favor completa la pregunta 27.')
        return
      }
      if(!p028.trim()){
        setMensaje('Por favor completa la pregunta 28.')
        return
      }
      if(!p029.trim()){
        setMensaje('Por favor completa la pregunta 29.')
        return
      }
      if(!p030.trim()){
        setMensaje('Por favor completa la pregunta 30.')
        return
      }
      if(!p031.trim()){
        setMensaje('Por favor completa la pregunta 31.')
        return
      }
      if(!p032.trim()){
        setMensaje('Por favor completa la pregunta 32.')
        return
      }
      if(!p033.trim()){
        setMensaje('Por favor completa la pregunta 33.')
        return
      }
      if(!p034.trim()){
        setMensaje('Por favor completa la pregunta 34.')
        return
      }
      if(!p035.trim()){
        setMensaje('Por favor completa la pregunta 35.')
        return
      }
      if(!p036.trim()){
        setMensaje('Por favor completa la pregunta 36.')
        return
      }
      if(!p037.trim()){
        setMensaje('Por favor completa la pregunta 37.')
        return
      }
      if(!p038.trim()){
        setMensaje('Por favor completa la pregunta 38.')
        return
      }
      if(!p039.trim()){
        setMensaje('Por favor completa la pregunta 39.')
        return
      }
      if(!p040.trim()){
        setMensaje('Por favor completa la pregunta 40.')
        return
      }
      if(!p041.trim()){
        setMensaje('Por favor completa la pregunta 41.')
        return
      }
      if(!p042.trim()){
        setMensaje('Por favor completa la pregunta 42.')
        return
      }
      if(!p043.trim()){
        setMensaje('Por favor completa la pregunta 43.')
        return
      }
      if(!p044.trim()){
        setMensaje('Por favor completa la pregunta 44.')
        return
      }
      if(!p045.trim()){
        setMensaje('Por favor completa la pregunta 45.')
        return
      }
      if(!p046.trim()){
        setMensaje('Por favor completa la pregunta 46.')
        return
      }
      if(!p047.trim()){
        setMensaje('Por favor completa la pregunta 47.')
        return
      }
      if(!p048.trim()){
        setMensaje('Por favor completa la pregunta 48.')
        return
      }
      if(!p049.trim()){
        setMensaje('Por favor completa la pregunta 49.')
        return
      }
      if(!p050.trim()){
        setMensaje('Por favor completa la pregunta 50.')
        return
      }
      if(!p051.trim()){
        setMensaje('Por favor completa la pregunta 51.')
        return
      }
      if(!p052.trim()){
        setMensaje('Por favor completa la pregunta 52.')
        return
      }
      if(!p053.trim()){
        setMensaje('Por favor completa la pregunta 53.')
        return
      }
      if(!p054.trim()){
        setMensaje('Por favor completa la pregunta 54.')
        return
      }
      if(!p055.trim()){
        setMensaje('Por favor completa la pregunta 55.')
        return
      }
      if(!p056.trim()){
        setMensaje('Por favor completa la pregunta 56.')
        return
      }
      if(!p057.trim()){
        setMensaje('Por favor completa la pregunta 57.')
        return
      }
      if(!p058.trim()){
        setMensaje('Por favor completa la pregunta 58.')
        return
      }
      if(!p059.trim()){
        setMensaje('Por favor completa la pregunta 59.')
        return
      }
      if(!p060.trim()){
        setMensaje('Por favor completa la pregunta 60.')
        return
      }
      if(!p061.trim()){
        setMensaje('Por favor completa la pregunta 61.')
        return
      }
      if(!p062.trim()){
        setMensaje('Por favor completa la pregunta 62.')
        return
      }
      if(!p063.trim()){
        setMensaje('Por favor completa la pregunta 63.')
        return
      }
      if(!p064.trim()){
        setMensaje('Por favor completa la pregunta 64.')
        return
      }
      if(!p065.trim()){
        setMensaje('Por favor completa la pregunta 65.')
        return
      }
      if(!p066.trim()){
        setMensaje('Por favor completa la pregunta 66.')
        return
      }
      if(!p067.trim()){
        setMensaje('Por favor completa la pregunta 67.')
        return
      }
      if(!p068.trim()){
        setMensaje('Por favor completa la pregunta 68.')
        return
      }
      if(!p069.trim()){
        setMensaje('Por favor completa la pregunta 69.')
        return
      }
      if(!p070.trim()){
        setMensaje('Por favor completa la pregunta 70.')
        return
      }
      if(!p071.trim()){
        setMensaje('Por favor completa la pregunta 71.')
        return
      }
      if(!p072.trim()){
        setMensaje('Por favor completa la pregunta 72.')
        return
      }
      if(!p073.trim()){
        setMensaje('Por favor completa la pregunta 73.')
        return
      }
      if(!p074.trim()){
        setMensaje('Por favor completa la pregunta 74.')
        return
      }
      if(!p075.trim()){
        setMensaje('Por favor completa la pregunta 75.')
        return
      }
      if(!p076.trim()){
        setMensaje('Por favor completa la pregunta 76.')
        return
      }
      if(!p077.trim()){
        setMensaje('Por favor completa la pregunta 77.')
        return
      }
      if(!p078.trim()){
        setMensaje('Por favor completa la pregunta 78.')
        return
      }
      if(!p079.trim()){
        setMensaje('Por favor completa la pregunta 79.')
        return
      }
      if(!p080.trim()){
        setMensaje('Por favor completa la pregunta 80.')
        return
      }
      if(!p081.trim()){
        setMensaje('Por favor completa la pregunta 81.')
        return
      }
      if(!p082.trim()){
        setMensaje('Por favor completa la pregunta 82.')
        return
      }
      if(!p083.trim()){
        setMensaje('Por favor completa la pregunta 83.')
        return
      }
      if(!p084.trim()){
        setMensaje('Por favor completa la pregunta 84.')
        return
      }
      if(!p085.trim()){
        setMensaje('Por favor completa la pregunta 85.')
        return
      }
      if(!p086.trim()){
        setMensaje('Por favor completa la pregunta 86.')
        return
      }
      if(!p087.trim()){
        setMensaje('Por favor completa la pregunta 87.')
        return
      }
      if(!p088.trim()){
        setMensaje('Por favor completa la pregunta 88.')
        return
      }
      if(!p089.trim()){
        setMensaje('Por favor completa la pregunta 89.')
        return
      }
      if(!p090.trim()){
        setMensaje('Por favor completa la pregunta 90.')
        return
      }
      if(!p091.trim()){
        setMensaje('Por favor completa la pregunta 91.')
        return
      }
      if(!p092.trim()){
        setMensaje('Por favor completa la pregunta 92.')
        return
      }
      if(!p093.trim()){
        setMensaje('Por favor completa la pregunta 93.')
        return
      }
      if(!p094.trim()){
        setMensaje('Por favor completa la pregunta 94.')
        return
      }
      if(!p095.trim()){
        setMensaje('Por favor completa la pregunta 95.')
        return
      }
      if(!p096.trim()){
        setMensaje('Por favor completa la pregunta 96.')
        return
      }
      if(!p097.trim()){
        setMensaje('Por favor completa la pregunta 97.')
        return
      }
      if(!p098.trim()){
        setMensaje('Por favor completa la pregunta 98.')
        return
      }
      if(!p099.trim()){
        setMensaje('Por favor completa la pregunta 99.')
        return
      }
      if(!p100.trim()){
        setMensaje('Por favor completa la pregunta 100.')
        return
      }

      try {
  
        const nuevoTest = {
          p001: p001,
          p002: p002,
          p003: p003,
          p004: p004,
          p005: p005,
          p006: p006,
          p007: p007,
          p008: p008,
          p009: p009,
          p010: p010,
          p011: p011,
          p012: p012,
          p013: p013,
          p014: p014,
          p015: p015,
          p016: p016,
          p017: p017,
          p018: p018,
          p019: p019,
          p020: p020,
          p021: p021,
          p022: p022,
          p023: p023,
          p024: p024,
          p025: p025,
          p026: p026,
          p027: p027,
          p028: p028,
          p029: p029,
          p030: p030,
          p031: p031,
          p032: p032,
          p033: p033,
          p034: p034,
          p035: p035,
          p036: p036,
          p037: p037,
          p038: p038,
          p039: p039,
          p040: p040,
          p041: p041,
          p042: p042,
          p043: p043,
          p044: p044,
          p045: p045,
          p046: p046,
          p047: p047,
          p048: p048,
          p049: p049,
          p050: p050,
          p051: p051,
          p052: p052,
          p053: p053,
          p054: p054,
          p055: p055,
          p056: p056,
          p057: p057,
          p058: p058,
          p059: p059,
          p060: p060,
          p061: p061,
          p062: p062,
          p063: p063,
          p064: p064,
          p065: p065,
          p066: p066,
          p067: p067,
          p068: p068,
          p069: p069,
          p070: p070,
          p071: p071,
          p072: p072,
          p073: p073,
          p074: p074,
          p075: p075,
          p076: p076,
          p077: p077,
          p078: p078,
          p079: p079,
          p080: p080,
          p081: p081,
          p082: p082,
          p083: p083,
          p084: p084,
          p085: p085,
          p086: p086,
          p087: p087,
          p088: p088,
          p089: p089,
          p090: p090,
          p091: p091,
          p092: p092,
          p093: p093,
          p094: p094,
          p095: p095,
          p096: p096,
          p097: p097,
          p098: p098,
          p099: p099,
          p100: p100,

          sumauno: sumauno,
          sumados: sumados,
          sumatres: sumatres,
          sumacuatro: sumacuatro,
          sumacinco: sumacinco,
          sumaseis: sumaseis,
          sumasiete: sumasiete,
          sumaocho: sumaocho,
          sumanueve: sumanueve,
          sumadiez: sumadiez,
          bloqueuno: bloqueuno,
          bloquedos: bloquedos,
          bloquetres: bloquetres,
          bloquecuatro: bloquecuatro,
          bloquecinco: bloquecinco,
          bloqueseis: bloqueseis,
          bloquesiete: bloquesiete,
          bloqueocho: bloqueocho,
          bloquenueve: bloquenueve,
          bloquediez: bloquediez,
          fecha: Date.now()
        }
         
        setSumaUno(p001 + p011 + p021 + p031 + p041 + p051 + p061 + p071 + p081 + p091)
        setSumaDos(p002 + p012 + p022 + p032 + p042 + p052 + p062 + p072 + p082 + p092) 
        setSumaTres(p003 + p013 + p023 + p033 + p043 + p053 + p063 + p073 + p083 + p093)
        setSumaCuatro(p004 + p014 + p024 + p034 + p044 + p054 + p064 + p074 + p084 + p094) 
        setSumaCinco(p005 + p015 + p025 + p035 + p045 + p055 + p065 + p075 + p085 + p095)
        setSumaSeis(p006 + p016 + p026 + p036 + p046 + p056 + p066 + p076 + p086 + p096)
        setSumaSiete(p007 + p017 + p027 + p037 + p047 + p057 + p067 + p077 + p087 + p097)
        setSumaOcho(p008 + p018 + p028 + p038 + p048 + p058 + p068 + p078 + p088 + p098)
        setSumaNueve(p009 + p019 + p029 + p039 + p049 + p059 + p069 + p079 + p089 + p099)
        setSumaDiez(p010 + p020 + p030 + p040 + p050 + p060 + p070 + p080 + p090 + p100) 

        
        setBloqueUno('Para un adulto es absolutamente necesario tener el cariño y la aprobación de sus semejantes, familia, amigos.')
        setBloqueDos('Debo ser indefectiblemente competente y casi perfecto en todo lo que emprendo o hago.')
        setBloqueTres('Ciertas personas son malas, viles y perversas y deberían ser castigadas.')
        setBloqueCuatro('Es horrible cuando las cosas no van como a uno le gustaría que fueran.')
        setBloqueCinco(`Los acontecimientos externos son la causa de la mayoría de las desgracias de la humanidad 
        o que la gente simplemente reacciona según cómo los acontecimientos inciden en sus emociones.`)
        setBloqueSeis('Se debe sentir miedo o ansiedad ante cualquier cosa desconocida, incierta o potencialmente peligrosa.')
        setBloqueSiete('Es más fácil evitar los problemas y responsabilidades de la vida que hacerles frente.')
        setBloqueOcho('Se necesita contar con algo más grande y más fuerte que uno mismo.')
        setBloqueNueve('El pasado tiene gran influencia en la determinación del presente.')
        setBloqueDiez('La felicidad aumenta con la inactividad, la pasividad y el ocio indefinido.')

        const data = await db.collection("testCreencias:" + props.user.email).add(nuevoTest)
  
        setTests([
          ...tests,
          {...nuevoTest, id: data.id}
        ])
        
        setMensaje('El Test de Creencias de Ellis fue guardado correctamente.')

        
      } catch (error) {
        console.log(error)
        
      }
      console.log("Suma Uno: " + sumauno)
          console.log("Suma Dos: " + sumados)
          console.log("Suma Tres: " + sumatres)
          console.log("Suma Cuatro: " + sumacuatro)
          console.log("Suma Cinco: " + sumacinco)
          console.log("Suma Seis: " + sumaseis)
          console.log("Suma Siete: " + sumasiete)
          console.log("Suma Ocho: " + sumaocho)
          console.log("Suma Nueve: " + sumanueve)
          console.log("Suma Diez: " + sumadiez)
        
    }
      

    return (
        <main>
            <div className="contenedor">
        
            <div>
            <br/>
            <h5>Test de Creencias de Ellis</h5>
            <h4>Descubriendo mis Creencias limitantes</h4>

                <p>Con este test tendrás más consciencia de las ideas auto-limitantes 
                que pueden estar contribuyendo, de manera inconsciente, a que sientas 
                estrés e infelicidad.</p>
                <p>No es necesario que pienses mucho tiempo en cada pregunta. Escribe rápidamente la respuesta y
                pasa a la siguiente. Asegúrate de que contestas lo que realmente piensas, no lo que crees que
                deberías pensar.</p>
                <p>Sólo marca cada respuesta con “Acuerdo” o “Desacuerdo”.</p>
            <br/>
                    <ul className="lista">
                        {
                        tests.map(item => (         
                            <li key={item.id}>
                              <a className="btn btn-light" 
                                 data-toggle="collapse" 
                                 href={"#"+item.id} 
                                 role="button" 
                                 aria-expanded="false" 
                                 aria-controls="collapseExample">
                                   Fecha: <Moment format="DD-MM-YYYY">{item.fecha}</Moment>
                              </a>
                              <br /> 
                            <div className="collapse" id={item.id}>
                            <div className="card card-body">
                                <b>Creencia Limitante 1: {item.sumauno}%</b>
                                Para un adulto es absolutamente necesario tener el cariño y la aprobación de sus semejantes, familia, amigos.<br/><br/>
                                
                                <b>Creencia Limitante 2: {item.sumados}%</b>
                                Debo ser indefectiblemente competente y casi perfecto en todo lo que emprendo o hago.<br/><br/>

                                <b>Creencia Limitante 3: {item.sumatres}%</b>
                                Ciertas personas son malas, viles y perversas y deberían ser castigadas.<br/><br/>

                                <b>Creencia Limitante 4: {item.sumacuatro}%</b>
                                Es horrible cuando las cosas no van como a uno le gustaría que fueran.<br/><br/>

                                <b>Creencia Limitante 5: {item.sumacinco}%</b>
                                Los acontecimientos externos son la causa de la mayoría de las desgracias de la humanidad 
                                o que la gente simplemente reacciona según cómo los acontecimientos inciden en sus emociones.<br/><br/>

                                <b>Creencia Limitante 6: {item.sumaseis}%</b>
                                Se debe sentir miedo o ansiedad ante cualquier cosa desconocida, incierta o potencialmente peligrosa.<br/><br/>

                                <b>Creencia Limitante 7: {item.sumasiete}%</b>
                                Es más fácil evitar los problemas y responsabilidades de la vida que hacerles frente.<br/><br/>

                                <b>Creencia Limitante 8: {item.sumaocho}%</b>
                                Se necesita contar con algo más grande y más fuerte que uno mismo.<br/><br/>

                                <b>Creencia Limitante 9: {item.sumanueve}%</b>
                                El pasado tiene gran influencia en la determinación del presente.<br/><br/>

                                <b>Creencia Limitante 10: {item.sumadiez}%</b>
                                La felicidad aumenta con la inactividad, la pasividad y el ocio indefinido.<br/>

                                <br />
                                <p className="peque">Con una puntuación de 5 ó 6 puntos, podemos 
                                decir que la creencia en la que hayas obtenido dicha puntuación te 
                                limita sólo en determinadas circunstancias, mientras que si tu 
                                puntuación es igual o superior a 7, dicha creencia te está limitando 
                                en muchas áreas de su vida.</p>
                                {item.bloqueuno}
                                {item.bloquedos}
                                {item.bloquetres}
                                {item.bloquecuatro}
                                {item.bloquecinco}
                                {item.bloqueseis}
                                {item.bloquesiete}
                                {item.bloqueocho}
                                {item.bloquenueve}
                                {item.bloquediez}

                            </div>
                            </div>
                            </li>
                        ))
                        }
                    </ul>
                    
        </div>
        <div>
                    <br/>
                    <h5>
                    <a className="btn btn-secondary" 
                       data-toggle="collapse" 
                       href="#collapseForm" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                          Realizar Test
                    </a>              
                    </h5>
                    <div className="collapse" id="collapseForm">
                    <div className="card card-body">
        
                    <form onSubmit={agregar}>
                        <p>1. Para mí es importante recibir la aprobación de los demás.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p001 === '10'} 
                                        onChange={e => setP001(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p001 === '0'} 
                                        onChange={e => setP001(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>    
                            <p>2. Odio equivocarme en algo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p002 === '10'} 
                                        onChange={e => setP002(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p002 === '0'} 
                                        onChange={e => setP002(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>3. La gente que se equivoca, logra lo que se merece.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p003 === '10'} 
                                        onChange={e => setP003(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p003 === '0'} 
                                        onChange={e => setP003(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>4. Generalmente, acepto los acontecimientos con filosofía.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p004 === '0'} 
                                        onChange={e => setP004(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p004 === '10'} 
                                        onChange={e => setP004(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>5. Si una persona quiere, puede ser feliz en casi cualquier circunstancia.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p005 === '0'} 
                                        onChange={e => setP005(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p005 === '10'} 
                                        onChange={e => setP005(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>6. Temo a las cosas que, a menudo, me resultan objeto de preocupación.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p006 === '10'} 
                                        onChange={e => setP006(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p006 === '0'} 
                                        onChange={e => setP006(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>7. Normalmente, aplazo las decisiones importantes.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p007 === '10'} 
                                        onChange={e => setP007(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p007 === '0'} 
                                        onChange={e => setP007(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div> 
                            <p>8. Todo el mundo necesita de alguien a quien recurrir en busca de ayuda y consejo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p008 === '10'} 
                                        onChange={e => setP008(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p008 === '0'} 
                                        onChange={e => setP008(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>9. Una cebra no puede cambiar sus rayas.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p009 === '10'} 
                                        onChange={e => setP009(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p009 === '0'} 
                                        onChange={e => setP009(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>10. Prefiero, sobre todas las cosas, pasar el tiempo libre de una forma tranquila.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p010 === '10'} 
                                        onChange={e => setP010(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p010 === '0'} 
                                        onChange={e => setP010(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>11. Me gusta que los demás me respeten, pero yo no tengo por qué manifestar respeto a nadie.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p011 === '10'} 
                                        onChange={e => setP011(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p011 === '0'} 
                                        onChange={e => setP011(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>12. Evito las cosas que no puedo hacer bien.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p012 === '10'} 
                                        onChange={e => setP012(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p012 === '0'} 
                                        onChange={e => setP012(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>13. Hay demasiadas personas malas que escapan del castigo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p013 === '10'} 
                                        onChange={e => setP013(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p013 === '0'} 
                                        onChange={e => setP013(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>14. Las frustraciones no me distorsionan.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p014 === '0'} 
                                        onChange={e => setP014(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p014 === '10'} 
                                        onChange={e => setP014(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>15. A la gente no le trastornan los acontecimientos, sino la imagen que tiene de ellos.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p015 === '0'} 
                                        onChange={e => setP015(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p015 === '10'} 
                                        onChange={e => setP015(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>16. Me producen poca ansiedad los peligros inesperados o los acontecimientos futuros.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p016 === '0'} 
                                        onChange={e => setP016(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p016 === '10'} 
                                        onChange={e => setP016(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>17. Trato de afrontar los trabajos fastidiosos y hacerlos cuanto antes.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p017 === '0'} 
                                        onChange={e => setP017(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p017 === '10'} 
                                        onChange={e => setP017(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>18. En las decisiones importantes, consulto con una autoridad al respecto.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p018 === '10'} 
                                        onChange={e => setP018(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p018 === '0'} 
                                        onChange={e => setP018(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>19. Es casi imposible superar la influencia del pasado.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p019 === '10'} 
                                        onChange={e => setP019(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p019 === '0'} 
                                        onChange={e => setP019(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>20. Me gusta disponer de muchos recursos.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p020 === '0'} 
                                        onChange={e => setP020(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p020 === '10'} 
                                        onChange={e => setP020(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>21. Quiero gustar a todo el mundo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p021 === '10'} 
                                        onChange={e => setP021(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p021 === '0'} 
                                        onChange={e => setP021(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>22. No me gusta competir en aquellas actividades en las que
                                los demás son mejores que yo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p022 === '0'} 
                                        onChange={e => setP022(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p022 === '10'} 
                                        onChange={e => setP022(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>23. Aquellos que se equivocan, merecen cargar con las culpas.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p023 === '10'} 
                                        onChange={e => setP023(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p023 === '0'} 
                                        onChange={e => setP023(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>24. Las cosas deberían ser distintas a como son.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p024 === '10'} 
                                        onChange={e => setP024(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p024 === '0'} 
                                        onChange={e => setP024(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>25. Yo provoco mi propio mal humor.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p025 === '0'} 
                                        onChange={e => setP025(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p025 === '10'} 
                                        onChange={e => setP025(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>26. A menudo, no puedo quitarme algún asunto de la cabeza.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p026 === '10'} 
                                        onChange={e => setP026(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p026 === '0'} 
                                        onChange={e => setP026(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>27. Evito enfrentarme a los problemas.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p027 === '10'} 
                                        onChange={e => setP027(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p027 === '0'} 
                                        onChange={e => setP027(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>28. Todo el mundo necesita tener fuera de sí mismo una fuente de energía.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p028 === '10'} 
                                        onChange={e => setP028(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p028 === '0'} 
                                        onChange={e => setP028(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>29. Sólo porque una vez algo afectó tu vida de forma
                                importante, no quiere decir que tenga que ser igual en un futuro.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p029 === '0'} 
                                        onChange={e => setP029(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p029 === '10'} 
                                        onChange={e => setP029(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>30. Me siento más satisfecho cuando tengo muchas cosas que hacer.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p030 === '0'} 
                                        onChange={e => setP030(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p030 === '10'} 
                                        onChange={e => setP030(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>31. Puedo gustarme a mí mismo, aun cuando no guste a los demás.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p031 === '0'} 
                                        onChange={e => setP031(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p031 === '10'} 
                                        onChange={e => setP031(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>32. Me gustaría triunfar en algo, pero no pienso que deba hacerlo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p032 === '0'} 
                                        onChange={e => setP032(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p032 === '10'} 
                                        onChange={e => setP032(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>33. La inmoralidad debería castigarse severamente.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p033 === '10'} 
                                        onChange={e => setP033(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p033 === '0'} 
                                        onChange={e => setP033(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>34. A menudo me siento trastornado/a por situaciones que no me gustan.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p034 === '10'} 
                                        onChange={e => setP034(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p034 === '0'} 
                                        onChange={e => setP034(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>35. Las personas desgraciadas, normalmente se deben este estado a sí mismas.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p035 === '0'} 
                                        onChange={e => setP035(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p035 === '10'} 
                                        onChange={e => setP035(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>36. No me preocupo por no poder evitar que algo ocurra.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p036 === '0'} 
                                        onChange={e => setP036(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p036 === '10'} 
                                        onChange={e => setP036(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>37. Normalmente, tomo las decisiones tan pronto como puedo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p037 === '0'} 
                                        onChange={e => setP037(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p037 === '10'} 
                                        onChange={e => setP037(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>38. Hay determinadas personas de las que dependo mucho.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p038 === '10'} 
                                        onChange={e => setP038(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p038 === '0'} 
                                        onChange={e => setP038(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>39. La gente sobrevalora la influencia del pasado.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p039 === '0'} 
                                        onChange={e => setP039(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p039 === '10'} 
                                        onChange={e => setP039(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>40. Lo que más me divierte es realizar algún proyecto creativo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p040 === '0'} 
                                        onChange={e => setP040(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p040 === '10'} 
                                        onChange={e => setP040(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>41. Si no gusto a los demás es su problema, no el mío.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p041 === '0'} 
                                        onChange={e => setP041(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p041 === '10'} 
                                        onChange={e => setP041(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>42. Para mí es muy importante lograr el éxito en todo lo que hago.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p042 === '10'} 
                                        onChange={e => setP042(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p042 === '0'} 
                                        onChange={e => setP042(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>43. Pocas veces culpo a la gente de sus errores.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p043 === '0'} 
                                        onChange={e => setP043(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p043 === '10'} 
                                        onChange={e => setP043(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>44. Normalmente, acepto las cosas como son, aunque no me gusten.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p044 === '0'} 
                                        onChange={e => setP044(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p044 === '10'} 
                                        onChange={e => setP044(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>45. Nadie está mucho tiempo de mal humor o enfadado, a menos que quiera estarlo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p045 === '0'} 
                                        onChange={e => setP045(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p045 === '10'} 
                                        onChange={e => setP045(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>46. No puedo soportar correr riesgos.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p046 === '10'} 
                                        onChange={e => setP046(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p046 === '0'} 
                                        onChange={e => setP046(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>47. La vida es demasiado corta para pasarla haciendo cosas
                                que a uno no le gustan.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p047 === '10'} 
                                        onChange={e => setP047(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p047 === '0'} 
                                        onChange={e => setP047(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>48. Me gusta valerme por mi mismo/a.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p048 === '0'} 
                                        onChange={e => setP048(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p048 === '10'} 
                                        onChange={e => setP048(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>49. Si hubiera vivido experiencias distintas, podría ser más
                                como me gustaría ser.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p049 === '10'} 
                                        onChange={e => setP049(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p049 === '0'} 
                                        onChange={e => setP049(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>50. Me gustaría jubilarme y apartarme totalmente del trabajo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p050 === '10'} 
                                        onChange={e => setP050(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p050 === '0'} 
                                        onChange={e => setP050(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>51. Pienso que es duro ir en contra de lo que piensan los demás.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p051 === '10'} 
                                        onChange={e => setP051(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p051 === '0'} 
                                        onChange={e => setP051(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>52. Disfruto de las actividades por sí mismas, al margen de lo
                                bueno/a malo/a que sea en ellas.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p052 === '0'} 
                                        onChange={e => setP052(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p052 === '10'} 
                                        onChange={e => setP052(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>53. El miedo al castigo es lo que hace a la gente ser buena.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p053 === '10'} 
                                        onChange={e => setP053(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p053 === '0'} 
                                        onChange={e => setP053(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>54. Si las cosas me desagradan opto por ignorarlas.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p054 === '0'} 
                                        onChange={e => setP054(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p054 === '10'} 
                                        onChange={e => setP054(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>55. Cuanto más problemas tiene una persona, menos feliz es.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p055 === '10'} 
                                        onChange={e => setP055(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p055 === '0'} 
                                        onChange={e => setP055(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>56. Raramente me siento ansioso/a al pensar en el futuro.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p056 === '0'} 
                                        onChange={e => setP056(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p056 === '10'} 
                                        onChange={e => setP056(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>57. Raramente aplazo las cosas.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p057 === '0'} 
                                        onChange={e => setP057(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p057 === '10'} 
                                        onChange={e => setP057(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>58. Yo soy el/la único/a que realmente puede entender y
                                solucionar mis problemas.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p058 === '0'} 
                                        onChange={e => setP058(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p058 === '10'} 
                                        onChange={e => setP058(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>59. Normalmente, no pienso que las experiencias pasadas me
                                afecten en la actualidad.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p059 === '0'} 
                                        onChange={e => setP059(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p059 === '10'} 
                                        onChange={e => setP059(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div> 
                            <p>60. Tener demasiado tiempo libre, resulta aburrido.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p060 === '0'} 
                                        onChange={e => setP060(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p060 === '10'} 
                                        onChange={e => setP060(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>   
                            <p>61. Aunque me gusta recibir la aprobación de los demás, no
                                tengo necesidad real de ello.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p061 === '0'} 
                                        onChange={e => setP061(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p061 === '10'} 
                                        onChange={e => setP061(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>62. Me fastidia que los demás sean mejores que yo en algo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p062 === '10'} 
                                        onChange={e => setP062(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p062 === '0'} 
                                        onChange={e => setP062(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>63. Todo el mundo es, esencialmente, bueno.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p063 === '10'} 
                                        onChange={e => setP063(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p063 === '0'} 
                                        onChange={e => setP063(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>64. Hago todo lo que puedo por conseguir lo que quiero y una
                                vez conseguido, deja de preocuparme.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p064 === '0'} 
                                        onChange={e => setP064(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p064 === '10'} 
                                        onChange={e => setP064(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>65. Nada es intrínsicamente perturbador; si lo es se debe al modo en que lo interpretamos.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p065 === '0'} 
                                        onChange={e => setP065(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p065 === '10'} 
                                        onChange={e => setP065(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>66. Me preocupan mucho determinadas cosas del futuro.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p066 === '10'} 
                                        onChange={e => setP066(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p066 === '0'} 
                                        onChange={e => setP066(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>67. Me resulta difícil hacer las tareas desagradables.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p067 === '10'} 
                                        onChange={e => setP067(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p067 === '0'} 
                                        onChange={e => setP067(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>68. Me desagrada que los demás tomen decisiones por mí.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p068 === '0'} 
                                        onChange={e => setP068(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p068 === '10'} 
                                        onChange={e => setP068(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>69. Somos esclavos de nuestro pasado.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p069 === '10'} 
                                        onChange={e => setP069(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p069 === '0'} 
                                        onChange={e => setP069(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>70. A veces desearía poder irme a una isla tropical y tenderme
                                en la playa, sin hacer nada más.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p070 === '10'} 
                                        onChange={e => setP070(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p070 === '0'} 
                                        onChange={e => setP070(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>71. A menudo me preocupa que la gente me apruebe y me acepte.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p071 === '10'} 
                                        onChange={e => setP071(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p071 === '0'} 
                                        onChange={e => setP071(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>72. Me trastorna cometer errores.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p072 === '10'} 
                                        onChange={e => setP072(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p072 === '0'} 
                                        onChange={e => setP072(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>73. No es equitativo que "llueva igual sobre el justo que sobre el injusto".</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p073 === '10'} 
                                        onChange={e => setP073(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p073 === '0'} 
                                        onChange={e => setP073(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>74. Yo disfruto honradamente de la vida.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p074 === '0'} 
                                        onChange={e => setP074(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p074 === '10'} 
                                        onChange={e => setP074(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>75. Debería haber más personas que afrontaran lo
                                desagradable de la vida.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p075 === '10'} 
                                        onChange={e => setP075(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p075 === '0'} 
                                        onChange={e => setP075(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>76. Algunas veces, me resulta imposible apartar de mi mente el miedo a algo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p076 === '0'} 
                                        onChange={e => setP076(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p076 === '10'} 
                                        onChange={e => setP076(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>77. Una vida fácil, muy pocas veces resulta compensadora.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p077 === '10'} 
                                        onChange={e => setP077(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p077 === '0'} 
                                        onChange={e => setP077(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>78. Pienso que es fácil buscar ayuda.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p078 === '10'} 
                                        onChange={e => setP078(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p078 === '0'} 
                                        onChange={e => setP078(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>79. Cada vez que algo afecta tu vida de forma importante,
                                seguirá haciéndolo siempre.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p079 === '0'} 
                                        onChange={e => setP079(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p079 === '10'} 
                                        onChange={e => setP079(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>80. Me encanta estar acostado/a sin hacer nada.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p080 === '0'} 
                                        onChange={e => setP080(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p080 === '10'} 
                                        onChange={e => setP080(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>81. Tengo considerable preocupación por lo que la gente
                                piensa de mí.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p081 === '10'} 
                                        onChange={e => setP081(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p081 === '0'} 
                                        onChange={e => setP081(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>82. Muchas veces me enfado muchísimo por cosas sin importancia.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p082 === '10'} 
                                        onChange={e => setP082(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p082 === '0'} 
                                        onChange={e => setP082(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>83. Generalmente doy una segunda oportunidad a quien se equivoca.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p083 === '0'} 
                                        onChange={e => setP083(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p083 === '10'} 
                                        onChange={e => setP083(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>84. La gente es más feliz cuando tiene metas y problemas que resolver.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p084 === '10'} 
                                        onChange={e => setP084(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p084 === '0'} 
                                        onChange={e => setP084(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>85. Nunca hay razón para permanecer afligido mucho tiempo.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p085 === '0'} 
                                        onChange={e => setP085(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p085 === '10'} 
                                        onChange={e => setP085(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>86. Raramente pienso en cosas como la muerte.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p086 === '0'} 
                                        onChange={e => setP086(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p086 === '10'} 
                                        onChange={e => setP086(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>87. No me gustan las responsabilidades.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p087 === '0'} 
                                        onChange={e => setP087(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p087 === '10'} 
                                        onChange={e => setP087(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>88. No me gusta depender de los demás.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p088 === '0'} 
                                        onChange={e => setP088(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p088 === '10'} 
                                        onChange={e => setP088(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>89. La gente nunca cambia, básicamente.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p089 === '10'} 
                                        onChange={e => setP089(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p089 === '0'} 
                                        onChange={e => setP089(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>90. La mayoría de las personas trabaja demasiado y no
                                descansa lo suficiente.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p090 === '10'} 
                                        onChange={e => setP090(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p090 === '0'} 
                                        onChange={e => setP090(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>91. Ser criticado es algo fastidioso pero no perturbador.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p091 === '0'} 
                                        onChange={e => setP091(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p091 === '10'} 
                                        onChange={e => setP091(e.target.value)}
                                        onClick={e => setSumaUno(sumauno + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>92. No me asusta hacer aquellas cosas que no hago del todo bien.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p092 === '0'} 
                                        onChange={e => setP092(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p092 === '10'} 
                                        onChange={e => setP092(e.target.value)}
                                        onClick={e => setSumaDos(sumados + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>93. Nadie es malo a pesar de que sus actos lo sean.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p093 === '0'} 
                                        onChange={e => setP093(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p093 === '10'} 
                                        onChange={e => setP093(e.target.value)}
                                        onClick={e => setSumaTres(sumatres + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>94. Raramente me importunan los errores de los demás.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p094 === '0'} 
                                        onChange={e => setP094(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p094 === '10'} 
                                        onChange={e => setP094(e.target.value)}
                                        onClick={e => setSumaCuatro(sumacuatro + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>95. El hombre construye su propio malestar interno.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p095 === '0'} 
                                        onChange={e => setP095(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p095 === '10'} 
                                        onChange={e => setP095(e.target.value)}
                                        onClick={e => setSumaCinco(sumacinco + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>96. Muchas veces me sorprendo planeando lo que haría si me
                                encontrara en determinadas situaciones de peligro.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p096 === '10'} 
                                        onChange={e => setP096(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p096 === '0'} 
                                        onChange={e => setP096(e.target.value)}
                                        onClick={e => setSumaSeis(sumaseis + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>97. Si tengo que hacer algo, lo hago a pesar de que no sea agradable.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p097 === '0'} 
                                        onChange={e => setP097(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p097 === '10'} 
                                        onChange={e => setP097(e.target.value)}
                                        onClick={e => setSumaSiete(sumasiete + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>98. He aprendido a no estar pendiente de nada que no esté
                                relacionado con mi bienestar.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p098 === '0'} 
                                        onChange={e => setP098(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p098 === '10'} 
                                        onChange={e => setP098(e.target.value)}
                                        onClick={e => setSumaOcho(sumaocho + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>99. No miro atrás con resentimiento.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={p099 === '0'} 
                                        onChange={e => setP099(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 0)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="10" 
                                        checked={p099 === '10'} 
                                        onChange={e => setP099(e.target.value)}
                                        onClick={e => setSumaNueve(sumanueve + 10)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>
                            <p>100. No me siento realmente contento hasta que no estoy
                                relajado y sin hacer nada.</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="10" 
                                        checked={p100 === '10'} 
                                        onChange={e => setP100(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 10)}
                                    /> Acuerdo
                                </p>
                                </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="0" 
                                        checked={p100 === '0'} 
                                        onChange={e => setP100(e.target.value)}
                                        onClick={e => setSumaDiez(sumadiez + 0)}
                                    /> Desacuerdo
                                </p>
                                </div>
                            </div>                                       
                        <br/>

                        {
                        mensaje && (
                            <div className="alert alert-info">
                                {mensaje}
                                <br/>
                            </div>
                        )
                        }
                        
                        {
                        bloqueuno && (
                            <div>
                                <h5>Creencia Limitante 1</h5>
                                <h4>{bloqueuno}</h4>
                                <h5>{parseFloat(p001) + parseFloat(p011) + parseFloat(p021) + parseFloat(p031) + 
                                        parseFloat(p041) + parseFloat(p051) + parseFloat(p061) + parseFloat(p071) + 
                                        parseFloat(p081) + parseFloat(p091)}%</h5>
                                <p>Cuanto mayor haya sido la puntuación alcanzada, más arraigada está la creencia limitante.</p>
                                <p>Es realmente imposible gustar a todas las personas que nos rodean. Incluso aquellas
                                personas a las que gustamos básicamente y nos aprueban, discreparán en algunas de
                                nuestras conductas y cualidades. Esta creencia es probablemente la mayor causa de
                                infelicidad, ya que estaremos continuamente forzándonos a actuar de acuerdo a las
                                expectativas de los demás para conseguir su aprobación. Generalmente se corre menos
                                riesgo de rechazo si uno se muestra a los demás tal como es; pueden tomarlo o dejarlo, pero
                                si te aceptan, ya no tienes que preocuparte de bajar la guardia ni de que te rechacen más
                                tarde.</p>
                            </div>     
                        )
                        }
                        {
                        bloquedos && (
                            <div>
                                <h5>Creencia Limitante 2</h5>
                                <h4>{bloquedos}</h4>
                                <h5>{parseFloat(p002) + parseFloat(p012) + parseFloat(p022) + parseFloat(p032) + 
                                    parseFloat(p042) + parseFloat(p052) + parseFloat(p062) + parseFloat(p072) + 
                                    parseFloat(p082) + parseFloat(p092)}%</h5>
                                <p>Cuanto mayor haya sido la puntuación alcanzada, más arraigada está la creencia limitante.</p>
                                <p>Los resultados de la creencia de que uno ha de ser perfecto son: los auto-reproches ante el
                                fallo inevitable, disminución de la autoestima, aplicación de patrones perfeccionistas y
                                paralización y miedo al intentar cualquier cosa.</p>
                            </div>     
                        )
                        }
                        {
                        bloquetres && (
                            <div>
                                <h5>Creencia Limitante 3</h5>
                                <h4>{bloquetres}</h4>
                                <h5>{parseFloat(p003) + parseFloat(p013) + parseFloat(p023) + parseFloat(p033) + 
                                    parseFloat(p043) + parseFloat(p053) + parseFloat(p063) + parseFloat(p073) + 
                                    parseFloat(p083) + parseFloat(p093)}%</h5>
                                <p>Cuanto mayor haya sido la puntuación alcanzada, más arraigada está la creencia limitante.</p>
                                <p>Una posición más real es pensar que las personas que se comportan de modo antisocial e
                                inapropiado, quizá son personas que necesitan ayuda y que tienen un sistema de creencias
                                desequilibrado y un deficiencias afectivas que se reflejan en sus actos.</p>
                            </div>     
                        )
                        }
                        {
                        bloquecuatro && (
                            <div>
                                <h5>Creencia Limitante 4</h5>
                                <h4>{bloquecuatro}</h4>
                                <h5>{parseFloat(p004) + parseFloat(p014) + parseFloat(p024) + parseFloat(p034) + 
                                    parseFloat(p044) + parseFloat(p054) + parseFloat(p064) + parseFloat(p074) + 
                                    parseFloat(p084) + parseFloat(p094)}%</h5>
                                <p>Cuanto mayor haya sido la puntuación alcanzada, más arraigada está la creencia limitante.</p>
                                <p>Tan pronto como algo “sale mal”, empieza el diálogo interno "¿Por qué me pasa esto a mí? No
                                puedo soportarlo. Es horrible, todo es un desastre". Cualquier inconveniente, problema o fallo 
                                que aparezca en tu camino se interpreta de este modo. El resultado es una profunda irritación e intenso estrés.</p>
                            </div>     
                        )
                        }
                        {
                        bloquecinco && (
                            <div>
                                <h5>Creencia Limitante 5</h5>
                                <h4>{bloquecinco}</h4>
                                <h5>{parseFloat(p005) + parseFloat(p015) + parseFloat(p025) + parseFloat(p035) + 
                                    parseFloat(p045) + parseFloat(p055) + parseFloat(p065) + parseFloat(p075) + 
                                    parseFloat(p085) + parseFloat(p095)}%</h5>
                                <p>Cuanto mayor haya sido la puntuación alcanzada, más arraigada está la creencia limitante.</p>
                                <p>Una deducción lógica, a partir de esta creencia, es pensar que para lograr la felicidad o evitar
                                la tristeza hay que controlar los acontecimientos externos. Dado que ese control es limitado y
                                que no podemos controlar los deseos y comportamientos de los demás, el resultado es la
                                sensación de infelicidad y una ansiedad crónica, Atribuir la falta de felicidad a los
                                acontecimientos es una forma de evitar enfrentarse con la realidad. Las propias
                                interpretaciones de los hechos son las que causan la infelicidad. Mientras que poseemos un
                                control limitado sobre las acciones de los demás, disponemos de un gran control de nuestras
                                emociones y acciones.</p>
                            </div>     
                        )
                        }
                        {
                        bloqueseis && (
                            <div>
                                <h5>Creencia Limitante 6</h5>
                                <h4>{bloqueseis}</h4>
                                <h5>{parseFloat(p006) + parseFloat(p016) + parseFloat(p026) + parseFloat(p036) + 
                                    parseFloat(p046) + parseFloat(p056) + parseFloat(p066) + parseFloat(p076) + 
                                    parseFloat(p086) + parseFloat(p096)}%</h5>
                                <p>Cuanto mayor haya sido la puntuación alcanzada, más arraigada está la creencia limitante.</p>
                                <p>Esto a veces se expresa de la siguiente forma: "sólo con que
                                suene un timbre, ya empiezo a preocuparme". y muchas personas empiezan a enumerar los
                                escenarios de la catástrofe.
                                Desarrollar miedo o ansiedad ante lo incierto hace que todo se vea desde una perspectiva
                                aterradora y aumenta el estrés; por el contrario, reservar las respuestas de miedo al peligro
                                real permite disfrutar de lo incierto como una experiencia nueva y estimulante.</p>
                            </div>     
                        )
                        }
                        {
                        bloquesiete && (
                            <div>
                                <h5>Creencia Limitante 7</h5>
                                <h4>{bloquesiete}</h4>
                                <h5>{parseFloat(p007) + parseFloat(p017) + parseFloat(p027) + parseFloat(p037) + 
                                    parseFloat(p047) + parseFloat(p057) + parseFloat(p067) + parseFloat(p077) + 
                                    parseFloat(p087) + parseFloat(p097)}%</h5>
                                <p>Cuanto mayor haya sido la puntuación alcanzada, más arraigada está la creencia limitante.</p>
                                <p>Esta creencia es la base para eludir las responsabilidades y no hacernos conscientes de
                                nuestra vida. Hay muchas formas de expresarla: "Debería decirle que ya no me interesa, pero
                                no esta noche.” “Tendría que encontrar otro trabajo, pero estoy demasiado cansado para
                                ponerme a buscarlo en los ratos libres.” “Un grifo que gotea no hace daño a nadie” “Podría ir
                                de compras hoy, pero el coche hace una especie de ruido raro”.</p>
                            </div>     
                        )
                        }
                        {
                        bloqueocho && (
                            <div>
                                <h5>Creencia Limitante 8</h5>
                                <h4>{bloqueocho}</h4>
                                <h5>{parseFloat(p008) + parseFloat(p018) + parseFloat(p028) + parseFloat(p038) + 
                                    parseFloat(p048) + parseFloat(p058) + parseFloat(p068) + parseFloat(p078) + 
                                    parseFloat(p088) + parseFloat(p098)}%</h5>
                                <p>Cuanto mayor haya sido la puntuación alcanzada, más arraigada está la creencia limitante.</p>
                                <p>Esta creencia se convierte en una trampa psicológica en la que sus opiniones y
                                conocimientos quedan atrapados por la dependencia desarrollada hacia una autoridad superior.</p>
                            </div>     
                        )
                        }
                        {
                        bloquenueve && (
                            <div>
                                <h5>Creencia Limitante 9</h5>
                                <h4>{bloquenueve}</h4>
                                <h5>{parseFloat(p009) + parseFloat(p019) + parseFloat(p029) + parseFloat(p039) + 
                                    parseFloat(p049) + parseFloat(p059) + parseFloat(p069) + parseFloat(p079) + 
                                    parseFloat(p089) + parseFloat(p099)}%</h5>
                                <p>Cuanto mayor haya sido la puntuación alcanzada, más arraigada está la creencia limitante.</p>
                                <p>Sólo porque una vez algo le afectó de forma importante no significa que deba continuar con
                                las conductas que desarrolló en esta situación primera. Esas formas y modelo de conducta
                                son sólo decisiones tomadas tantas veces que se han hecho casi automáticas. Debe
                                averiguar cuáles son y empezar a cambiarlas ahora mismo. Hay que aprender de las
                                experiencias pasadas, pero no debemos permanecer atados a ellas indefinidamente.</p>
                            </div>     
                        )
                        }
                        {
                        bloquediez && (
                            <div>
                                <h5>Creencia Limitante 10</h5>
                                <h4>{bloquediez}</h4>
                                <h5>{parseFloat(p010) + parseFloat(p020) + parseFloat(p030) + parseFloat(p040) + 
                                parseFloat(p050) + parseFloat(p060) + parseFloat(p070) + parseFloat(p080) + 
                                parseFloat(p090) + parseFloat(p100)}%</h5>
                                <p>Cuanto mayor haya sido la puntuación alcanzada, más arraigada está la creencia limitante.</p>
                                <p>Esto es el llamado "Síndrome de los Campos Elíseos". La relajación absoluta puede formar
                                parte momentánea de la felicidad, pero existen más formas de Felicidad: el trabajo bien
                                realizado, el esfuerzo por conseguir los propios objetivos.</p>
                            </div>     
                        )
                        }
                        
                        <br/>
                        <div>
                        <button 
                        className='btn btn-dark btn-block mt-2'
                        type="submit"
                        >
                        Ver Resultado y Guardar Test
                        </button>
                        </div>
                        <br /><br />
                    </form>
                    </div>
                </div>
            </div> 
     </div>  
        </main>
    )
}

export default TestCreencias