import React from 'react'
import {db} from '../firebase'
import moment from 'moment'
import 'moment/locale/es'
import '../index.css';

const Audiencia = (props) => {

    const [audiencias, setAudiencias] = React.useState([])
    const [disertante, setDisertante] = React.useState('')
    const [horario, setHorario] = React.useState('')
    const [observador, setObservador] = React.useState('')
    const [titulo, setTitulo] = React.useState('')
    const [contexto, setContexto] = React.useState('')
    const [prepersonal, setPrepersonal] = React.useState('')
    const [modelocom, setModelocom] = React.useState('')
    const [preoral, setPreoral] = React.useState('')
    const [explorar, setExplorar] = React.useState('')
    const [ensala, setEnsala] = React.useState('')
    const [espacio, setEspacio] = React.useState('')
    const [corporalidad, setCorporalidad] = React.useState('')
    const [fortalezas, setFortalezas] = React.useState('')
    const [animo, setAnimo] = React.useState('')
    const [participacion, setParticipacion] = React.useState('')
    const [contenidos, setContenidos] = React.useState('')
    const [tiempo, setTiempo] = React.useState('')
    const [recursos, setRecursos] = React.useState('')
    const [aprendizajes, setAprendizajes] = React.useState('')
    const [met, setMet] = React.useState('')
    const [coach, setCoach] = React.useState('')
    const [mensaje, setMensaje] = React.useState('')
    const [modoEdicion, setModoEdicion] = React.useState(false)
    const [id, setId] = React.useState('')
  
  
    React.useEffect(() => {
  
      const obtenerDatos = async () => {
  
        try {
  
          const data = await db.collection("audiencias:" + props.user.email).get()
          const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          console.log(arrayData)
          setAudiencias(arrayData)
          
        } catch (error) {
          console.log(error)
        }
  
      }
  
      obtenerDatos()
      setMensaje('')
  
    }, [props.user.email])
  
    const agregar = async (e) => {
      e.preventDefault()
  
      if(!disertante.trim()){
        setMensaje('Completa el disertante')
        return
      }
      if(!horario.trim()){
        setMensaje('Completa la fecha y la hora')
        return
      }
      if(!observador.trim()){
        setMensaje('Completa el observador')
        return
      }
      if(!titulo.trim()){
        setMensaje('Completa el título')
        return
      }
      if(!contexto.trim()){
        setMensaje('Completa los contextos de obviedad')
        return
      }
      if(!prepersonal.trim()){
        setMensaje('Completa la presentación personal')
        return
      }
      if(!modelocom.trim()){
        setMensaje('Completa el modelo de comunicación')
        return
      }
      if(!preoral.trim()){
        setMensaje('Completa la presentación oral')
        return
      }
      if(!explorar.trim()){
        setMensaje('Completa cómo explora la zona de comodidad')
        return
      }
      if(!ensala.trim()){
        setMensaje('Completa cómo se comporta en sala')
        return
      }
      if(!espacio.trim()){
        setMensaje('Completa cómo maneja el espacio')
        return
      }
      if(!corporalidad.trim()){
        setMensaje('Completa la corporalidad')
        return
      }
      if(!fortalezas.trim()){
        setMensaje('Completa las fortalezas observadas')
        return
      }
      if(!animo.trim()){
        setMensaje('Completa el estado de ánimo')
        return
      }
      if(!participacion.trim()){
        setMensaje('Completa el grado de participación')
        return
      }
      if(!contenidos.trim()){
        setMensaje('Completa cómo maneja los contenidos')
        return
      }
      if(!tiempo.trim()){
        setMensaje('Completa el manejo del tiempo')
        return
      }
      if(!recursos.trim()){
        setMensaje('Completa los recursos utilizados')
        return
      }
      if(!aprendizajes.trim()){
        setMensaje('Completa los espacios de aprendizaje')
        return
      }
      if(!met.trim()){
        setMensaje('Completa la evaluación según el modelo Met')
        return
      }
      if(!coach.trim()){
        setMensaje('Completa el Coach a cargo')
        return
      }

  
      try {
  
        const nuevaAudiencia = {
          disertante: disertante,
          horario: horario,
          observador: observador,
          titulo: titulo,
          contexto: contexto,
          prepersonal: prepersonal,
          modelocom: modelocom,
          preoral: preoral,
          explorar: explorar,
          ensala: ensala,
          espacio: espacio,
          corporalidad: corporalidad,
          fortalezas: fortalezas,
          animo: animo,
          participacion: participacion,
          contenidos: contenidos,
          tiempo: tiempo,
          recursos: recursos,
          aprendizajes: aprendizajes,
          met: met,
          coach: coach,
          fecha: Date.now()
        }
        const data = await db.collection("audiencias:" + props.user.email).add(nuevaAudiencia)
  
        setAudiencias([
          ...audiencias,
          {...nuevaAudiencia, id: data.id}
        ])
  
        setDisertante('')
        setHorario('')
        setObservador('')
        setTitulo('')
        setContexto('')
        setPrepersonal('')
        setModelocom('')
        setPreoral('')
        setExplorar('')
        setEnsala('')
        setEspacio('')
        setCorporalidad('')
        setFortalezas('')
        setAnimo('')
        setParticipacion('')
        setContenidos('')
        setTiempo('')
        setRecursos('')
        setAprendizajes('')
        setMet('')
        setCoach('')
        setMensaje('La audiencia fue agregada. Ver arriba.')
        
      } catch (error) {
        console.log(error)
      }
  
      console.log(audiencias)
    }
  
    const eliminar = async (id) => {
      try {
        
        await db.collection("audiencias:" + props.user.email).doc(id).delete()
  
        const arrayFiltrado = audiencias.filter(item => item.id !== id)
        setAudiencias(arrayFiltrado)
  
      } catch (error) {
        console.log(error)
      } setMensaje('La audiencia fue eliminada. Ver arriba.')
    }
  
    const activarEdicion = (item) => {
        setModoEdicion(true)
        setDisertante(item.disertante)
        setHorario(item.horario)
        setObservador(item.observador)
        setTitulo(item.titulo)
        setContexto(item.contexto)
        setPrepersonal(item.prepersonal)
        setModelocom(item.modelocom)
        setPreoral(item.preoral)
        setExplorar(item.explorar)
        setEnsala(item.ensala)
        setEspacio(item.espacio)
        setCorporalidad(item.corporalidad)
        setFortalezas(item.fortalezas)
        setAnimo(item.animo)
        setParticipacion(item.participacion)
        setContenidos(item.contenidos)
        setTiempo(item.tiempo)
        setRecursos(item.recursos)
        setAprendizajes(item.aprendizajes)
        setMet(item.met)
        setCoach(item.coach)
        setId(item.id)
        setMensaje('')
    }
  
    const editar = async (e) => {
      e.preventDefault()
      if(!disertante.trim()){
        setMensaje('Completa el disertante')
        return
      }
      if(!horario.trim()){
        setMensaje('Completa la fecha y la hora')
        return
      }
      if(!observador.trim()){
        setMensaje('Completa el observador')
        return
      }
      if(!titulo.trim()){
        setMensaje('Completa el título')
        return
      }
      if(!contexto.trim()){
        setMensaje('Completa los contextos de obviedad')
        return
      }
      if(!prepersonal.trim()){
        setMensaje('Completa la presentación personal')
        return
      }
      if(!modelocom.trim()){
        setMensaje('Completa el modelo de comunicación')
        return
      }
      if(!preoral.trim()){
        setMensaje('Completa la presentación oral')
        return
      }
      if(!explorar.trim()){
        setMensaje('Completa cómo explora la zona de comodidad')
        return
      }
      if(!ensala.trim()){
        setMensaje('Completa cómo se comporta en sala')
        return
      }
      if(!espacio.trim()){
        setMensaje('Completa cómo maneja el espacio')
        return
      }
      if(!corporalidad.trim()){
        setMensaje('Completa la corporalidad')
        return
      }
      if(!fortalezas.trim()){
        setMensaje('Completa las fortalezas observadas')
        return
      }
      if(!animo.trim()){
        setMensaje('Completa el estado de ánimo')
        return
      }
      if(!participacion.trim()){
        setMensaje('Completa el grado de participación')
        return
      }
      if(!contenidos.trim()){
        setMensaje('Completa cómo maneja los contenidos')
        return
      }
      if(!tiempo.trim()){
        setMensaje('Completa el manejo del tiempo')
        return
      }
      if(!recursos.trim()){
        setMensaje('Completa los recursos utilizados')
        return
      }
      if(!aprendizajes.trim()){
        setMensaje('Completa los espacios de aprendizaje')
        return
      }
      if(!met.trim()){
        setMensaje('Completa la evaluación según el modelo Met')
        return
      }
      if(!coach.trim()){
        setMensaje('Completa el Coach a cargo')
        return
      }

      try {
        
        await db.collection("audiencias:" + props.user.email).doc(id).update({
            disertante: disertante,
            horario: horario,
            observador: observador,
            titulo: titulo,
            contexto: contexto,
            prepersonal: prepersonal,
            modelocom: modelocom,
            preoral: preoral,
            explorar: explorar,
            ensala: ensala,
            espacio: espacio,
            corporalidad: corporalidad,
            fortalezas: fortalezas,
            animo: animo,
            participacion: participacion,
            contenidos: contenidos,
            tiempo: tiempo,
            recursos: recursos,
            aprendizajes: aprendizajes,
            met: met,
            coach: coach,
            fecha: Date.now()
        })
        const arrayEditado = audiencias.map(item => (
          item.id === id ? {id: item.id, fecha: item.fecha, disertante: disertante,
            horario: horario, observador: observador, titulo: titulo,
            contexto: contexto, prepersonal: prepersonal, modelocom: modelocom, 
            preoral: preoral, explorar: explorar, ensala: ensala, espacio: espacio,
            corporalidad: corporalidad, fortalezas: fortalezas, animo: animo, 
            participacion: participacion, contenidos: contenidos, tiempo: tiempo, 
            recursos: recursos, aprendizajes: aprendizajes, met: met, coach: coach
          } : item
        ))
        setAudiencias(arrayEditado)
        setModoEdicion(false)
        setDisertante('')
        setHorario('')
        setObservador('')
        setTitulo('')
        setContexto('')
        setPrepersonal('')
        setModelocom('')
        setPreoral('')
        setExplorar('')
        setEnsala('')
        setEspacio('')
        setCorporalidad('')
        setFortalezas('')
        setAnimo('')
        setParticipacion('')
        setContenidos('')
        setTiempo('')
        setRecursos('')
        setAprendizajes('')
        setMet('')
        setCoach('')
        setId('')
        setMensaje('La audiencia fue actualizada. Ver arriba.')
      } catch (error) {
        console.log(error)
      }
    }

    return (
          <>
             <div>
                <br />
                    <h5>Fichas de Audiencia Realizadas</h5>
                    <ul className="lista">
                        {
                        audiencias.map(item => (
                            <li key={item.id}>
                              <a class="btn btn-light" 
                                 data-toggle="collapse" 
                                 href={"#"+item.id} 
                                 role="button" 
                                 aria-expanded="false" 
                                 aria-controls="collapseExample">
                                   Disertante: {item.disertante} - Fecha y Hora: {item.horario}
                              </a>
                              <br /> 
                            <div class="collapse" id={item.id}>
                            <div class="card card-body">
                            Observador: {item.observador} <br />
                            Título: {item.titulo} <br />
                            Contexto de obviedad observados: {item.contexto} <br />
                            Presentación personal: {item.prepersonal} <br />
                            Presentación oral: {item.preoral} <br />
                            Manejo del espacio: {item.espacio} <br />
                            Corporalidad: {item.corporalidad} <br />
                            Estado de ánimo transitado: {item.animo} <br />
                            Grado de participación y/o fluidez: {item.participacion} <br />
                            Modelo de comunicación: {item.modelocom} <br />
                            Manejo de los contenidos: {item.contenidos} <br />
                            Exploración fuera de la zona de comodidad: {item.explorar} <br />
                            Manejo en sala: {item.ensala} <br />
                            Manejo del tiempo: {item.tiempo} <br />
                            Fortalezas detectadas: {item.fortalezas} <br />
                            Espacios de aprendizaje: {item.aprendizajes} <br />
                            Recursos utilizados: {item.recursos} <br />
                            Evaluación según el modelo MET: {item.met} <br />
                            Aval del Coach a Cargo: {item.coach} <br />
                            </div>
                            </div>
                            <button 
                                className="btn btn-danger btn-sm mb-3 ml-2"
                                onClick={() => eliminar(item.id)}
                            >
                                Eliminar
                            </button>
                            <button 
                                className="btn btn-warning btn-sm ml-2 mb-3"
                                onClick={() => activarEdicion(item)}
                            >
                                Editar
                            </button>
                            
                            </li>
                        ))
                        }
                    </ul>
                </div>
                <div>
                    <h5>
                    <a className="btn btn-light" 
                       data-toggle="collapse" 
                       href="#collapseForm" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                          {
                        modoEdicion ? 'Editar Ficha de Audiencia' : 'Completar Ficha de Audiencia'
                        }
                    </a>              
                    </h5>
                    <div className="collapse" id="collapseForm">
                    <div className="card card-body">
                    <form onSubmit={modoEdicion ? editar : agregar}>
                        <input 
                        type="text"
                        className="form-control"
                        placeholder="Nombre y Apellido del Disertante"
                        onChange={e => setDisertante(e.target.value)}
                        value={disertante}
                        />
                        <br />
                        <input 
                        type="text"
                        className="form-control"
                        placeholder="Fecha y Hora de la Audiencia"
                        onChange={e => setHorario(e.target.value)}
                        value={horario}
                        />
                        <br />
                        <input 
                        type="text"
                        className="form-control"
                        placeholder="Nombre y Apellido del observador"
                        onChange={e => setObservador(e.target.value)}
                        value={observador}
                        />
                        <br />
                        <input 
                        type="text"
                        className="form-control"
                        placeholder="Título de la Audiencia"
                        onChange={e => setTitulo(e.target.value)}
                        value={titulo}
                        />
                        <br />
                        <textarea 
                        className="form-control"
                        placeholder="Contextos de obviedad observados"
                        onChange={e => setContexto(e.target.value)}
                        value={contexto}
                        />
                        <br />
                        <label><i>Observe la integración con el rol o consigna a cumplir:</i></label>
                        <textarea 
                        className="form-control"
                        placeholder="Presentación Personal (apropiada, percibir diferencia con el entorno)"
                        onChange={e => setPrepersonal(e.target.value)}
                        value={prepersonal}
                        />
                        <br />
                        <label><i>Observe las siguientes características: <br/>
                                  Tono: empático, monocorde, chirrido.<br/>
                                  Volumen: susurra, conversa, grita.<br/>
                                  Dicción: claridad + tus distinciones de oratoria.<br/>
                                  Lenguaje: burdo, adecuado, distinguido, elocuente, etc.</i></label>
                        <textarea 
                        className="form-control"
                        placeholder="Presentación Oral (tono, brillo, volumen, dicción, lenguaje)"
                        onChange={e => setPreoral(e.target.value)}
                        value={preoral}
                        />
                        <br />
                        <label><i>Observe si encuentra: <br/>
                                  Armonía: ¿percibe ansiedad, inquietud o equilibrio?<br/>
                                  Presencia: ¿cómo ocupa el espacio?<br/>
                                  Movimientos: ¿acompaña al mensaje con la cabeza, los pies y los brazos?</i></label>
                        <textarea 
                        className="form-control"
                        placeholder="Manejo del espacio (armonía, presencia, movimientos)"
                        onChange={e => setEspacio(e.target.value)}
                        value={espacio}
                        />
                        <br />
                        <label><i>Oberserve la integración con el personaje o rol. 
                                  Si hay defecto percibido o tic en transparencia. 
                                  Si su posició es en exceso, medio o centrada.</i></label>
                        <textarea 
                        className="form-control"
                        placeholder="Corporalidad (centramiento, apertura, flexibilidad, resolución, estabilidad)"
                        onChange={e => setCorporalidad(e.target.value)}
                        value={corporalidad}
                        />
                        <br />
                        <label><i>Paz de Espíritu, actitud de propósito, juegos, acción, jubilo, 
                                  estética, entusiasmo, alegría, interés intenso, conservador, moderado, 
                                  conforme, desinterés, aburrimiento, hostilidad, antagonismo, dolor, 
                                  enojo, odio, resentimiento, sin compasión, ansiedad, miedo, terror, 
                                  insensibilidad, compasión, complaciente, víctima, desesperanza, apatía.</i></label>
                        <textarea 
                        className="form-control"
                        placeholder="Estado de ánimo transitado (Escriba 3 corridas. Si no se encuentra en la lista citarlo.)"
                        onChange={e => setAnimo(e.target.value)}
                        value={animo}
                        />
                        <br />
                        <input 
                        type="text"
                        className="form-control"
                        placeholder="Grado de Participación y/o fluidez. Defina con una palabra."
                        onChange={e => setParticipacion(e.target.value)}
                        value={participacion}
                        />
                        <br />
                        <div>
                            <br/>
                            <p>Modelo de Comunicación:</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Agresivo" 
                                        checked={modelocom === 'Agresivo'} 
                                        onChange={e => setModelocom(e.target.value)}
                                    />
                                    Agresivo
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Indiferente" 
                                        checked={modelocom === 'Indiferente'} 
                                        onChange={e => setModelocom(e.target.value)}
                                    />
                                    Indiferente
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Pasivo" 
                                        checked={modelocom === 'Pasivo'} 
                                        onChange={e => setModelocom(e.target.value)}
                                    />
                                    Pasivo
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Emotivo" 
                                        checked={modelocom === 'Emotivo'} 
                                        onChange={e => setModelocom(e.target.value)}
                                    />
                                    Emotivo
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Desde la certeza" 
                                        checked={modelocom === 'Desde la certeza'} 
                                        onChange={e => setModelocom(e.target.value)}
                                    />
                                    Desde la certeza
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Asertivo" 
                                        checked={modelocom === 'Asertivo'} 
                                        onChange={e => setModelocom(e.target.value)}
                                    />
                                    Asertivo
                                </p>
                            </div> 
                        </div>   
                        </div>
                        <br/>
                        <div>
                            <p>Manejo de los contenidos:</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Impecable" 
                                        checked={contenidos === 'Impecable'} 
                                        onChange={e => setContenidos(e.target.value)}
                                    />
                                    Impecable
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Muy Claro" 
                                        checked={contenidos === 'Muy Claro'} 
                                        onChange={e => setContenidos(e.target.value)}
                                    />
                                    Muy Claro
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Claro" 
                                        checked={contenidos === 'Claro'} 
                                        onChange={e => setContenidos(e.target.value)}
                                    />
                                    Claro
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Poco Claro" 
                                        checked={contenidos === 'Poco Claro'} 
                                        onChange={e => setContenidos(e.target.value)}
                                    />
                                    Poco Claro
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Nada Claro" 
                                        checked={contenidos === 'Nada Claro'} 
                                        onChange={e => setContenidos(e.target.value)}
                                    />
                                    Nada Claro
                                </p>
                            </div>
                        </div>
                    </div>                           
                        <br />
                        <div>
                            Explorar fuera de la zona de Comodidad:<br/>
                            <label><i>Señale una graduación:</i><br/>
                            conservador (4 a 1) | innovador (1 a 4)</label><br/>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Conservador Cuatro" 
                                        checked={explorar === 'Conservador Cuatro'} 
                                        onChange={e => setExplorar(e.target.value)}
                                    />
                                    4
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Conservador Tres" 
                                        checked={explorar === 'Conservador Tres'} 
                                        onChange={e => setExplorar(e.target.value)}
                                    />
                                    3
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Conservador Dos" 
                                        checked={explorar === 'Conservador Dos'} 
                                        onChange={e => setExplorar(e.target.value)}
                                    />
                                    2
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Conservador Uno" 
                                        checked={explorar === 'Conservador Uno'} 
                                        onChange={e => setExplorar(e.target.value)}
                                    />
                                    1
                                </p>
                            </div>
                            <div><label> | </label></div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Innovador Uno" 
                                        checked={explorar === 'Innovador Uno'} 
                                        onChange={e => setExplorar(e.target.value)}
                                    />
                                    1
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Innovador Dos" 
                                        checked={explorar === 'Innovador Dos'} 
                                        onChange={e => setExplorar(e.target.value)}
                                    />
                                    2
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Innovador Tres" 
                                        checked={explorar === 'Innovador Tres'} 
                                        onChange={e => setExplorar(e.target.value)}
                                    />
                                    3
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Innovador Cuatro" 
                                        checked={explorar === 'Innovador Cuatro'} 
                                        onChange={e => setExplorar(e.target.value)}
                                    />
                                    4
                                </p>
                            </div>
                        </div>
                    </div>    
                    <br/>
                            <div>
                            En Sala (poder/control - pasión/unibación):<br/>
                            <label><i>Señale una graduación:</i><br/>
                            desorden-caos (-4 a -2 ) | equilibrio-poder (-1 a 1) | órden-control (2 a 4)</label><br/>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Desorden-Caos Cuatro" 
                                        checked={ensala === 'Desorden-Caos Cuatro'} 
                                        onChange={e => setEnsala(e.target.value)}
                                    />
                                    -4
                                </p>
                            </div>
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Desorden-Caos Tres" 
                                        checked={ensala === 'Desorden-Caos Tres'} 
                                        onChange={e => setEnsala(e.target.value)}
                                    />
                                    -3
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Desorden-Caos Dos" 
                                        checked={ensala === 'Desorden-Caos Dos'} 
                                        onChange={e => setEnsala(e.target.value)}
                                    />
                                    -2
                                </p>
                            </div>
                            <div><label> | </label></div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Equilibrio-Poder Uno" 
                                        checked={ensala === 'Equilibrio-Poder Uno'} 
                                        onChange={e => setEnsala(e.target.value)}
                                    />
                                    -1
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Equilibrio-Poder Cero" 
                                        checked={ensala === 'Equilibrio-Poder Cero'} 
                                        onChange={e => setEnsala(e.target.value)}
                                    />
                                    0
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Equilibrio-Poder Uno" 
                                        checked={ensala === 'Equilibrio-Poder Uno'} 
                                        onChange={e => setEnsala(e.target.value)}
                                    />
                                    1
                                </p>
                            </div>
                            <div><label> | </label></div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Orden-Control Dos" 
                                        checked={ensala === 'Orden-Control Dos'} 
                                        onChange={e => setEnsala(e.target.value)}
                                    />
                                    2
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Orden-Control Tres" 
                                        checked={ensala === 'Orden-Control Tres'} 
                                        onChange={e => setEnsala(e.target.value)}
                                    />
                                    3
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Orden-Control Cuatro" 
                                        checked={ensala === 'Orden-Control Cuatro'} 
                                        onChange={e => setEnsala(e.target.value)}
                                    />
                                    4
                                </p>
                            </div>
                          </div>
                          </div>
                          <div>
                              <br/>
                            Manejo del Tiempo<br/>
                            <label><i>Marque una opción:</i></label><br/>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Acorde" 
                                        checked={tiempo === 'Acorde'} 
                                        onChange={e => setTiempo(e.target.value)}
                                    />
                                    Acorde al compromiso pautado
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Menor" 
                                        checked={tiempo === 'Menor'} 
                                        onChange={e => setTiempo(e.target.value)}
                                    />
                                    Tomó menos del tiempo asignado
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Excedio por Pedido" 
                                        checked={tiempo === 'Excedio por Pedido'} 
                                        onChange={e => setTiempo(e.target.value)}
                                    />
                                    Se excedió en tiempo, por pedido
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Tomo el tiempo" 
                                        checked={tiempo === 'Tomo el tiempo'} 
                                        onChange={e => setTiempo(e.target.value)}
                                    />
                                    Tomó el tiempo de la audiencia 
                                </p>
                            </div>
                          </div>
                        </div>
                        <br/> 
                        <label><i>Describa de 3 a 5 fortalezas observadas en lo posible 
                                  sin repetir conceptos ya evaluados. Seniors 10.</i></label>
                        <textarea 
                        className="form-control"
                        placeholder="Fortalezas observadas como logros"
                        onChange={e => setFortalezas(e.target.value)}
                        value={fortalezas}
                        />
                        <br /> 
                        <label><i>Describa al menos 5 espacios de aprendizaje observados en 
                                  lo posible sin repetir conceptos ya evaluados. Seniors 10.</i></label>
                        <textarea 
                        className="form-control"
                        placeholder="Espacios de Aprendizaje"
                        onChange={e => setAprendizajes(e.target.value)}
                        value={aprendizajes}
                        />
                        <br />
                        <label><i>Imágenes - Powerpoint - Cuadros sinópticos o Mapas Conceptuales
                                  - Frases, cuentos - Testimonios - Música - Trabajos c/cuerpo - 
                                  c/emociones - Juegos de simulación - Experiencias personales - 
                                  Otros.</i></label>
                        <textarea 
                        className="form-control"
                        placeholder="Recursos urilizados (marque todos los que correspondan)"
                        onChange={e => setRecursos(e.target.value)}
                        value={recursos}
                        />
                        <br />
                        <label><i>Instancia MET que considera que el disertante alcanzó.</i></label>
                        <textarea 
                        className="form-control"
                        placeholder="Evaluacion según el modelo MET"
                        onChange={e => setMet(e.target.value)}
                        value={met}
                        />
                        <br />
                        <input 
                        type="text"
                        className="form-control"
                        placeholder="Aval del Coach a cargo del entrenamiento."
                        onChange={e => setCoach(e.target.value)}
                        value={coach}
                        />
                        <br /> 
                        {
                        mensaje && (
                            <div className="alert alert-info">
                                {mensaje}
                            </div>
                        )
                        }
                        <br/>
                        <button 
                        className={
                            modoEdicion ? 'btn btn-warning btn-block custom mt-2' : 'btn btn-dark btn-block custom mt-2'
                        }
                        type="submit"
                        >
                        {
                            modoEdicion ? 'Editar' : 'Agregar'
                        }
                        </button>
                        <br />
                        <br />
                    </form>
                </div>
              </div>
            </div>
      </>
    )
}

export default Audiencia