import React from 'react'
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import '../index.css';
import {withRouter} from 'react-router-dom'
import { Link } from 'react-router-dom';

const Inicio = () => {

  const slides = [
    { title: 'Mi Salud', 
      description: 'Tests, Manual de Salud Integrativa, Biodescodificación de los Síntomas.',
      image: 'https://app.sonria.tv/img/nivel0.png',
      link: '/misalud'  
    },
    { title: 'Mis Compromisos', 
      description: 'Aceptar y Reconocer. Mi Plan de Competencias Básicas. Test Eneagrama.',
      image: 'https://app.sonria.tv/img/nivel1.png',
      link: '/miscompromisos'  
    },
    { title: 'Mis Emociones', 
      description: 'Test de Inteligencia emocional. Mis Relaciones 4D. Escucha y Presencia.',
      image: 'https://app.sonria.tv/img/nivel2.png',
      link: '/misemociones'   
    },
    { title: 'Mi Trabajo', 
      description: 'Confección del Plan de Metas, Objetivos y autodefinición de tus KPI´s.',
      image: 'https://app.sonria.tv/img/nivel3.png',  
      link: '/metas'  
    },
    { title: 'La Carrera', 
      description: 'Mis Competencias Genéricas, Mis Clases OnLine y mis Históricos de Evaluación.',
      image: 'https://app.sonria.tv/img/nivel4.png',
      link: '/clases'  
    },
    { title: 'El Liderazgo', 
      description: 'Mis competencias Específicas, Mi perfil de Liderazgo y de Gestión Efectiva.',
      image: 'https://app.sonria.tv/img/nivel5.png' 
    },
    { title: '♥️ de Servicio', 
      description: 'Plan de RSO, Protocolos Iso 9001, Procesos de Facilitación Generados.',
      image: 'https://app.sonria.tv/img/nivel6.png'
    },
    { title: 'El Legado', 
      description: 'Plan de Innovación y Liderazgo, Construcción del Legado Social Organizacional.',
      image: 'https://app.sonria.tv/img/nivel7.png'
    },
  ];

  return (
    <main>
    <div className="contenedor-smiles">
          <Slider autoplay={2000}>
      {slides.map((item, index) => (
        <div key={index}>
          <div className="center">
            <Link to={item.link}>
              <img src={item.image} />
            </Link>
            <br /><br />
            <h3 className="tsmile">{item.title}</h3>
            <div className="contenedor-slider">
              {item.description}
            </div>
            <br/>
            <Link className="btn btn-dark mr-2" to={item.link}>
                explorar
            </Link>
          </div>
        </div>
      ))}
    </Slider>
    <br/><br/>
    <h3>¿Qué eliges gestionar hoy?</h3>
    </div>
    </main>
  )
}

export default withRouter(Inicio)
