import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import '../index.css';
import { Link } from 'react-router-dom';

const MisCompromisos = () => {

    return (
        <main>
        <div className="contenedor">
            <h3>Test del Eneagrama</h3>
            <p>El eneagrama es un método de clasificación de la personalidad utilizado generalmente 
                para el autoconocimiento y el desarrollo personal. Presenta nueve tipos de 
                personalidades principales. Se trata de una técnica milenaria pero interpretada, 
                desde el punto de vista de la psicología, por múltiples autores recientes.</p>

                <p>Para concer más sobre los Eneatipos puedes ingresar en estos links:<br/>
                <a href="https://sonria.com/glossary/eneagrama/" target="_blank">
                    ¿Qué es el Eneagrama?
                </a>   
                </p>
                <p>
                <a href="https://sonria.com/los-eneatipos-y-sus-alas/" target="_blank">
                    Los Eneatipos y sus Alas
                </a>   
                </p>
                <p>
                <a href="https://sonria.com/los-eneatipos-y-sus-flechas/" target="_blank">
                    Los Eneatipos y sus Flechas
                </a>   
                </p>
                <p>
                <a href="https://sonria.com/los-subtipos-en-el-eneagrama/" target="_blank">
                    Los Subtipos en el Eneagrama. Clasificación en 27 Personalidades.
                </a>   
                </p>

                <h5>¿Estás listo para descubrir tu eneatipo?</h5>
                <Link className="btn btn-dark mr-2" to="/mitesteneagrama">
                    Realizar Test de Eneagrama
                </Link>
                <p></p>
                <br/><br/>
            
        </div>
        </main>
    )
}

export default withRouter(MisCompromisos)