import React from 'react'
import {withRouter} from 'react-router-dom'
import '../index.css';
import { Link } from 'react-router-dom';

const Intensivos = () => {
    return (
        <main>
        <div className="contenedor">
            <h3>Entrenamientos Intensivos</h3>
            <br/>
            <div className="centrado-grid">
                <div>
                    <Link to="/cree">
                        <img src="https://app.sonria.tv/img/intensivo01.jpg" width="160px"></img>
                    </Link>
                </div>
                <div>
                
                    <img src="https://app.sonria.tv/img/intensivo02.jpg" width="160px"></img>
                
                </div>
                <div>
                
                    <img src="https://app.sonria.tv/img/intensivo03.jpg" width="160px"></img>
                
                </div>
                <div>
                
                    <img src="https://app.sonria.tv/img/intensivo04.jpg" width="160px"></img>
                
                </div>
                <div>
                
                    <img src="https://app.sonria.tv/img/intensivo05.jpg" width="160px"></img>
                
                </div>
                <div>
                
                    <img src="https://app.sonria.tv/img/intensivo06.jpg" width="160px"></img>
                
                </div>
                <div>
                
                    <img src="https://app.sonria.tv/img/intensivo07.jpg" width="160px"></img>
                
                </div>
                <div>
                
                    <img src="https://app.sonria.tv/img/intensivo08.jpg" width="160px"></img>
                
                </div>
            </div>

        </div>
        </main>
    )
}

export default Intensivos
