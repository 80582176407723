import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const Apatico = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])

    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <img src="https://app.sonria.tv/img/apatia.jpg" width="70px"></img><br/>
                <h4>Apatía</h4>
                <b>¿Cuál es el Sentido de la Emoción que se nos dispara?</b><br/>
                Vivimos en una resignación causada por un peligro real o imaginario que 
                deviene del Miedo y la Tristeza.
                    <br/><br/>
    
                <p className="peque"><i><b>Raíz de la palabra: </b>
                A = Negación. Pathos (EMoción, Sentimiento, Enfermedad). Indiferencia de 
                Ánimo.</i></p>
                <br/>
                <NavLink className="btn btn-info" href="/">Regresar</NavLink>
                </>
                )
            }
        </div>
        </main>
    )
}

export default withRouter(Apatico)