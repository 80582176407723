import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const Enojado = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])

    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <img src="https://app.sonria.tv/img/enfado.jpg" width="70px"></img><br/>
                <h4>Enfado</h4>
                <b>¿Cuál es el Sentido de la Emoción que se nos dispara?</b><br/>
                Los sentimientos de enojo, de fastidio, molestia, furia, de hostilidad y 
                de odio son el canal a través del cual expresamos que no estamos bien con 
                esa circunstancia. Naturalmente corresponden a un estado de reacción como 
                si el otro fuera responsable de lo que me está sucediendo.
                    <br/><br/>
    
                <p className="peque"><i><b>Raíz de la palabra: </b>
                Del Romance Galaico Portugués. En (in-hacia el interior). Fatum (destino 
                Predicción). Entregarse a la fatalidad por aburrimiento vital, cansarse, 
                enojarse, desalentarse. Del Latín Fatum (que nos ha dado).</i></p>
                <br/>
                <NavLink className="btn btn-info" href="/">Regresar</NavLink>
                </>
                )
            }
        </div>
        </main>
    )
}

export default withRouter(Enojado)