import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Navbary from './components/Navbary';
import Login from './components/Login'
import Perfil from './components/Perfil'
import Inicio from './components/Inicio'
import Clases from './components/Clases'
import Datos from './components/Datos'
import Metas from './components/Metas'
import Reset from './components/Reset'
import MisEmociones from './components/MisEmociones'
import MiSalud from './components/MiSalud'
import MisCompromisos from './components/MisCompromisos'
import Amoroso from './components/Amoroso'
import Agradecido from './components/Agradecido'
import Alegre from './components/Alegre'
import Comprensivo from './components/Comprensivo'
import Entusiasmado from './components/Entusiasmado'
import Deseoso from './components/Deseoso'
import Sorprendido from './components/Sorprendido'
import Esperanzado from './components/Esperanzado'
import Disgustado from './components/Disgustado'
import Enojado from './components/Enojado'
import Temeroso from './components/Temeroso'
import Triste from './components/Triste'
import Apatico from './components/Apatico'
import MiTestSalud from './components/MiTestSalud'
import MiTestEfectividad from './components/MiTestEfectividad'
import MiTestCreencias from './components/MiTestCreencias'
import MiTestEneagrama from './components/MiTestEneagrama'
import IndicadorSalud from './components/IndicadorSalud'
import Intensivos from './components/Intensivos'
import Cree from './components/Cree'
import {auth} from './firebase'

const App = () => {

  const [firebaseUser, setFirebaseUser] = React.useState(false)

  React.useEffect(() => {
    auth.onAuthStateChanged(user => {
      console.log(user)
      if (user){
        setFirebaseUser(user)
      } else {
        setFirebaseUser(null)
      }
    })
  }, [])
  
  return firebaseUser !== false ? (
    <Router>
    <div className="main">
    <Navbary firebaseUser={firebaseUser} />
      <Switch>
      <Route exact path="/">
          <Inicio />
        </Route>
        <Route exact path="/login">
          <Login />
          </Route>
        <Route exact path="/reset">
          <Reset />
        </Route>
        <Route exact path="/perfil">
          <Perfil />
        </Route>
        <Route exact path="/metas">
          <Metas />
        </Route>
        <Route exact path="/datos">
          <Datos />
        </Route>
        <Route exact path="/clases">
          <Clases />
        </Route>
        <Route exact path="/misemociones">
          <MisEmociones />
        </Route>
        <Route exact path="/misalud">
          <MiSalud />
        </Route>
        <Route exact path="/miscompromisos">
          <MisCompromisos />
        </Route>
        <Route exact path="/amoroso">
          <Amoroso />
        </Route>
        <Route exact path="/agradecido">
          <Agradecido />
        </Route>
        <Route exact path="/alegre">
          <Alegre />
        </Route>
        <Route exact path="/comprensivo">
          <Comprensivo />
        </Route>
        <Route exact path="/entusiasmado">
          <Entusiasmado />
        </Route>
        <Route exact path="/deseoso">
          <Deseoso />
        </Route>
        <Route exact path="/sorprendido">
          <Sorprendido />
        </Route>
        <Route exact path="/esperanzado">
          <Esperanzado />
        </Route>
        <Route exact path="/disgustado">
          <Disgustado />
        </Route>
        <Route exact path="/enojado">
          <Enojado />
        </Route>
        <Route exact path="/temeroso">
          <Temeroso />
        </Route>
        <Route exact path="/triste">
          <Triste />
        </Route>
        <Route exact path="/apatico">
          <Apatico />
        </Route>
        <Route exact path="/indicador">
          <IndicadorSalud />
        </Route>
        <Route exact path="/mitestsalud">
          <MiTestSalud />
        </Route>
        <Route exact path="/mitestefectividad">
          <MiTestEfectividad />
        </Route>
        <Route exact path="/mitestcreencias">
          <MiTestCreencias />
        </Route>
        <Route exact path="/mitesteneagrama">
          <MiTestEneagrama />
        </Route>
        <Route exact path="/intensivos">
          <Intensivos />
        </Route>
        <Route exact path="/cree">
          <Cree />
        </Route>
        <Route path="*" component={Error404} />
      </Switch>
    </div>
    </Router>
  ) : (
    <Router>
    <div className="main">
    <Navbary firebaseUser={firebaseUser} />
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
      </Switch>
    </div>
    </Router>
  )
}

const Error404 = () => {
  return (
    <section>
      <h4>Página no encontrada. Vuelva al inicio por favor.</h4>        
    </section>
  );
}

export default App;
