import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import TestEfectividad from './TestEfectividad'
import ListaDatos from './ListaDatos'
import '../index.css';

const MiTestEfectividad = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])


    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <h3>Mi Test de Efectividad: 
                <ListaDatos user={user}/></h3> 
                <TestEfectividad user={user}/>
                </>
               )
            } 
        </div>
        </main>
    )
}

export default withRouter(MiTestEfectividad)