import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const Sorprendido = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])

    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <img src="https://app.sonria.tv/img/sorpresa.jpg" width="70px"></img><br/>
                <h4>Sorpresa</h4>
                <b>¿Cuál es el Sentido de la Emoción que se nos dispara?</b><br/>
                Alteración emocional que causa una cosa no prevista o esperada. 
                Asombro, desconcierto, sobresalto. Sensaciones trasitorias.
                    <br/><br/>
    
                <p className="peque"><i><b>Raíz de la palabra: </b>
                Del francés Surprende: compuesto por el prefijo Sur (Sobre) y el 
                vocablo Prender (Tomar, Coger). Impresión producida por algo imprevisto. 
                Revelar una cosa que se calla o encubre. Sentir una perturbación emocional. 
                Descubrir lo que otro escondía o enmascaraba.</i></p>
                <br/>
                <NavLink className="btn btn-info" href="/">Regresar</NavLink>
                </>
                )
            }
        </div>
        </main>
    )
}

export default withRouter(Sorprendido)