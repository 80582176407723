import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const Amoroso = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])

    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <img src="https://app.sonria.tv/img/amor-humanidad.jpg" width="70px"></img><br/>
                <h4>Amor a la Humanidad</h4>
                <b>¿Cuál es el Sentido de la Emoción que se nos dispara?</b><br/>
                    Etapa signada por un propósito de bien común, que trasciende 
                    al interés de una persona u organización. Hay paz, 
                    agradecimiento en el corazón y el propósito de "ser puente" 
                    para otros.
                    <br/><br/>
                <iframe width="100%" 
                        src="https://www.youtube.com/embed/gENZO0xp1Xw" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                </iframe>
                <br/><br/>
                <p className="peque"><i><b>Raíz de la palabra: </b>
                Del griego philos (amor)y anthropos (ser humano). Del latín 
                amórem (amos). Amor, Agape, que se concentra en la declaración y
                la acción de dar sin esperar nada a cambio. Por eso es posible 
                amar al enemigo.</i></p>
                <br/>
                <NavLink className="btn btn-info" href="/">Regresar</NavLink>
                <br/>
                </>
                )
            }
        </div>
        </main>
    )
}

export default withRouter(Amoroso)