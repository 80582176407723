import React from 'react'
import {db} from '../firebase'
import Moment from 'react-moment';
import 'moment/locale/es'
import '../index.css';

const TestEneagrama = (props) => {

    const [tests, setTests] = React.useState([])
    const [p001, setP001] = React.useState('')
    const [p002, setP002] = React.useState('')
    const [p003, setP003] = React.useState('')
    const [p004, setP004] = React.useState('')
    const [p005, setP005] = React.useState('')
    const [p006, setP006] = React.useState('')
    const [p007, setP007] = React.useState('')
    const [p008, setP008] = React.useState('')
    const [p009, setP009] = React.useState('')
    const [p010, setP010] = React.useState('')
    const [p011, setP011] = React.useState('')
    const [p012, setP012] = React.useState('')
    const [p013, setP013] = React.useState('')
    const [p014, setP014] = React.useState('')
    const [p015, setP015] = React.useState('')
    const [p016, setP016] = React.useState('')
    const [p017, setP017] = React.useState('')
    const [p018, setP018] = React.useState('')
    const [p019, setP019] = React.useState('')
    const [p020, setP020] = React.useState('')
    const [p021, setP021] = React.useState('')
    const [p022, setP022] = React.useState('')
    const [p023, setP023] = React.useState('')
    const [p024, setP024] = React.useState('')
    const [p025, setP025] = React.useState('')
    const [p026, setP026] = React.useState('')
    const [p027, setP027] = React.useState('')
    const [p028, setP028] = React.useState('')
    const [p029, setP029] = React.useState('')
    const [p030, setP030] = React.useState('')
    const [p031, setP031] = React.useState('')
    const [p032, setP032] = React.useState('')
    const [p033, setP033] = React.useState('')
    const [p034, setP034] = React.useState('')
    const [p035, setP035] = React.useState('')
    const [p036, setP036] = React.useState('')
    const [p037, setP037] = React.useState('')
    const [p038, setP038] = React.useState('')
    const [p039, setP039] = React.useState('')
    const [p040, setP040] = React.useState('')
    const [p041, setP041] = React.useState('')
    const [p042, setP042] = React.useState('')
    const [p043, setP043] = React.useState('')
    const [p044, setP044] = React.useState('')
    const [p045, setP045] = React.useState('')
    const [p046, setP046] = React.useState('')
    const [p047, setP047] = React.useState('')
    const [p048, setP048] = React.useState('')
    const [p049, setP049] = React.useState('')
    const [p050, setP050] = React.useState('')
    const [p051, setP051] = React.useState('')
    const [p052, setP052] = React.useState('')
    const [p053, setP053] = React.useState('')
    const [p054, setP054] = React.useState('')
    const [p055, setP055] = React.useState('')
    const [p056, setP056] = React.useState('')
    const [p057, setP057] = React.useState('')
    const [p058, setP058] = React.useState('')
    const [p059, setP059] = React.useState('')
    const [p060, setP060] = React.useState('')
    const [p061, setP061] = React.useState('')
    const [p062, setP062] = React.useState('')
    const [p063, setP063] = React.useState('')
    const [p064, setP064] = React.useState('')
    const [p065, setP065] = React.useState('')
    const [p066, setP066] = React.useState('')
    const [p067, setP067] = React.useState('')
    const [p068, setP068] = React.useState('')
    const [p069, setP069] = React.useState('')
    const [p070, setP070] = React.useState('')
    const [p071, setP071] = React.useState('')
    const [p072, setP072] = React.useState('')
    const [p073, setP073] = React.useState('')
    const [p074, setP074] = React.useState('')
    const [p075, setP075] = React.useState('')
    const [p076, setP076] = React.useState('')
    const [p077, setP077] = React.useState('')
    const [p078, setP078] = React.useState('')
    const [p079, setP079] = React.useState('')
    const [p080, setP080] = React.useState('')
    const [p081, setP081] = React.useState('')
    const [p082, setP082] = React.useState('')
    const [p083, setP083] = React.useState('')
    const [p084, setP084] = React.useState('')
    const [p085, setP085] = React.useState('')
    const [p086, setP086] = React.useState('')
    const [p087, setP087] = React.useState('')
    const [p088, setP088] = React.useState('')
    const [p089, setP089] = React.useState('')
    const [p090, setP090] = React.useState('')
    const [p091, setP091] = React.useState('')
    const [p092, setP092] = React.useState('')
    const [p093, setP093] = React.useState('')
    const [p094, setP094] = React.useState('')
    const [p095, setP095] = React.useState('')
    const [p096, setP096] = React.useState('')
    const [p097, setP097] = React.useState('')
    const [p098, setP098] = React.useState('')
    const [p099, setP099] = React.useState('')
    const [p100, setP100] = React.useState('')
    const [p101, setP101] = React.useState('')
    const [p102, setP102] = React.useState('')
    const [p103, setP103] = React.useState('')
    const [p104, setP104] = React.useState('')
    const [p105, setP105] = React.useState('')
    const [p106, setP106] = React.useState('')
    const [p107, setP107] = React.useState('')
    const [p108, setP108] = React.useState('')
    const [p109, setP109] = React.useState('')
    const [p110, setP110] = React.useState('')
    const [p111, setP111] = React.useState('')
    const [p112, setP112] = React.useState('')
    const [p113, setP113] = React.useState('')
    const [p114, setP114] = React.useState('')
    const [p115, setP115] = React.useState('')
    const [p116, setP116] = React.useState('')
    const [p117, setP117] = React.useState('')
    const [p118, setP118] = React.useState('')
    const [p119, setP119] = React.useState('')
    const [p120, setP120] = React.useState('')
    const [p121, setP121] = React.useState('')
    const [p122, setP122] = React.useState('')
    const [p123, setP123] = React.useState('')
    const [p124, setP124] = React.useState('')
    const [p125, setP125] = React.useState('')
    const [p126, setP126] = React.useState('')
    const [p127, setP127] = React.useState('')
    const [p128, setP128] = React.useState('')
    const [p129, setP129] = React.useState('')
    const [p130, setP130] = React.useState('')
    const [p131, setP131] = React.useState('')
    const [p132, setP132] = React.useState('')
    const [p133, setP133] = React.useState('')
    const [p134, setP134] = React.useState('')
    const [p135, setP135] = React.useState('')
    const [p136, setP136] = React.useState('')
    const [p137, setP137] = React.useState('')
    const [p138, setP138] = React.useState('')
    const [p139, setP139] = React.useState('')
    const [p140, setP140] = React.useState('')
    const [p141, setP141] = React.useState('')
    const [p142, setP142] = React.useState('')
    const [p143, setP143] = React.useState('')
    const [p144, setP144] = React.useState('')
    const [mensaje, setMensaje] = React.useState('')
    const [eneatipouno, setEneatipoUno] = React.useState(0)
    const [eneatipodos, setEneatipoDos] = React.useState(0)
    const [eneatipotres, setEneatipoTres] = React.useState(0)
    const [eneatipocuatro, setEneatipoCuatro] = React.useState(0)
    const [eneatipocinco, setEneatipoCinco] = React.useState(0)
    const [eneatiposeis, setEneatipoSeis] = React.useState(0)
    const [eneatiposiete, setEneatipoSiete] = React.useState(0)
    const [eneatipoocho, setEneatipoOcho] = React.useState(0)
    const [eneatiponueve, setEneatipoNueve] = React.useState(0)
    const [bloqueuno, setBloqueUno] = React.useState('')
    const [bloquedos, setBloqueDos] = React.useState('')
    const [bloquetres, setBloqueTres] = React.useState('')
    const [bloquecuatro, setBloqueCuatro] = React.useState('')
    const [bloquecinco, setBloqueCinco] = React.useState('')
    const [bloqueseis, setBloqueSeis] = React.useState('')
    const [bloquesiete, setBloqueSiete] = React.useState('')
    const [bloqueocho, setBloqueOcho] = React.useState('')
    const [bloquenueve, setBloqueNueve] = React.useState('')
    
  
    React.useEffect(() => {
  
      const obtenerDatos = async () => {
  
        try {
  
          const data = await db.collection("testEneatipo:" + props.user.email).get()
          const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          console.log(arrayData)
          setTests(arrayData)
          
        } catch (error) {
          console.log(error)
          console.log("Eneatipo Uno: " + eneatipouno)
          console.log("Eneatipo Dos: " + eneatipodos)
          console.log("Eneatipo Tres: " + eneatipotres)
          console.log("Eneatipo Cuatro: " + eneatipocuatro)
          console.log("Eneatipo Cinco: " + eneatipocinco)
          console.log("Eneatipo Seis: " + eneatiposeis)
          console.log("Eneatipo Siete: " + eneatiposiete)
          console.log("Eneatipo Ocho: " + eneatipoocho)
          console.log("Eneatipo Nueve: " + eneatiponueve)
          
        }
  
      }
  
      obtenerDatos()
      setMensaje('')
      
  
    }, [props.user.email])
  
    const agregar = async (e) => {
      e.preventDefault()

      if(!p001.trim()){
        setMensaje('Por favor completa la pregunta 01.')
        return
      }      
      
      if(!p002.trim()){
        setMensaje('Por favor completa la pregunta 02.')
        return
      }
      if(!p003.trim()){
        setMensaje('Por favor completa la pregunta 03.')
        return
      }
      if(!p004.trim()){
        setMensaje('Por favor completa la pregunta 04.')
        return
      }
      if(!p005.trim()){
        setMensaje('Por favor completa la pregunta 05.')
        return
      }
      if(!p006.trim()){
        setMensaje('Por favor completa la pregunta 06.')
        return
      }
      if(!p007.trim()){
        setMensaje('Por favor completa la pregunta 07.')
        return
      }
      if(!p008.trim()){
        setMensaje('Por favor completa la pregunta 08.')
        return
      }
      if(!p009.trim()){
        setMensaje('Por favor completa la pregunta 09.')
        return
      }
      if(!p010.trim()){
        setMensaje('Por favor completa la pregunta 10.')
        return
      }
      if(!p011.trim()){
        setMensaje('Por favor completa la pregunta 11.')
        return
      }
      if(!p012.trim()){
        setMensaje('Por favor completa la pregunta 12.')
        return
      }
      if(!p013.trim()){
        setMensaje('Por favor completa la pregunta 13.')
        return
      }
      if(!p014.trim()){
        setMensaje('Por favor completa la pregunta 14.')
        return
      }
      if(!p015.trim()){
        setMensaje('Por favor completa la pregunta 15.')
        return
      }
      if(!p016.trim()){
        setMensaje('Por favor completa la pregunta 16.')
        return
      }
      if(!p017.trim()){
        setMensaje('Por favor completa la pregunta 17.')
        return
      }
      if(!p018.trim()){
        setMensaje('Por favor completa la pregunta 18.')
        return
      }
      if(!p019.trim()){
        setMensaje('Por favor completa la pregunta 19.')
        return
      }
      if(!p020.trim()){
        setMensaje('Por favor completa la pregunta 20.')
        return
      }
      if(!p021.trim()){
        setMensaje('Por favor completa la pregunta 21.')
        return
      }
      if(!p022.trim()){
        setMensaje('Por favor completa la pregunta 22.')
        return
      }
      if(!p023.trim()){
        setMensaje('Por favor completa la pregunta 23.')
        return
      }
      if(!p024.trim()){
        setMensaje('Por favor completa la pregunta 24.')
        return
      }
      if(!p025.trim()){
        setMensaje('Por favor completa la pregunta 25.')
        return
      }
      if(!p026.trim()){
        setMensaje('Por favor completa la pregunta 26.')
        return
      }
      if(!p027.trim()){
        setMensaje('Por favor completa la pregunta 27.')
        return
      }
      if(!p028.trim()){
        setMensaje('Por favor completa la pregunta 28.')
        return
      }
      if(!p029.trim()){
        setMensaje('Por favor completa la pregunta 29.')
        return
      }
      if(!p030.trim()){
        setMensaje('Por favor completa la pregunta 30.')
        return
      }
      if(!p031.trim()){
        setMensaje('Por favor completa la pregunta 31.')
        return
      }
      if(!p032.trim()){
        setMensaje('Por favor completa la pregunta 32.')
        return
      }
      if(!p033.trim()){
        setMensaje('Por favor completa la pregunta 33.')
        return
      }
      if(!p034.trim()){
        setMensaje('Por favor completa la pregunta 34.')
        return
      }
      if(!p035.trim()){
        setMensaje('Por favor completa la pregunta 35.')
        return
      }
      if(!p036.trim()){
        setMensaje('Por favor completa la pregunta 36.')
        return
      }
      if(!p037.trim()){
        setMensaje('Por favor completa la pregunta 37.')
        return
      }
      if(!p038.trim()){
        setMensaje('Por favor completa la pregunta 38.')
        return
      }
      if(!p039.trim()){
        setMensaje('Por favor completa la pregunta 39.')
        return
      }
      if(!p040.trim()){
        setMensaje('Por favor completa la pregunta 40.')
        return
      }
      if(!p041.trim()){
        setMensaje('Por favor completa la pregunta 41.')
        return
      }
      if(!p042.trim()){
        setMensaje('Por favor completa la pregunta 42.')
        return
      }
      if(!p043.trim()){
        setMensaje('Por favor completa la pregunta 43.')
        return
      }
      if(!p044.trim()){
        setMensaje('Por favor completa la pregunta 44.')
        return
      }
      if(!p045.trim()){
        setMensaje('Por favor completa la pregunta 45.')
        return
      }
      if(!p046.trim()){
        setMensaje('Por favor completa la pregunta 46.')
        return
      }
      if(!p047.trim()){
        setMensaje('Por favor completa la pregunta 47.')
        return
      }
      if(!p048.trim()){
        setMensaje('Por favor completa la pregunta 48.')
        return
      }
      if(!p049.trim()){
        setMensaje('Por favor completa la pregunta 49.')
        return
      }
      if(!p050.trim()){
        setMensaje('Por favor completa la pregunta 50.')
        return
      }
      if(!p051.trim()){
        setMensaje('Por favor completa la pregunta 51.')
        return
      }
      if(!p052.trim()){
        setMensaje('Por favor completa la pregunta 52.')
        return
      }
      if(!p053.trim()){
        setMensaje('Por favor completa la pregunta 53.')
        return
      }
      if(!p054.trim()){
        setMensaje('Por favor completa la pregunta 54.')
        return
      }
      if(!p055.trim()){
        setMensaje('Por favor completa la pregunta 55.')
        return
      }
      if(!p056.trim()){
        setMensaje('Por favor completa la pregunta 56.')
        return
      }
      if(!p057.trim()){
        setMensaje('Por favor completa la pregunta 57.')
        return
      }
      if(!p058.trim()){
        setMensaje('Por favor completa la pregunta 58.')
        return
      }
      if(!p059.trim()){
        setMensaje('Por favor completa la pregunta 59.')
        return
      }
      if(!p060.trim()){
        setMensaje('Por favor completa la pregunta 60.')
        return
      }
      if(!p061.trim()){
        setMensaje('Por favor completa la pregunta 61.')
        return
      }
      if(!p062.trim()){
        setMensaje('Por favor completa la pregunta 62.')
        return
      }
      if(!p063.trim()){
        setMensaje('Por favor completa la pregunta 63.')
        return
      }
      if(!p064.trim()){
        setMensaje('Por favor completa la pregunta 64.')
        return
      }
      if(!p065.trim()){
        setMensaje('Por favor completa la pregunta 65.')
        return
      }
      if(!p066.trim()){
        setMensaje('Por favor completa la pregunta 66.')
        return
      }
      if(!p067.trim()){
        setMensaje('Por favor completa la pregunta 67.')
        return
      }
      if(!p068.trim()){
        setMensaje('Por favor completa la pregunta 68.')
        return
      }
      if(!p069.trim()){
        setMensaje('Por favor completa la pregunta 69.')
        return
      }
      if(!p070.trim()){
        setMensaje('Por favor completa la pregunta 70.')
        return
      }
      if(!p071.trim()){
        setMensaje('Por favor completa la pregunta 71.')
        return
      }
      if(!p072.trim()){
        setMensaje('Por favor completa la pregunta 72.')
        return
      }
      if(!p073.trim()){
        setMensaje('Por favor completa la pregunta 73.')
        return
      }
      if(!p074.trim()){
        setMensaje('Por favor completa la pregunta 74.')
        return
      }
      if(!p075.trim()){
        setMensaje('Por favor completa la pregunta 75.')
        return
      }
      if(!p076.trim()){
        setMensaje('Por favor completa la pregunta 76.')
        return
      }
      if(!p077.trim()){
        setMensaje('Por favor completa la pregunta 77.')
        return
      }
      if(!p078.trim()){
        setMensaje('Por favor completa la pregunta 78.')
        return
      }
      if(!p079.trim()){
        setMensaje('Por favor completa la pregunta 79.')
        return
      }
      if(!p080.trim()){
        setMensaje('Por favor completa la pregunta 80.')
        return
      }
      if(!p081.trim()){
        setMensaje('Por favor completa la pregunta 81.')
        return
      }
      if(!p082.trim()){
        setMensaje('Por favor completa la pregunta 82.')
        return
      }
      if(!p083.trim()){
        setMensaje('Por favor completa la pregunta 83.')
        return
      }
      if(!p084.trim()){
        setMensaje('Por favor completa la pregunta 84.')
        return
      }
      if(!p085.trim()){
        setMensaje('Por favor completa la pregunta 85.')
        return
      }
      if(!p086.trim()){
        setMensaje('Por favor completa la pregunta 86.')
        return
      }
      if(!p087.trim()){
        setMensaje('Por favor completa la pregunta 87.')
        return
      }
      if(!p088.trim()){
        setMensaje('Por favor completa la pregunta 88.')
        return
      }
      if(!p089.trim()){
        setMensaje('Por favor completa la pregunta 89.')
        return
      }
      if(!p090.trim()){
        setMensaje('Por favor completa la pregunta 90.')
        return
      }
      if(!p091.trim()){
        setMensaje('Por favor completa la pregunta 91.')
        return
      }
      if(!p092.trim()){
        setMensaje('Por favor completa la pregunta 92.')
        return
      }
      if(!p093.trim()){
        setMensaje('Por favor completa la pregunta 93.')
        return
      }
      if(!p094.trim()){
        setMensaje('Por favor completa la pregunta 94.')
        return
      }
      if(!p095.trim()){
        setMensaje('Por favor completa la pregunta 95.')
        return
      }
      if(!p096.trim()){
        setMensaje('Por favor completa la pregunta 96.')
        return
      }
      if(!p097.trim()){
        setMensaje('Por favor completa la pregunta 97.')
        return
      }
      if(!p098.trim()){
        setMensaje('Por favor completa la pregunta 98.')
        return
      }
      if(!p099.trim()){
        setMensaje('Por favor completa la pregunta 99.')
        return
      }
      if(!p100.trim()){
        setMensaje('Por favor completa la pregunta 100.')
        return
      }
      if(!p101.trim()){
        setMensaje('Por favor completa la pregunta 101.')
        return
      }
      if(!p102.trim()){
        setMensaje('Por favor completa la pregunta 102.')
        return
      }
      if(!p103.trim()){
        setMensaje('Por favor completa la pregunta 103.')
        return
      }
      if(!p104.trim()){
        setMensaje('Por favor completa la pregunta 104.')
        return
      }
      if(!p105.trim()){
        setMensaje('Por favor completa la pregunta 105.')
        return
      }
      if(!p106.trim()){
        setMensaje('Por favor completa la pregunta 106.')
        return
      }
      if(!p107.trim()){
        setMensaje('Por favor completa la pregunta 107.')
        return
      }
      if(!p108.trim()){
        setMensaje('Por favor completa la pregunta 108.')
        return
      }
      if(!p109.trim()){
        setMensaje('Por favor completa la pregunta 109.')
        return
      }
      if(!p110.trim()){
        setMensaje('Por favor completa la pregunta 110.')
        return
      }
      if(!p111.trim()){
        setMensaje('Por favor completa la pregunta 111.')
        return
      }
      if(!p112.trim()){
        setMensaje('Por favor completa la pregunta 112.')
        return
      }
      if(!p113.trim()){
        setMensaje('Por favor completa la pregunta 113.')
        return
      }
      if(!p114.trim()){
        setMensaje('Por favor completa la pregunta 114.')
        return
      }
      if(!p115.trim()){
        setMensaje('Por favor completa la pregunta 115.')
        return
      }
      if(!p116.trim()){
        setMensaje('Por favor completa la pregunta 116.')
        return
      }
      if(!p117.trim()){
        setMensaje('Por favor completa la pregunta 117.')
        return
      }
      if(!p118.trim()){
        setMensaje('Por favor completa la pregunta 118.')
        return
      }
      if(!p119.trim()){
        setMensaje('Por favor completa la pregunta 119.')
        return
      }
      if(!p120.trim()){
        setMensaje('Por favor completa la pregunta 120.')
        return
      }
      if(!p121.trim()){
        setMensaje('Por favor completa la pregunta 121.')
        return
      }
      if(!p122.trim()){
        setMensaje('Por favor completa la pregunta 122.')
        return
      }
      if(!p123.trim()){
        setMensaje('Por favor completa la pregunta 123.')
        return
      }
      if(!p124.trim()){
        setMensaje('Por favor completa la pregunta 124.')
        return
      }
      if(!p125.trim()){
        setMensaje('Por favor completa la pregunta 125.')
        return
      }
      if(!p126.trim()){
        setMensaje('Por favor completa la pregunta 126.')
        return
      }
      if(!p127.trim()){
        setMensaje('Por favor completa la pregunta 127.')
        return
      }
      if(!p128.trim()){
        setMensaje('Por favor completa la pregunta 128.')
        return
      }
      if(!p129.trim()){
        setMensaje('Por favor completa la pregunta 129.')
        return
      }
      if(!p130.trim()){
        setMensaje('Por favor completa la pregunta 130.')
        return
      }
      if(!p131.trim()){
        setMensaje('Por favor completa la pregunta 131.')
        return
      }
      if(!p132.trim()){
        setMensaje('Por favor completa la pregunta 132.')
        return
      }
      if(!p133.trim()){
        setMensaje('Por favor completa la pregunta 133.')
        return
      }
      if(!p134.trim()){
        setMensaje('Por favor completa la pregunta 134.')
        return
      }
      if(!p135.trim()){
        setMensaje('Por favor completa la pregunta 135.')
        return
      }
      if(!p136.trim()){
        setMensaje('Por favor completa la pregunta 136.')
        return
      }
      if(!p137.trim()){
        setMensaje('Por favor completa la pregunta 137.')
        return
      }
      if(!p138.trim()){
        setMensaje('Por favor completa la pregunta 138.')
        return
      }
      if(!p139.trim()){
        setMensaje('Por favor completa la pregunta 139.')
        return
      }
      if(!p140.trim()){
        setMensaje('Por favor completa la pregunta 140.')
        return
      }
      if(!p141.trim()){
        setMensaje('Por favor completa la pregunta 141.')
        return
      }
      if(!p142.trim()){
        setMensaje('Por favor completa la pregunta 142.')
        return
      }
      if(!p143.trim()){
        setMensaje('Por favor completa la pregunta 143.')
        return
      }
      if(!p144.trim()){
        setMensaje('Por favor completa la pregunta 144.')
        return
      } 

      try {
  
        const nuevoTest = {
          p001: p001,
          p002: p002,
          p003: p003,
          p004: p004,
          p005: p005,
          p006: p006,
          p007: p007,
          p008: p008,
          p009: p009,
          p010: p010,
          p011: p011,
          p012: p012,
          p013: p013,
          p014: p014,
          p015: p015,
          p016: p016,
          p017: p017,
          p018: p018,
          p019: p019,
          p020: p020,
          p021: p021,
          p022: p022,
          p023: p023,
          p024: p024,
          p025: p025,
          p026: p026,
          p027: p027,
          p028: p028,
          p029: p029,
          p030: p030,
          p031: p031,
          p032: p032,
          p033: p033,
          p034: p034,
          p035: p035,
          p036: p036,
          p037: p037,
          p038: p038,
          p039: p039,
          p040: p040,
          p041: p041,
          p042: p042,
          p043: p043,
          p044: p044,
          p045: p045,
          p046: p046,
          p047: p047,
          p048: p048,
          p049: p049,
          p050: p050,
          p051: p051,
          p052: p052,
          p053: p053,
          p054: p054,
          p055: p055,
          p056: p056,
          p057: p057,
          p058: p058,
          p059: p059,
          p060: p060,
          p061: p061,
          p062: p062,
          p063: p063,
          p064: p064,
          p065: p065,
          p066: p066,
          p067: p067,
          p068: p068,
          p069: p069,
          p070: p070,
          p071: p071,
          p072: p072,
          p073: p073,
          p074: p074,
          p075: p075,
          p076: p076,
          p077: p077,
          p078: p078,
          p079: p079,
          p080: p080,
          p081: p081,
          p082: p082,
          p083: p083,
          p084: p084,
          p085: p085,
          p086: p086,
          p087: p087,
          p088: p088,
          p089: p089,
          p090: p090,
          p091: p091,
          p092: p092,
          p093: p093,
          p094: p094,
          p095: p095,
          p096: p096,
          p097: p097,
          p098: p098,
          p099: p099,
          p100: p100,
          p101: p101,
          p102: p102,
          p103: p103,
          p104: p104,
          p105: p105,
          p106: p106,
          p107: p107,
          p108: p108,
          p109: p109,
          p110: p110,
          p111: p111,
          p112: p112,
          p113: p113,
          p114: p114,
          p115: p115,
          p116: p116,
          p117: p117,
          p118: p118,
          p119: p119,
          p120: p120,
          p121: p121,
          p122: p122,
          p123: p123,
          p124: p124,
          p125: p125,
          p126: p126,
          p127: p127,
          p128: p128,
          p129: p129,
          p130: p130,
          p131: p131,
          p132: p132,
          p133: p133,
          p134: p134,
          p135: p135,
          p136: p136,
          p137: p137,
          p138: p138,
          p139: p139,
          p140: p140,
          p141: p141,
          p142: p142,
          p143: p143,
          p144: p144,

          eneatipouno: eneatipouno,
          eneatipodos: eneatipodos,
          eneatipotres: eneatipotres,
          eneatipocuatro: eneatipocuatro,
          eneatipocinco: eneatipocinco,
          eneatiposeis: eneatiposeis,
          eneatiposiete: eneatiposiete,
          eneatipoocho: eneatipoocho,
          eneatiponueve: eneatiponueve,

          fecha: Date.now()
        }
         
        setEneatipoUno(eneatipouno)
        setEneatipoDos(eneatipodos) 
        setEneatipoTres(eneatipotres)
        setEneatipoCuatro(eneatipocuatro) 
        setEneatipoCinco(eneatipocinco)
        setEneatipoSeis(eneatiposeis)
        setEneatipoSiete(eneatiposiete)
        setEneatipoOcho(eneatipoocho)
        setEneatipoNueve(eneatiponueve)
        
        
        setBloqueUno('El Reformador')
        setBloqueDos('El Ayudador')
        setBloqueTres('El Triunfador')
        setBloqueCuatro('El Individualista')
        setBloqueCinco(`El Investigador`)
        setBloqueSeis('El Leal')
        setBloqueSiete('El Entusiasta')
        setBloqueOcho('El Desafiador')
        setBloqueNueve('El Pacificador.')

        const data = await db.collection("testEneatipo:" + props.user.email).add(nuevoTest)
  
        setTests([
          ...tests,
          {...nuevoTest, id: data.id}
        ])
        
        setMensaje('El Test de Eneatipo fue guardado correctamente.')

        
      } catch (error) {
        console.log(error)
        
      }
          console.log("Eneatipo Uno: " + eneatipouno)
          console.log("Eneatipo Dos: " + eneatipodos)
          console.log("Eneatipo Tres: " + eneatipotres)
          console.log("Eneatipo Cuatro: " + eneatipocuatro)
          console.log("Eneatipo Cinco: " + eneatipocinco)
          console.log("Eneatipo Seis: " + eneatiposeis)
          console.log("Eneatipo Siete: " + eneatiposiete)
          console.log("Eneatipo Ocho: " + eneatipoocho)
          console.log("Eneatipo Nueve: " + eneatiponueve)
        
    }
      

    return (
        <main>
            <div className="contenedor">
        
            <div>
            <br/>
            <h5>Test de Eneagrama</h5>
            <p>Riso-Hudson</p>

            <p>Instrucciones:<br/>
            Por cada pregunta hay dos opciones de respuesta, debiendo necesariamente que elegir 1 
            sola. Las respuestas deben contestarse de manera honesta, como realmente usted 
            reaccionea, piensa, hace y siente y no como querría ser.
            Este test es solamente una orientación para que pueda hacer una primera deducción 
            del tipo de personalidad y pueda seguir indagando.</p>
            <br/>
                    <ul className="lista">
                        {
                        tests.map(item => (         
                            <li key={item.id}>
                              <a className="btn btn-light" 
                                 data-toggle="collapse" 
                                 href={"#"+item.id} 
                                 role="button" 
                                 aria-expanded="false" 
                                 aria-controls="collapseExample">
                                   Fecha: <Moment format="DD-MM-YYYY">{item.fecha}</Moment>
                              </a>
                              <br /> 
                            <div className="collapse" id={item.id}>
                            <div className="card card-body">
                                <b>Eneatipo 1: {item.eneatipouno}</b>
                                El Reformador<br/><br/>
                                
                                <b>Eneatipo 2: {item.eneatipodos}</b>
                                El Ayudador<br/><br/>

                                <b>Eneatipo 3: {item.eneatipotres}</b>
                                El Triunfador<br/><br/>

                                <b>Eneatipo 4: {item.eneatipocuatro}</b>
                                El Individualista<br/><br/>

                                <b>Eneatipo 5: {item.eneatipocinco}</b>
                                El Investigador<br/><br/>

                                <b>Eneatipo 6: {item.eneatiposeis}</b>
                                El Leal<br/><br/>

                                <b>Eneatipo 7: {item.eneatiposiete}</b>
                                El Entusiasta<br/><br/>

                                <b>Eneatipo 8: {item.eneatipoocho}</b>
                                El Desafiador<br/><br/>

                                <b>Eneatipo 9: {item.eneatiponueve}</b>
                                El Pacificador<br/><br/>

                                <br />          
                                <p className="peque">
                                Para conocer información sobre los Eneatipos ingresa en este link:<br/>
                                    <a href="https://sonria.com/eneagrama/" target="_blank">
                                        Devolución del Test
                                    </a>
                                </p>
                                {item.bloqueuno}
                                {item.bloquedos}
                                {item.bloquetres}
                                {item.bloquecuatro}
                                {item.bloquecinco}
                                {item.bloqueseis}
                                {item.bloquesiete}
                                {item.bloqueocho}
                                {item.bloquenueve}
                                {item.bloquediez}

                            </div>
                            </div>
                            </li>
                        ))
                        }
                    </ul>
                    
        </div>
        <div>
                    <br/>
                    <h5>
                    <a className="btn btn-secondary" 
                       data-toggle="collapse" 
                       href="#collapseForm" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                          Realizar Test
                    </a>              
                    </h5>
                    <div className="collapse" id="collapseForm">
                    <div className="card card-body">
        
                    <form onSubmit={agregar}>
                        <h5>001:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p001 === 'enea5'} 
                                        onChange={e => setP001(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Sobre todo he sido romántico e imaginativo.
                                </p>
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p001 === 'enea2'} 
                                        onChange={e => setP001(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Sobre todo he sido pragmático y realista.
                                </p>
                                </div>
                            </div>
                            <h5>002:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p002 === 'enea7'} 
                                        onChange={e => setP002(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Me he inclinado a enfrentarme a la gente.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p002 === 'enea1'} 
                                        onChange={e => setP002(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Me he inclinado a evitar los enfrentamientos.
                                </p>
                                </div>
                            </div>
                            <h5>003:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p003 === 'enea3'} 
                                        onChange={e => setP003(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> La gente me suele ver como una persona diplomática, encantadora y ambiciosa.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p003 === 'enea4'} 
                                        onChange={e => setP003(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Generalmente me han descrito como una persona directa, formal e idealista.
                                </p>
                                </div>
                            </div>
                            <h5>004:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p004 === 'enea8'} 
                                        onChange={e => setP004(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Sobre todo me he inclinado a llevar una vida intensa y bien centrada.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p004 === 'enea9'} 
                                        onChange={e => setP004(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Sobre todo me he inclinado a ser espontáneo y divertirme.
                                </p>
                                </div>
                            </div>
                            <h5>005:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p005 === 'enea6'} 
                                        onChange={e => setP005(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> He sido una persona acogedora y he disfrutado rodeándome de nuevas amistades en mi vida.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p005 === 'enea5'} 
                                        onChange={e => setP005(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> He protegido mucho mi vida privada no mezclándome demasiado con los demás.
                                </p>
                                </div>
                            </div>
                            <h5>006:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p006 === 'enea2'} 
                                        onChange={e => setP006(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Generalmente ha sido fácil hacerme enfadar.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p006 === 'enea1'} 
                                        onChange={e => setP006(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Generalmente ha sido difícil hacerme enfadar.
                                </p>
                                </div>
                            </div>
                            <h5>007:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p007 === 'enea7'} 
                                        onChange={e => setP007(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> He sido mas bien una persona que se ha fortalecido haciéndose a si misma.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p007 === 'enea4'} 
                                        onChange={e => setP007(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> He sido mas bien un idealista de grandes valores.
                                </p>
                                </div>
                            </div>
                            <h5>008:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p008 === 'enea6'} 
                                        onChange={e => setP008(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Me he inclinado a mostrar mi afecto ante los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea8" 
                                        checked={p008 === 'enea8'} 
                                        onChange={e => setP008(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> He preferido mantenerme a cierta distancia de los demás.
                                </p>
                                </div>
                            </div>
                            <h5>009:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p009 === 'enea3'} 
                                        onChange={e => setP009(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Ante nuevas experiencias suelo preguntarme si me resultaran útiles y eficaces.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p009 === 'enea9'} 
                                        onChange={e => setP009(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Ante nuevas experiencias suelo preguntarme si me resultaran agradables y placenteras.
                                </p>
                                </div>
                            </div>
                            <h5>010:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p010 === 'enea5'} 
                                        onChange={e => setP010(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Sobre todo me he inclinado a centrarme demasiado en mí mismo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p010 === 'enea1'} 
                                        onChange={e => setP010(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Sobre todo me he inclinado a centrarme demasiado en los demás.
                                </p>
                                </div>
                            </div>
                            <h5>011:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p011 === 'enea8'} 
                                        onChange={e => setP011(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Otras personas han dependido de mi intuición y sabiduría.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p011 === 'enea7'} 
                                        onChange={e => setP011(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Otras personas han dependido de mi fortaleza y capacidad de decisión.
                                </p>
                                </div>
                            </div>
                            <h5>012:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p012 === 'enea2'} 
                                        onChange={e => setP012(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> He dado la impresión de estar demasiado inseguro de mí mismo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p012 === 'enea4'} 
                                        onChange={e => setP012(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> He dado la impresión de estar demasiado seguro de mí mismo.
                                </p>
                                </div>
                            </div>
                            <h5>013:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p013 === 'enea6'} 
                                        onChange={e => setP013(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Me he orientado mas hacia las relaciones interpersonales que hacia la consecución de objetivos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p013 === 'enea3'} 
                                        onChange={e => setP013(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Me he orientado mas hacia la consecución de objetivos que hacia las relaciones interpersonales.
                                </p>
                                </div>
                            </div>
                            <h5>014:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p014 === 'enea5'} 
                                        onChange={e => setP014(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Me suelo inhibir mucho y no soy capaz de expresarme bien.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p014 === 'enea9'} 
                                        onChange={e => setP014(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Soy muy claro, digo lo que otros habrían deseado tener el arrojo de decir.
                                </p>
                                </div>
                            </div>
                            <h5>015:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p015 === 'enea8'} 
                                        onChange={e => setP015(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Me ha resultado difícil dejar de considerar las diferentes alternativas y hacer algo definitivo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p015 === 'enea4'} 
                                        onChange={e => setP015(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Me ha resultado difícil relajarme y ser mas flexible.
                                </p>
                                </div>
                            </div>
                            <h5>016:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p016 === 'enea2'} 
                                        onChange={e => setP016(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Tiendo a demorarme y no me gusta tomar la iniciativa.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p016 === 'enea7'} 
                                        onChange={e => setP016(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Tiendo a tomar la iniciativa y a ser dominante.
                                </p>
                                </div>
                            </div>
                            <h5>017:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea1" 
                                        checked={p017 === 'enea1'} 
                                        onChange={e => setP017(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Mi renuencia a implicarme con la gente me ha ocasionado algunos problemas.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea6" 
                                        checked={p017 === 'enea6'} 
                                        onChange={e => setP017(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Mis deseos de que la gente dependa de mi me ha ocasionado algunos problemas.
                                </p>
                                </div>
                            </div>
                            <h5>018:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p018 === 'enea3'} 
                                        onChange={e => setP018(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Normalmente he sido capaz de prescindir de mis sentimientos para hacer bien mi trabajo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p018 === 'enea5'} 
                                        onChange={e => setP018(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Normalmente he necesitado dar prioridad a mis sentimientos antes de poder actuar.
                                </p>
                                </div>
                            </div>
                            <h5>019:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p019 === 'enea2'} 
                                        onChange={e => setP019(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Sobre todo me he inclinado a ser cauto y metódico.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p019 === 'enea9'} 
                                        onChange={e => setP019(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Sobre todo me he inclinado hacia el riesgo y la aventura.
                                </p>
                                </div>
                            </div>
                            <h5>020:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p020 === 'enea6'} 
                                        onChange={e => setP020(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> He tendido a ser una persona que se ha entregado a los demás y he disfrutado de la compañía de la gente.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p020 === 'enea4'} 
                                        onChange={e => setP020(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> He tendido a ser una persona reservada y seria a la que le gusta hablar y discutir los problemas.
                                </p>
                                </div>
                            </div>
                            <h5>021:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p021 === 'enea7'} 
                                        onChange={e => setP021(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Con frecuencia he sentido la necesidad de ser como un pilar de fortaleza.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p021 === 'enea3'} 
                                        onChange={e => setP021(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Con frecuencia he sentido la necesidad de hacer las cosas a la perfección.
                                </p>
                                </div>
                            </div>
                            <h5>022:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p022 === 'enea8'} 
                                        onChange={e => setP022(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Normalmente me he interesado por las preguntas difíciles y por preservar mi independencia.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p022 === 'enea1'} 
                                        onChange={e => setP022(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Normalmente me he interesado en mantener mi estabilidad y mi paz mental.
                                </p>
                                </div>
                            </div>
                            <h5>023:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p023 === 'enea2'} 
                                        onChange={e => setP023(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> He sido una persona demasiado escéptica y dura.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea6" 
                                        checked={p023 === 'enea6'} 
                                        onChange={e => setP023(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> He sido una persona demasiado sentimental y tierna.
                                </p>
                                </div>
                            </div>
                            <h5>024:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea9" 
                                        checked={p024 === 'enea9'} 
                                        onChange={e => setP024(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Con frecuencia me preocupa que me vaya a perder las cosas buenas de la vida.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p024 === 'enea7'} 
                                        onChange={e => setP024(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Con frecuencia me preocupa que alguien se aproveche de mi si bajo la guardia.
                                </p>
                                </div>
                            </div>
                            <h5>025:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p025 === 'enea5'} 
                                        onChange={e => setP025(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Mi costumbre de ser tan reservado molesta a la gente.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p025 === 'enea4'} 
                                        onChange={e => setP025(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Mi costumbre de decir a la gente lo que hacer les resulta a veces molesta.
                                </p>
                                </div>
                            </div>
                            <h5>026:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea1" 
                                        checked={p026 === 'enea1'} 
                                        onChange={e => setP026(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Cuando he tenido problemas, normalmente he sido capaz de superarlos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p026 === 'enea9'} 
                                        onChange={e => setP026(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Cuando he tenido problemas, normalmente me he invitado a mi mismo a algo que me gusta.
                                </p>
                                </div>
                            </div>
                            <h5>027:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p027 === 'enea2'} 
                                        onChange={e => setP027(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Sobre todo he dependido de mis amigos y ellos han sabido que pueden depender de mi.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p027 === 'enea3'} 
                                        onChange={e => setP027(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> No he dependido de nadie hago las cosas por mi mismo.
                                </p>
                                </div>
                            </div>
                            <h5>028:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p028 === 'enea5'} 
                                        onChange={e => setP028(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Tiendo a ser voluble y a permanecer en mi mundo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea8" 
                                        checked={p028 === 'enea8'} 
                                        onChange={e => setP028(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Me he inclinado a ser bastante desapegado.
                                </p>
                                </div>
                            </div>
                            <h5>029:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p029 === 'enea7'} 
                                        onChange={e => setP029(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> He disfrutado desafiando a la gente y haciéndoles reaccionar.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea6" 
                                        checked={p029 === 'enea6'} 
                                        onChange={e => setP029(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> He disfrutado conformando y calmando a los demás.
                                </p>
                                </div>
                            </div>
                            <h5>030:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea9" 
                                        checked={p030 === 'enea9'} 
                                        onChange={e => setP030(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Generalmente he sido una persona sociable y extrovertida.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p030 === 'enea4'} 
                                        onChange={e => setP030(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Generalmente he sido una persona disciplinada y formal.
                                </p>
                                </div>
                            </div>
                            <h5>031:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea1" 
                                        checked={p031 === 'enea1'} 
                                        onChange={e => setP031(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Normalmente soy tímido a la hora de mostrar mis habilidades.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p031 === 'enea3'} 
                                        onChange={e => setP031(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Normalmente me gusta que la gente sepa lo que hago bien.
                                </p>
                                </div>
                            </div>
                            <h5>032:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p032 === 'enea8'} 
                                        onChange={e => setP032(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Para mi ha sido mas importante desarrollar mis intereses personales que poseer seguridad y comodidad.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea2" 
                                        checked={p032 === 'enea2'} 
                                        onChange={e => setP032(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Para mi ha sido mas importante disfrutar de la seguridad y la comodidad que desarrollar mis intereses personales.
                                </p>
                                </div>
                            </div>
                            <h5>033:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p033 === 'enea5'} 
                                        onChange={e => setP033(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Sobre todo me he inclinado a huir de las situaciones conflictivas con los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p033 === 'enea7'} 
                                        onChange={e => setP033(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Rara vez me he achantado ante las situaciones conflictivas con los demás.
                                </p>
                                </div>
                            </div>
                            <h5>034:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea1" 
                                        checked={p034 === 'enea1'} 
                                        onChange={e => setP034(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Normalmente he cedido con facilidad y me he dejado manejar por los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p034 === 'enea4'} 
                                        onChange={e => setP034(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Normalmente he sido demasiado intransigente y exigente con los demás.
                                </p>
                                </div>
                            </div>
                            <h5>035:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea9" 
                                        checked={p035 === 'enea9'} 
                                        onChange={e => setP035(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Me han apreciado por mi inquebrantable espíritu y mi gran sentido del humor.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea6" 
                                        checked={p035 === 'enea6'} 
                                        onChange={e => setP035(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Me han apreciado por mi gran generosidad y mi callada fortaleza.
                                </p>
                                </div>
                            </div>
                            <h5>036:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p036 === 'enea3'} 
                                        onChange={e => setP036(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Gran parte de mis éxitos se han debido a mi capacidad para dar una buena impresión.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea8" 
                                        checked={p036 === 'enea8'} 
                                        onChange={e => setP036(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Gran parte de mis éxitos los he logrado a pesar de mis limitadas habilidades interpersonales.
                                </p>
                                </div>
                            </div>
                            <h5>037:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p037 === 'enea2'} 
                                        onChange={e => setP037(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Me enorgullezco de mi perseverancia y mi sentido común.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p037 === 'enea5'} 
                                        onChange={e => setP037(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Me enorgullezco de mi originalidad y mi capacidad de invención.
                                </p>
                                </div>
                            </div>
                            <h5>038:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea1" 
                                        checked={p038 === 'enea1'} 
                                        onChange={e => setP038(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Básicamente me conformo con facilidad y soy agradable.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p038 === 'enea7'} 
                                        onChange={e => setP038(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Básicamente soy asertivo y practico.
                                </p>
                                </div>
                            </div>
                            <h5>039:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p039 === 'enea3'} 
                                        onChange={e => setP039(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Me he esforzado mucho para que los demás me acepten y quieran.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p039 === 'enea4'} 
                                        onChange={e => setP039(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Para mi ser aceptado y querido no ha sido una de mis prioridades.
                                </p>
                                </div>
                            </div>
                            <h5>040:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p040 === 'enea8'} 
                                        onChange={e => setP040(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Ante la presión de los demás, me he vuelto mas huidizo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p040 === 'enea9'} 
                                        onChange={e => setP040(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Ante la presión de los demás, me he vuelto mas agresivo.
                                </p>
                                </div>
                            </div>
                            <h5>041:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p041 === 'enea6'} 
                                        onChange={e => setP041(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Los demás se han interesado en mi porque soy extrovertido y me intereso y comprometo con ellos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p041 === 'enea5'} 
                                        onChange={e => setP041(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Los demás se han interesado en mi porque soy tranquilo, original y profundo.
                                </p>
                                </div>
                            </div>
                            <h5>042:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p042 === 'enea2'} 
                                        onChange={e => setP042(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> El deber y la responsabilidad han sido valores importantes para mi.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p042 === 'enea1'} 
                                        onChange={e => setP042(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> La armonía y la aceptación han sido valores importantes para mi.
                                </p>
                                </div>
                            </div>
                            <h5>043:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p043 === 'enea7'} 
                                        onChange={e => setP043(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> He intentado motivar a la gente haciendo grandes planes y promesas.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p043 === 'enea4'} 
                                        onChange={e => setP043(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> He intentado motivar a la gente señalando lo que ocurrirá si no siguen mis consejos.
                                </p>
                                </div>
                            </div>
                            <h5>044:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p044 === 'enea8'} 
                                        onChange={e => setP044(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Rara vez he sido demasiado expresivo emocionalmente.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea6" 
                                        checked={p044 === 'enea6'} 
                                        onChange={e => setP044(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Con frecuencia he sido bastante expresivo emocionalmente.
                                </p>
                                </div>
                            </div>
                            <h5>045:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea9" 
                                        checked={p045 === 'enea9'} 
                                        onChange={e => setP045(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Ocuparme de atender a los detalles no ha sido precisamente uno de mis puntos fuertes.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p045 === 'enea3'} 
                                        onChange={e => setP045(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> He tenido una gran habilidad para ocuparme de los detalles.
                                </p>
                                </div>
                            </div>
                            <h5>046:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p046 === 'enea5'} 
                                        onChange={e => setP046(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Con frecuencia he destacado cuan diferente soy de mis amigos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p046 === 'enea1'} 
                                        onChange={e => setP046(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Con frecuencia he destacado cuantas cosas en común tengo con mis amigos.
                                </p>
                                </div>
                            </div>
                            <h5>047:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p047 === 'enea8'} 
                                        onChange={e => setP047(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Cuando las situaciones se han caldeado me he inclinado a permanecer al margen.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p047 === 'enea7'} 
                                        onChange={e => setP047(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Cuando las situaciones se han caldeado he ido a afrontarlas directamente por encima de todo.
                                </p>
                                </div>
                            </div>
                            <h5>048:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p048 === 'enea2'} 
                                        onChange={e => setP048(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> He permanecido junto a mis amigos, incluso cuando estaban equivocados.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p048 === 'enea4'} 
                                        onChange={e => setP048(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Nunca he querido transigir en lo que considero adecuado para las relaciones.
                                </p>
                                </div>
                            </div>
                            <h5>049:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p049 === 'enea6'} 
                                        onChange={e => setP049(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> He sido un seguidor bien intencionado.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p049 === 'enea3'} 
                                        onChange={e => setP049(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> He sido una persona ambiciosa muy motivada.
                                </p>
                                </div>
                            </div>
                            <h5>050:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p050 === 'enea5'} 
                                        onChange={e => setP050(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Rumio mis problemas hasta que puedo deshacerme de ellos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p050 === 'enea9'} 
                                        onChange={e => setP050(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Me suelo distraer en otras cosas hasta que veo la forma de solucionar mis problemas.
                                </p>
                                </div>
                            </div>
                            <h5>051:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea4" 
                                        checked={p051 === 'enea4'} 
                                        onChange={e => setP051(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Generalmente suelo tener una gran convicción y sentido de cómo deberían ser las cosas.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea8" 
                                        checked={p051 === 'enea8'} 
                                        onChange={e => setP051(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Generalmente he tenido serias dudas y me cuestiono sobre lo que parecen las cosas.
                                </p>
                                </div>
                            </div>
                            <h5>052:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p052 === 'enea2'} 
                                        onChange={e => setP052(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> He tenido problemas con otras personas debido a mi pesimismo y quejas.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p052 === 'enea7'} 
                                        onChange={e => setP052(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> He tenido problemas con otras personas debido a que he sido demasiado controlador y exigente.
                                </p>
                                </div>
                            </div>
                            <h5>053:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p053 === 'enea6'} 
                                        onChange={e => setP053(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Me he inclinado a actuar basándome en mis sentimientos y dejar que pase lo que tenga que pasar.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p053 === 'enea1'} 
                                        onChange={e => setP053(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Me he inclinado a no actuar basándome en mis sentimientos a menos que estos hagan surgir mas problemas.
                                </p>
                                </div>
                            </div>
                            <h5>054:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p054 === 'enea3'} 
                                        onChange={e => setP054(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Normalmente me he sentido cómodo siendo el centro de atención.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p054 === 'enea5'} 
                                        onChange={e => setP054(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Normalmente no me he sentido cómodo siendo el centro de atención.
                                </p>
                                </div>
                            </div>
                            <h5>055:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p055 === 'enea2'} 
                                        onChange={e => setP055(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> He sido cauto y he intentado estar preparado para los problemas imprevisibles.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p055 === 'enea9'} 
                                        onChange={e => setP055(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Sobre todo he sido espontáneo y he preferido solventar los problemas a medida que ocurrían.
                                </p>
                                </div>
                            </div>
                            <h5>056:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p056 === 'enea6'} 
                                        onChange={e => setP056(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Me he enfadado cuando los demás no han apreciado lo que he hecho por ellos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p056 === 'enea4'} 
                                        onChange={e => setP056(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Me he enfadado cuando los demás no han escuchado lo que yo tenia que decirles.
                                </p>
                                </div>
                            </div>
                            <h5>057:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p057 === 'enea7'} 
                                        onChange={e => setP057(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Para mi ha sido importante mantener mi independencia y la confianza en mi mismo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p057 === 'enea3'} 
                                        onChange={e => setP057(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Para mi ha sido importante sentirme valorado y admirado.
                                </p>
                                </div>
                            </div>
                            <h5>058:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p058 === 'enea8'} 
                                        onChange={e => setP058(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Cuando discuto con amigos me inclino a imponer mis ideas.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p058 === 'enea1'} 
                                        onChange={e => setP058(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Cuando discuto con amigos me inclino a dejar que las aguas sigan su curso para prevenir sinsabores.
                                </p>
                                </div>
                            </div>
                            <h5>059:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p059 === 'enea6'} 
                                        onChange={e => setP059(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Con frecuencia he sido posesivo con mis seres queridos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea2" 
                                        checked={p059 === 'enea2'} 
                                        onChange={e => setP059(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Con frecuencia he puesto a prueba a mis seres queridos para ver si estaban a mi lado.
                                </p>
                                </div>
                            </div>
                            <h5>060:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p060 === 'enea7'} 
                                        onChange={e => setP060(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Uno de mis mayores esfuerzos ha sido la organización de los recursos y hacer que las cosas fueran adelante.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p060 === 'enea9'} 
                                        onChange={e => setP060(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Uno de mis mayores esfuerzos ha sido la creación de nuevas ideas y entusiasmar con ellas a los demás.
                                </p>
                                </div>
                            </div>
                            <h5>061:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea4" 
                                        checked={p061 === 'enea4'} 
                                        onChange={e => setP061(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Me he inclinado a ser riguroso y duro con migo mismo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p061 === 'enea5'} 
                                        onChange={e => setP061(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Me he inclinado a ser demasiado emotivo y mas bien poco disciplinado.
                                </p>
                                </div>
                            </div>
                            <h5>062:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea9" 
                                        checked={p062 === 'enea9'} 
                                        onChange={e => setP062(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> He intentado que mi vida fuera dinámica, intensa y excitante.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p062 === 'enea1'} 
                                        onChange={e => setP062(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> He intentado que mi vida fuera constante, estable y tranquila.
                                </p>
                                </div>
                            </div>
                            <h5>063:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p063 === 'enea2'} 
                                        onChange={e => setP063(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Me ha resultado difícil realizar los mayores cambios de mi vida porque me cuesta bastante renunciar al pasado.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p063 === 'enea3'} 
                                        onChange={e => setP063(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Me ha resultado bastante fácil realizar los mayores cambios de mi vida porque me cuesta mucho comprometerme a largo plazo.
                                </p>
                                </div>
                            </div>  
                            <h5>064:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p064 === 'enea5'} 
                                        onChange={e => setP064(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Generalmente me he inclinado a enfatizar mis sentimientos y apegarme a ellos durante mucho tiempo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea8" 
                                        checked={p064 === 'enea8'} 
                                        onChange={e => setP064(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Generalmente me he inclinado a minimizar mis sentimientos y a no prestarles mucha atención.
                                </p>
                                </div>
                            </div>
                            <h5>065:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p065 === 'enea6'} 
                                        onChange={e => setP065(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> He atendido y apoyado a mucha gente.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p065 === 'enea7'} 
                                        onChange={e => setP065(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> He dirigido y motivado a mucha gente.
                                </p>
                                </div>
                            </div>
                            <h5>066:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea4" 
                                        checked={p066 === 'enea4'} 
                                        onChange={e => setP066(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> He sido demasiado serio y estricto con migo mismo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p066 === 'enea9'} 
                                        onChange={e => setP066(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> He sido demasiado confiado y permisivo conmigo mismo.
                                </p>
                                </div>
                            </div>
                            <h5>067:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p067 === 'enea3'} 
                                        onChange={e => setP067(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Sobre todo he sido asertivo y orientado a la superación.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p067 === 'enea1'} 
                                        onChange={e => setP067(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Sobre todo he sido modesto y me he contentado con ir a mi propio ritmo.
                                </p>
                                </div>
                            </div>
                            <h5>068:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p068 === 'enea8'} 
                                        onChange={e => setP068(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Me he sentido orgulloso de ser una persona objetiva y clara.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea2" 
                                        checked={p068 === 'enea2'} 
                                        onChange={e => setP068(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Me he sentido orgulloso de ser una persona comprometida en la que se puede confiar.
                                </p>
                                </div>
                            </div>
                            <h5>069:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p069 === 'enea5'} 
                                        onChange={e => setP069(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> He empleado mucho tiempo en descubrir lo que mis sentimientos e impulsos tienen que decirme.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p069 === 'enea7'} 
                                        onChange={e => setP069(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Estar pendiente de los propios sentimientos es una perdida de tiempo; lo que importa es hacer cosas.
                                </p>
                                </div>
                            </div>
                            <h5>070:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea1" 
                                        checked={p070 === 'enea1'} 
                                        onChange={e => setP070(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Normalmente me he visto a mi mismo como una persona alegre y despreocupada.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p070 === 'enea4'} 
                                        onChange={e => setP070(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Normalmente me he visto a mi mismo como una persona seria y digna.
                                </p>
                                </div>
                            </div>
                            <h5>071:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea9" 
                                        checked={p071 === 'enea9'} 
                                        onChange={e => setP071(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Me he caracterizado por mi agudeza mental y por poseer una energía sin limites.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea6" 
                                        checked={p071 === 'enea6'} 
                                        onChange={e => setP071(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Me he caracterizado por mi profunda dedicación y mi preocupación por los demás.
                                </p>
                                </div>
                            </div>
                            <h5>072:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p072 === 'enea3'} 
                                        onChange={e => setP072(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> He intentado realizar actividades que me proporcionaran algún tipo de premio o reconocimiento personal.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea8" 
                                        checked={p072 === 'enea8'} 
                                        onChange={e => setP072(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Me he inclinado a anteponer las actividades que realmente me interesaban sobre aquellas que podían proporcionarme alguna recompensa o reconocimiento personal.
                                </p>
                                </div>
                            </div>
                            <h5>073:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p073 === 'enea5'} 
                                        onChange={e => setP073(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Cumplir con las obligaciones sociales no ha sido para mi una de mis prioridades.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea2" 
                                        checked={p073 === 'enea2'} 
                                        onChange={e => setP073(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Normalmente me he tomado muy en serio cumplir mis obligaciones sociales.
                                </p>
                                </div>
                            </div>
                            <h5>074:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p074 === 'enea7'} 
                                        onChange={e => setP074(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Casi siempre he preferido hacerme yo mismo cargo de la situación.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p074 === 'enea1'} 
                                        onChange={e => setP074(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Casi siempre he preferido que otra persona se hiciera cargo de la situación.
                                </p>
                                </div>
                            </div>
                            <h5>075:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p075 === 'enea3'} 
                                        onChange={e => setP075(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Mis valores y mi estilo de vida han cambiado varias veces a lo largo de los años.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p075 === 'enea4'} 
                                        onChange={e => setP075(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Mis valores y mi estilo de vida han seguido siendo consistentes y prácticamente los mismos a lo largo de los años.
                                </p>
                                </div>
                            </div>
                            <h5>076:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea9" 
                                        checked={p076 === 'enea9'} 
                                        onChange={e => setP076(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Normalmente no he sido demasiado autodisciplinado.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea8" 
                                        checked={p076 === 'enea8'} 
                                        onChange={e => setP076(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Normalmente no he estado demasiado en contacto con la gente.
                                </p>
                                </div>
                            </div>
                            <h5>077:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p077 === 'enea6'} 
                                        onChange={e => setP077(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Con frecuencia me he sentido vulnerable al estar rodeado de gente.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p077 === 'enea7'} 
                                        onChange={e => setP077(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Con frecuencia he sentido que mis sacrificios se veían como algo normal.
                                </p>
                                </div>
                            </div>
                            <h5>078:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p078 === 'enea2'} 
                                        onChange={e => setP078(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Me he inclinado a pensar siempre lo peor.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p078 === 'enea1'} 
                                        onChange={e => setP078(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Me he inclinado a pensar que las cosas siempre saldrían bien.
                                </p>
                                </div>
                            </div>
                            <h5>079:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p079 === 'enea7'} 
                                        onChange={e => setP079(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> La gente ha confiado en mi porque soy discreto y puedo mirar por sus intereses.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p079 === 'enea4'} 
                                        onChange={e => setP079(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> La gente ha confiado en mi porque soy justo y hago lo que es correcto.
                                </p>
                                </div>
                            </div>
                            <h5>080:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p080 === 'enea8'} 
                                        onChange={e => setP080(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> A menudo me implico tanto en mis propios proyectos que con frecuencia me aíslo de los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea6" 
                                        checked={p080 === 'enea6'} 
                                        onChange={e => setP080(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> A menudo me implico tanto con los demás, que descuido mis propios proyectos.
                                </p>
                                </div>
                            </div>
                            <h5>081:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p081 === 'enea3'} 
                                        onChange={e => setP081(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Cuando me presentan a alguien nuevo suelo mostrarme sereno y controlado.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p081 === 'enea9'} 
                                        onChange={e => setP081(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Cuando me presentan a alguien suelo ser afectuoso y entretenido.
                                </p>
                                </div>
                            </div>
                            <h5>082:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p082 === 'enea5'} 
                                        onChange={e => setP082(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Hablando de forma general, he tendido a ser pesimista.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p082 === 'enea1'} 
                                        onChange={e => setP082(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Hablando de forma general, he tendido a ser optimista.
                                </p>
                                </div>
                            </div>
                            <h5>083:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p083 === 'enea8'} 
                                        onChange={e => setP083(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> He preferido habitar mi propio y pequeño mundo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p083 === 'enea7'} 
                                        onChange={e => setP083(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> He preferido mostrar a los demás quien soy.
                                </p>
                                </div>
                            </div>
                            <h5>084:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p084 === 'enea2'} 
                                        onChange={e => setP084(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Sobre todo he padecido nerviosismo, inseguridad y duda.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p084 === 'enea4'} 
                                        onChange={e => setP084(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Sobre todo he padecido de impaciencia y de un perfeccionismo voraz.
                                </p>
                                </div>
                            </div>
                            <h5>085:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p085 === 'enea6'} 
                                        onChange={e => setP085(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Me doy cuenta que a menudo he sido demasiado intimo y personal.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p085 === 'enea3'} 
                                        onChange={e => setP085(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Me doy cuenta que a menudo he sido demasiado frío y reservado.
                                </p>
                                </div>
                            </div>
                            <h5>086:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p086 === 'enea5'} 
                                        onChange={e => setP086(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Me he perdido cosas porque no sentía que tenia que aprovechar las oportunidades.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p086 === 'enea9'} 
                                        onChange={e => setP086(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Me he perdido cosas porque he perseguido siempre muchas posibilidades.
                                </p>
                                </div>
                            </div>
                            <h5>087:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p087 === 'enea8'} 
                                        onChange={e => setP087(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Generalmente me lleva bastante tiempo empezar a hacer algo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p087 === 'enea4'} 
                                        onChange={e => setP087(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Generalmente suelo empezar a hacer las cosas con rapidez.
                                </p>
                                </div>
                            </div>
                            <h5>088:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p088 === 'enea2'} 
                                        onChange={e => setP088(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Con frecuencia me ha resultado difícil decidirme.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p088 === 'enea7'} 
                                        onChange={e => setP088(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Rara vez me ha resultado difícil decidirme.
                                </p>
                                </div>
                            </div>
                            <h5>089:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p089 === 'enea7'} 
                                        onChange={e => setP089(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> He tenido tendencia a imponerme demasiado ante los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p089 === 'enea1'} 
                                        onChange={e => setP089(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Me he inclinado a no imponerme lo suficiente ante los demás.
                                </p>
                                </div>
                            </div>
                            <h5>090:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p090 === 'enea3'} 
                                        onChange={e => setP090(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Generalmente me he desviado de mi camino para conocer gente nueva y realizar contactos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p090 === 'enea5'} 
                                        onChange={e => setP090(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Generalmente no me he desviado de mi camino para conocer gente nueva y realizar contactos.
                                </p>
                                </div>
                            </div>
                            <h5>091:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p091 === 'enea2'} 
                                        onChange={e => setP091(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> He buscado a alguien que me aconsejara cuando no he estado seguro de que hacer.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p091 === 'enea9'} 
                                        onChange={e => setP091(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Cuando no he estado seguro de que hacer, he probado cosas diferentes para ver cual me resultaba mejor.
                                </p>
                                </div>
                            </div>
                            <h5>092:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p092 === 'enea6'} 
                                        onChange={e => setP092(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Sobre todo he temido quedarme al margen de las actividades de los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p092 === 'enea4'} 
                                        onChange={e => setP092(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Sobre todo he temido  que las actividades de los demás me distrajeran de lo que yo tenia que hacer.
                                </p>
                                </div>
                            </div>
                            <h5>093:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p093 === 'enea7'} 
                                        onChange={e => setP093(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Normalmente, cuando me he enfadado, se lo he dicho a la gente.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p093 === 'enea3'} 
                                        onChange={e => setP093(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Normalmente, cuando me he enfadado, me he vuelto distante.
                                </p>
                                </div>
                            </div>
                            <h5>094:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p094 === 'enea8'} 
                                        onChange={e => setP094(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Suelo tener problemas para dormirme.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p094 === 'enea1'} 
                                        onChange={e => setP094(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Suelo dormirme con facilidad.
                                </p>
                                </div>
                            </div>
                            <h5>095:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p095 === 'enea6'} 
                                        onChange={e => setP095(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> A menudo he intentado averiguar cual era la forma de estar mas cerca de los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea2" 
                                        checked={p095 === 'enea2'} 
                                        onChange={e => setP095(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> A menudo he intentado averiguar que es lo que quieren los demás de mi.
                                </p>
                                </div>
                            </div>
                            <h5>096:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p096 === 'enea6'} 
                                        onChange={e => setP096(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Normalmente he sido comedido, directo y prudente.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p096 === 'enea9'} 
                                        onChange={e => setP096(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Normalmente he sido excitable, muy hablador e ingenioso.
                                </p>
                                </div>
                            </div>
                            <h5>097:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p097 === 'enea5'} 
                                        onChange={e => setP097(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Muchas veces no he dicho nada cuando he visto que otros se equivocaban.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p097 === 'enea4'} 
                                        onChange={e => setP097(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Muchas veces he ayudado a los demás a que vieran que estaban equivocados.
                                </p>
                                </div>
                            </div>
                            <h5>098:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea9" 
                                        checked={p098 === 'enea9'} 
                                        onChange={e => setP098(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> A lo largo de mi vida he sido mas bien una persona temperamental, de sentimientos cambiantes.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p098 === 'enea1'} 
                                        onChange={e => setP098(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> A lo largo de mi vida he sido mas bien una persona tranquila en cuyo interior fluye una suave corriente.
                                </p>
                                </div>
                            </div>
                            <h5>099:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p099 === 'enea3'} 
                                        onChange={e => setP099(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Cuando alguien no me cae bien, normalmente he intentado ser cordial a pesar de mis sentimientos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea2" 
                                        checked={p099 === 'enea2'} 
                                        onChange={e => setP099(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Cuando alguien no me cae bien, normalmente se lo he hecho saber de una forma u otra.
                                </p>
                                </div>
                            </div>
                            <h5>100:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p100 === 'enea5'} 
                                        onChange={e => setP100(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Gran parte de mis problemas con los demás provienen de mi susceptibilidad y de mi forma de tomarme las cosas demasiado personalmente.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea8" 
                                        checked={p100 === 'enea8'} 
                                        onChange={e => setP100(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Gran parte de mis problemas con los demás provienen de mi despreocupación sobre las formas sociales.
                                </p>
                                </div>
                            </div>
                            <h5>101:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p101 === 'enea6'} 
                                        onChange={e => setP101(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Me he caracterizado por apresurarme a rescatar a los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p101 === 'enea7'} 
                                        onChange={e => setP101(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Me he caracterizado por mostrar a los demás como ayudarse a sí mismos.
                                </p>
                                </div>
                            </div>
                            <h5>102:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea9" 
                                        checked={p102 === 'enea9'} 
                                        onChange={e => setP102(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Generalmente me ha gustado dejarme llevar y forzarme mas allá de los límites.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p102 === 'enea4'} 
                                        onChange={e => setP102(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Generalmente no me ha gustado perder demasiado el control.
                                </p>
                                </div>
                            </div>
                            <h5>103:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p103 === 'enea3'} 
                                        onChange={e => setP103(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Me he centrado demasiado en hacer las cosas mejor que los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p103 === 'enea1'} 
                                        onChange={e => setP103(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Me he centrado demasiado en hacer las cosas bien para los demás.
                                </p>
                                </div>
                            </div>
                            <h5>104:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p104 === 'enea8'} 
                                        onChange={e => setP104(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Normalmente mis pensamientos han sido especulativos, dando rienda suelta a mi imaginación y curiosidad.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea2" 
                                        checked={p104 === 'enea2'} 
                                        onChange={e => setP104(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Normalmente mis pensamientos han sido prácticos, intentando conseguir que las cosas sigan adelante.
                                </p>
                                </div>
                            </div>
                            <h5>105:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p105 === 'enea5'} 
                                        onChange={e => setP105(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Una de mis cualidades ha sido la habilidad para describir estados internos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p105 === 'enea7'} 
                                        onChange={e => setP105(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Una de mis cualidades ha sido la habilidad de hacerme cargo de las situaciones.
                                </p>
                                </div>
                            </div>  
                            <h5>106:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea4" 
                                        checked={p106 === 'enea4'} 
                                        onChange={e => setP106(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> He hecho lo necesario para que las cosas se hicieran correctamente, incluso cuando eso molestara a alguien.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p106 === 'enea1'} 
                                        onChange={e => setP106(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> No me ha gustado sentirme presionado, por lo que tampoco me he inclinado a presionar a nadie.
                                </p>
                                </div>
                            </div>
                            <h5>107:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p107 === 'enea6'} 
                                        onChange={e => setP107(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Me he sentido orgulloso de lo importante que soy en la vida de los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p107 === 'enea9'} 
                                        onChange={e => setP107(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Me he sentido orgulloso de mi gusto e inclinación hacia las experiencias nuevas.
                                </p>
                                </div>
                            </div>
                            <h5>108:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p108 === 'enea3'} 
                                        onChange={e => setP108(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Me he dado cuenta que a menudo doy a los demás la impresión de ser alguien importante, incluso admirable a veces.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea8" 
                                        checked={p108 === 'enea8'} 
                                        onChange={e => setP108(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Me he dado cuenta que a menudo doy a los demás la impresión de ser alguien poco corriente, a veces incluso algo excéntrico.
                                </p>
                                </div>
                            </div>
                            <h5>109:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p109 === 'enea2'} 
                                        onChange={e => setP109(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Habitualmente he hecho lo que tenia que hacer.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p109 === 'enea5'} 
                                        onChange={e => setP109(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Habitualmente he hecho lo que quería hacer.
                                </p>
                                </div>
                            </div>
                            <h5>110:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p110 === 'enea7'} 
                                        onChange={e => setP110(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Normalmente me han gustado las situaciones difíciles e incluso de alta tensión.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p110 === 'enea1'} 
                                        onChange={e => setP110(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Normalmente me ha disgustado encontrarme en situaciones difíciles y de alta tensión.
                                </p>
                                </div>
                            </div>
                            <h5>111:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p111 === 'enea3'} 
                                        onChange={e => setP111(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Me he sentido orgulloso de mi capacidad para ser flexible; lo importante o conveniente cambia con frecuencia.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p111 === 'enea4'} 
                                        onChange={e => setP111(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Me he sentido orgulloso de mantener mis puntos de vista; soy firme y congruente con lo que creo.
                                </p>
                                </div>
                            </div>
                            <h5>112:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p112 === 'enea8'} 
                                        onChange={e => setP112(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Mi estilo de vida se ha inclinado mas hacia la sobriedad y la austeridad.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p112 === 'enea9'} 
                                        onChange={e => setP112(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Mi estilo de vida se ha inclinado mas hacia el exceso y la hiperactividad.
                                </p>
                                </div>
                            </div>
                            <h5>113:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p113 === 'enea6'} 
                                        onChange={e => setP113(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Mi deseo de ayudar a los demás ha repercutido en mi propia salud y bienestar.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p113 === 'enea5'} 
                                        onChange={e => setP113(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Mi deseo de atender mis necesidades personales ha repercutido negativamente en mis relaciones.
                                </p>
                                </div>
                            </div>
                            <h5>114:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea1" 
                                        checked={p114 === 'enea1'} 
                                        onChange={e => setP114(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> En general he sido demasiado abierto e ingenuo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea2" 
                                        checked={p114 === 'enea2'} 
                                        onChange={e => setP114(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> En general he sido demasiado cauto y reservado.
                                </p>
                                </div>
                            </div>
                            <h5>115:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p115 === 'enea7'} 
                                        onChange={e => setP115(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> A veces he irritado a los demás al ser demasiado agresivo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p115 === 'enea4'} 
                                        onChange={e => setP115(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> A veces he irritado a los demás al ser demasiado severo y rígido.
                                </p>
                                </div>
                            </div>
                            <h5>116:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p116 === 'enea6'} 
                                        onChange={e => setP116(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Una de mis prioridades ha sido servir y atender las necesidades de los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea8" 
                                        checked={p116 === 'enea8'} 
                                        onChange={e => setP116(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Una de mis prioridades ha sido buscar formas alternativas de ver y hacer cosas.
                                </p>
                                </div>
                            </div>
                            <h5>117:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p117 === 'enea3'} 
                                        onChange={e => setP117(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    />Normalmente no me he alterado mucho.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p117 === 'enea9'} 
                                        onChange={e => setP117(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Normalmente he tenido fuertes cambios de estado de ánimo.
                                </p>
                                </div>
                            </div>
                            <h5>118:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p118 === 'enea5'} 
                                        onChange={e => setP118(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    />Me han atraído las situaciones que me producen emociones intensas y profundas.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p118 === 'enea1'} 
                                        onChange={e => setP118(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Me han atraído las situaciones que me hacen sentir tranquilo y cómodo.
                                </p>
                                </div>
                            </div>
                            <h5>119:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p119 === 'enea8'} 
                                        onChange={e => setP119(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    />Me he preocupado mas en seguir mis intereses que conseguir resultados prácticos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p119 === 'enea7'} 
                                        onChange={e => setP119(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Me he preocupado mas en conseguir resultados prácticos que seguir mis intereses.
                                </p>
                                </div>
                            </div>
                            <h5>120:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p120 === 'enea2'} 
                                        onChange={e => setP120(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    />He tenido una fuerte necesidad de pertenencia.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p120 === 'enea4'} 
                                        onChange={e => setP120(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> He tenido una fuerte necesidad de sentirme equilibrado.
                                </p>
                                </div>
                            </div>
                            <h5>121:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p121 === 'enea6'} 
                                        onChange={e => setP121(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    />En el pasado probablemente he reclamado demasiada cercanía en mis relaciones.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p121 === 'enea3'} 
                                        onChange={e => setP121(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> En el pasado probablemente he mantenido demasiada distancia en mis relaciones.
                                </p>
                                </div>
                            </div>
                            <h5>122:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p122 === 'enea5'} 
                                        onChange={e => setP122(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    />Con frecuencia me he inclinado a revivir los sentimientos del pasado.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p122 === 'enea9'} 
                                        onChange={e => setP122(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Con frecuencia he tenido la tendencia a anticipar cosas que iban a ocurrir.
                                </p>
                                </div>
                            </div>
                            <h5>123:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p123 === 'enea8'} 
                                        onChange={e => setP123(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    />Me he inclinado a ver que los demás son intrusivos y exigentes.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p123 === 'enea4'} 
                                        onChange={e => setP123(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Me he inclinado a ver que los demás son desorganizados e irresponsables.
                                </p>
                                </div>
                            </div>
                            <h5>124:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p124 === 'enea2'} 
                                        onChange={e => setP124(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    />Normalmente no he confiado demasiado en mi mismo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea7" 
                                        checked={p124 === 'enea7'} 
                                        onChange={e => setP124(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Normalmente solo he confiado en mi mismo.
                                </p>
                                </div>
                            </div>
                            <h5>125:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea1" 
                                        checked={p125 === 'enea1'} 
                                        onChange={e => setP125(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Probablemente he sido demasiado pasivo y poco comprometido.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea6" 
                                        checked={p125 === 'enea6'} 
                                        onChange={e => setP125(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Probablemente he sido demasiado controlador y manipulador.
                                </p>
                                </div>
                            </div>
                            <h5>126:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p126 === 'enea5'} 
                                        onChange={e => setP126(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Con frecuencia las dudas sobre mi mismo me han paralizado.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p126 === 'enea3'} 
                                        onChange={e => setP126(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Rara vez he dejado que las dudas se interpusieran en mi camino.
                                </p>
                                </div>
                            </div>
                            <h5>127:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea9" 
                                        checked={p127 === 'enea9'} 
                                        onChange={e => setP127(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Si se me da a elegir entre algo conocido y algo nuevo, tiendo a escoger lo nuevo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea2" 
                                        checked={p127 === 'enea2'} 
                                        onChange={e => setP127(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Normalmente prefiero lo que se que me gusta: ¿para qué probar algo que puede ser que no me guste?
                                </p>
                                </div>
                            </div>
                            <h5>128:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p128 === 'enea6'} 
                                        onChange={e => setP128(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Utilizo mucho contacto físico para reforzar lo que siento sobre la gente.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p128 === 'enea4'} 
                                        onChange={e => setP128(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Generalmente he sentido que el amor sincero no depende del contacto físico.
                                </p>
                                </div>
                            </div>
                            <h5>129:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p129 === 'enea7'} 
                                        onChange={e => setP129(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Cuando he necesitado enfrentarme a alguien, con frecuencia he sido demasiado directo y duro.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p129 === 'enea3'} 
                                        onChange={e => setP129(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Cuando he necesitado enfrentarme a alguien, con frecuencia me he ido demasiado por las ramas.
                                </p>
                                </div>
                            </div>
                            <h5>130:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p130 === 'enea8'} 
                                        onChange={e => setP130(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Me han atraído temas que a otras personas pudieran parecerles perturbadores e incluso amenazantes.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p130 === 'enea1'} 
                                        onChange={e => setP130(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> He preferido no gastar mi tiempo en temas perturbadores o amenazantes.
                                </p>
                                </div>
                            </div>
                            <h5>131:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p131 === 'enea6'} 
                                        onChange={e => setP131(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Ser tan intrusivo y entrometido me ha ocasionado problemas con los demás.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea2" 
                                        checked={p131 === 'enea2'} 
                                        onChange={e => setP131(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Ser tan evasivo e incomunicativo me ha ocasionado problemas con los demás.
                                </p>
                                </div>
                            </div>
                            <h5>132:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p132 === 'enea7'} 
                                        onChange={e => setP132(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Me ha preocupado no tener los recursos necesarios para satisfacer las responsabilidades que he asumido.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p132 === 'enea9'} 
                                        onChange={e => setP132(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Me ha preocupado no tener la disciplina necesaria para centrarme en aquello que realmente me llena.
                                </p>
                                </div>
                            </div>
                            <h5>133:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea5" 
                                        checked={p133 === 'enea5'} 
                                        onChange={e => setP133(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Generalmente he sido una persona individualista e intuitiva.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p133 === 'enea4'} 
                                        onChange={e => setP133(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Generalmente he sido una persona responsable y bien organizada.
                                </p>
                                </div>
                            </div>
                            <h5>134:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea1" 
                                        checked={p134 === 'enea1'} 
                                        onChange={e => setP134(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Uno de mis grandes problemas ha sido superar la inercia.

                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p134 === 'enea9'} 
                                        onChange={e => setP134(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Uno de mis grandes problemas ha sido verme incapaz de reducir mi ritmo.
                                </p>
                                </div>
                            </div>
                            <h5>135:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p135 === 'enea3'} 
                                        onChange={e => setP135(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Cuando me siento inseguro reacciono volviéndome arrogante y despreciativo.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea2" 
                                        checked={p135 === 'enea2'} 
                                        onChange={e => setP135(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Cuando me siento inseguro reacciono volviéndome discutidor y poniéndome a la defensiva.
                                </p>
                                </div>
                            </div>
                            <h5>136:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p136 === 'enea8'} 
                                        onChange={e => setP136(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Generalmente he sido abierto y dispuesto a probar enfoques nuevos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p136 === 'enea5'} 
                                        onChange={e => setP136(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Generalmente he estado dispuesto a ser auto-revelador y a compartir mis sentimientos con los demás.
                                </p>
                                </div>
                            </div>
                            <h5>137:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p137 === 'enea7'} 
                                        onChange={e => setP137(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> Me muestro ante los demás como una persona mas dura de lo que realmente soy.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea6" 
                                        checked={p137 === 'enea6'} 
                                        onChange={e => setP137(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> Me muestro ante los demás como una persona mas preocupada por ellos de lo que realmente soy.
                                </p>
                                </div>
                            </div>
                            <h5>138:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea4" 
                                        checked={p138 === 'enea4'} 
                                        onChange={e => setP138(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Normalmente me he dejado llevar por la conciencia y la razón.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p138 === 'enea9'} 
                                        onChange={e => setP138(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> Normalmente me he dejado llevar por mis sentimientos e impulsos.
                                </p>
                                </div>
                            </div>
                            <h5>139:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea3" 
                                        checked={p139 === 'enea3'} 
                                        onChange={e => setP139(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> La adversidad me ha hecho mas duro y resuelto.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea1" 
                                        checked={p139 === 'enea1'} 
                                        onChange={e => setP139(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> La adversidad me ha hecho sentirme mas resignado y desanimado.
                                </p>
                                </div>
                            </div>
                            <h5>140:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea2" 
                                        checked={p140 === 'enea2'} 
                                        onChange={e => setP140(e.target.value)}
                                        onClick={e => setEneatipoDos(eneatipodos + 1)}
                                    /> Normalmente me he asegurado de que tenia alguna especie de red de seguridad por si me caía.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea8" 
                                        checked={p140 === 'enea8'} 
                                        onChange={e => setP140(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Normalmente he preferido depender de lo menos posible.
                                </p>
                                </div>
                            </div>
                            <h5>141:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea7" 
                                        checked={p141 === 'enea7'} 
                                        onChange={e => setP141(e.target.value)}
                                        onClick={e => setEneatipoSiete(eneatiposiete + 1)}
                                    /> He tenido que ser fuerte para los demás, por lo que no he tenido tiempo para ver mis sentimientos y miedos.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea5" 
                                        checked={p141 === 'enea5'} 
                                        onChange={e => setP141(e.target.value)}
                                        onClick={e => setEneatipoCinco(eneatipocinco + 1)}
                                    /> Me ha costado mucho afrontar mis sentimientos y mis miedos, por lo que me resulta difícil ser fuerte para los demás.
                                </p>
                                </div>
                            </div>
                            <h5>142:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea1" 
                                        checked={p142 === 'enea1'} 
                                        onChange={e => setP142(e.target.value)}
                                        onClick={e => setEneatipoUno(eneatipouno + 1)}
                                    /> Muchas veces me he preguntado por que la gente se centra en lo negativo, cuando hay tantas cosas maravillosas en la vida.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea4" 
                                        checked={p142 === 'enea4'} 
                                        onChange={e => setP142(e.target.value)}
                                        onClick={e => setEneatipoCuatro(eneatipocuatro + 1)}
                                    /> Muchas veces me he preguntado por que la gente es tan alegre cuando tantas cosas de la vida van mal.
                                </p>
                                </div>
                            </div>
                            <h5>143:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea6" 
                                        checked={p143 === 'enea6'} 
                                        onChange={e => setP143(e.target.value)}
                                        onClick={e => setEneatipoSeis(eneatiposeis + 1)}
                                    /> He intentado que no se me vea como una persona egoísta.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea9" 
                                        checked={p143 === 'enea9'} 
                                        onChange={e => setP143(e.target.value)}
                                        onClick={e => setEneatipoNueve(eneatiponueve + 1)}
                                    /> He intentado que no se me vea como una persona aburrida.
                                </p>
                                </div>
                            </div>
                            <h5>144:</h5>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio"
                                        value="enea8" 
                                        checked={p144 === 'enea8'} 
                                        onChange={e => setP144(e.target.value)}
                                        onClick={e => setEneatipoOcho(eneatipoocho + 1)}
                                    /> Cuando he temido que las necesidades y demandas de los demás me superasen, he evitado la intimidad.
                                </p>
                                <p>
                                    <input 
                                        type="radio" 
                                        value="enea3" 
                                        checked={p144 === 'enea3'} 
                                        onChange={e => setP144(e.target.value)}
                                        onClick={e => setEneatipoTres(eneatipotres + 1)}
                                    /> Cuando he temido que no iba a poder cumplir las expectativas que se tenían de mi, he evitado la intimidad.
                                </p>
                                </div>
                            </div>
                                                              
                        <br/>

                        {
                        mensaje && (
                            <div className="alert alert-info">
                                {mensaje}
                                <br/>
                            </div>
                        )
                        }
                        
                        {
                        bloqueuno && (
                            <div>
                                <h4>Eneatipo 1: {eneatipouno}</h4>
                                <h5>{bloqueuno}</h5>
                                <p></p>
                            </div>     
                        )
                        }
                        {
                        bloquedos && (
                            <div>
                                <h4>Eneatipo 2: {eneatipodos}</h4>
                                <h5>{bloquedos}</h5>
                                <p></p>
                            </div>     
                        )
                        }
                        {
                        bloquetres && (
                            <div>
                                <h4>Eneatipo 3: {eneatipotres}</h4>
                                <h5>{bloquetres}</h5>
                                <p></p>
                            </div>     
                        )
                        }
                        {
                        bloquecuatro && (
                            <div>
                                <h4>Eneatipo 4: {eneatipocuatro}</h4>
                                <h5>{bloquecuatro}</h5>
                                <p></p>
                            </div>     
                        )
                        }
                        {
                        bloquecinco && (
                            <div>
                                <h4>Eneatipo 5: {eneatipocinco}</h4>
                                <h5>{bloquecinco}</h5>
                                <p></p>
                            </div>     
                        )
                        }
                        {
                        bloqueseis && (
                            <div>
                                <h4>Eneatipo 6: {eneatiposeis}</h4>
                                <h5>{bloqueseis}</h5>
                                <p></p>
                            </div>       
                        )
                        }
                        {
                        bloquesiete && (
                            <div>
                                <h4>Eneatipo 7: {eneatiposiete}</h4>
                                <h5>{bloquesiete}</h5>
                                <p></p>
                            </div>     
                        )
                        }
                        {
                        bloqueocho && (
                            <div>
                                <h4>Eneatipo 8: {eneatipoocho}</h4>
                                <h5>{bloqueocho}</h5>
                                <p></p>
                            </div>     
                        )
                        }
                        {
                        bloquenueve && (
                            <div>
                                <h4>Eneatipo 9: {eneatiponueve}</h4>
                                <h5>{bloquenueve}</h5>
                                <p></p>
                                <p className="peque">
                                Para conocer más sobre los Eneatipos ingresa en este link:<br/>
                                    <a href="https://sonria.com/eneagrama/" target="_blank">
                                        Devolución del Test
                                    </a>
                                </p>
                            </div>     
                        )
                        }
                        
                        <br/>
                        <div>
                        <button 
                        className='btn btn-dark btn-block mt-2'
                        type="submit"
                        >
                        Ver Resultado y Guardar Test
                        </button>
                        </div>
                        <br /><br />
                    </form>
                    </div>
                </div>
            </div> 
     </div>  
        </main>
    )
}

export default TestEneagrama