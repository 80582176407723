import React from 'react'
import {db} from '../firebase'
import Moment from 'react-moment';
import 'moment/locale/es'
import '../index.css';

const TestSalud = (props) => {

    const [tests, setTests] = React.useState([])
    const [tabaco, setTabaco] = React.useState('')
    const [vodka, setVodka] = React.useState('')
    const [vino, setVino] = React.useState('')
    const [drogas, setDrogas] = React.useState('')
    const [toxicos, setToxicos] = React.useState('')
    const [sedentarismo, setSedentarismo] = React.useState('')
    const [estres, setEstres] = React.useState('')
    const [cafe, setCafe] = React.useState('')
    const [sueno, setSueno] = React.useState('')
    const [estrenimento, setEstrenimento] = React.useState('')
    const [comerCarne, setComerCarne] = React.useState('')
    const [dulces, setDulces] = React.useState('')
    const [sobrepeso, setSobrepeso] = React.useState('')
    const [embutidos, setEmbutidos] = React.useState('')
    const [horasExtra, setHorasExtra] = React.useState('')
    const [sal, setSal] = React.useState('')
    const [hipertension, setHipertension] = React.useState('')
    const [macronutrientes, setMacronutrientes] = React.useState('')
    const [ultraprocesados, setUltraprocesados] = React.useState('')
    const [plastico, setPlastico] = React.useState('')
    const [insomnio, setInsomnio] = React.useState('')
    const [dependencia, setDependencia] = React.useState('')
    const [autoestima, setAutoestima] = React.useState('')
    const [sol, setSol] = React.useState('')
    const [vacunacion, setVacunacion] = React.useState('')
    const [dieta, setDieta] = React.useState('')
    const [bebidas, setBebidas] = React.useState('')
    const [protestar, setProtestar] = React.useState('')
    const [television, setTelevision] = React.useState('')
    const [siempreSi, setSiempreSi] = React.useState('')
    const [descontrol, setDescontrol] = React.useState('')
    const [postura, setPostura] = React.useState('')
    const [zumos, setZumos] = React.useState('')
    const [automedicacion, setAutomedicacion] = React.useState('')
    const [automocion, setAutomocion] = React.useState('')
    const [comerDeprisa, setComerDeprisa] = React.useState('')
    const [noOrar, setNoOrar] = React.useState('')

    const [ejercicio, setEjercicio] = React.useState('')
    const [cepillado, setCepillado] = React.useState('')
    const [ceplengua, setCeplengua] = React.useState('')
    const [ducha, setDucha] = React.useState('')
    const [higiene, setHigiene] = React.useState('')
    const [tareasHogar, setTareasHogar] = React.useState('')
    const [cama, setCama] = React.useState('')
    const [equilibrio, setEquilibrio] = React.useState('')
    const [sociales, setSociales] = React.useState('')
    const [sociedad, setSociedad] = React.useState('')
    const [frutas, setFrutas] = React.useState('')
    const [agua, setAgua] = React.useState('')
    const [descanso, setDescanso] = React.useState('')
    const [respiracion, setRespiracion] = React.useState('')
    const [pescado, setPescado] = React.useState('')
    const [comidas, setComidas] = React.useState('')
    const [sobrecarga, setSobrecarga] = React.useState('')
    const [revision, setRevision] = React.useState('')
    const [naturaleza, setNaturaleza] = React.useState('')
    const [glucosa, setGlucosa] = React.useState('')
    const [tono, setTono] = React.useState('')
    const [alimentos, setAlimentos] = React.useState('')
    const [ocio, setOcio] = React.useState('')
    const [bicicleta, setBicicleta] = React.useState('')
    const [paz, setPaz] = React.useState('')
    const [suplementos, setSuplementos] = React.useState('')
    const [focalizar, setFocalizar] = React.useState('')
    const [seguridad, setSeguridad] = React.useState('')
    const [deteccion, setDeteccion] = React.useState('')
    const [manos, setManos] = React.useState('')
    const [comer, setComer] = React.useState('')
    const [leer, setLeer] = React.useState('')
    const [abrazarse, setAbrazarse] = React.useState('')
    const [padres, setPadres] = React.useState('')
    const [hermanos, setHermanos] = React.useState('')
    const [visitar, setVisitar] = React.useState('')
    const [invitados, setInvitados] = React.useState('')
    const [huespedes, setHuespedes] = React.useState('')
    const [indigentes, setIndigentes] = React.useState('')
    const [propinas, setPropinas] = React.useState('')
    const [prepararComida, setPrepararComida] = React.useState('')
    const [fruta, setFruta] = React.useState('')
    const [plancha, setPlancha] = React.useState('')
    const [cenar, setCenar] = React.useState('')
    const [comerModerado, setComerModerado] = React.useState('')
    const [jugar, setJugar] = React.useState('')
    const [transeuntes, setTranseuntes] = React.useState('')
    const [musica, setMusica] = React.useState('')
    const [movil, setMovil] = React.useState('')
    const [id, setId] = React.useState('')
    const [mensaje, setMensaje] = React.useState('')
    const [suma, setSuma] = React.useState(0)  
    const [positivo, setPositivo] = React.useState(0) 
    const [resultado, setResultado] = React.useState('')
    const [devoluciona, setDevoluciona] = React.useState('') 
    const [devolucionb, setDevolucionb] = React.useState('')
    const [devolucionc, setDevolucionc] = React.useState('') 
    const [devoluciond, setDevoluciond] = React.useState('') 
    const [devolucione, setDevolucione] = React.useState('') 
    const [devolucionf, setDevolucionf] = React.useState('') 
    const [devoluciong, setDevoluciong] = React.useState('') 
    const [devolucionh, setDevolucionh] = React.useState('') 
    const [devolucioni, setDevolucioni] = React.useState('') 
    const [devolucionj, setDevolucionj] = React.useState('')
    const [devolucionaa, setDevolucionaa] = React.useState('') 
    const [devolucionbb, setDevolucionbb] = React.useState('')
    const [devolucioncc, setDevolucioncc] = React.useState('') 
    const [devoluciondd, setDevoluciondd] = React.useState('') 
    const [devolucionee, setDevolucionee] = React.useState('') 
    const [devolucionff, setDevolucionff] = React.useState('') 
    const [devoluciongg, setDevoluciongg] = React.useState('') 
    const [devolucionhh, setDevolucionhh] = React.useState('') 
    const [devolucionii, setDevolucionii] = React.useState('') 
    const [devolucionjj, setDevolucionjj] = React.useState('')    

  
    React.useEffect(() => {
  
      const obtenerDatos = async () => {
  
        try {

            if (resultado <= -24) {
                setDevolucionaa('Consulta con Nuestro Coordinador Académico.')
            }
            else if ((resultado <= -16) && (resultado > -24)) {
                setDevolucionbb('Consulta con Nuestro Coordinador Académico.')
            }
            else if ((resultado <= -8) && (resultado >= -15)) {
                setDevolucioncc(`Tu cuerpo no tiene repuesto y tu vida es una sola. No estás 
                preservando tu salud como es necesario si tu objetivo es vivir muchos años y vivirlos
                bien, aquí van algunas sugerencias: 1 - Declara un basta a los hábitos que te han 
                venido teniendo hasta aquí, haz un cambio concreto comenzando por declararlo 
                detalladamente y luego avanzá. 2 - Consultá ya mismo a nuestro médico virtual para 
                que te indique que proceso necesitas llevar a cabo. Una vez sostenido por un lapso de 
                6 meses te volveremos a solicitar un test para conocer los avances.`)
            }
            else if ((resultado <= -1) && (resultado >= -7)) {
                setDevoluciondd(`Tu cuerpo no tiene repuesto y tu vida es una sola. 
                No estás preservando tu salud como es necesario si tu objetivo es vivir muchos años 
                y vivirlos bien, aquí van algunas sugerencias: 1 - Declara un basta a los hábitos 
                que te han venido teniendo hasta aquí, haz un cambio concreto comenzando por 
                declararlo detalladamente y luego avanzá. 2 - Consultá ya mismo a nuestro médico 
                virtual para que te indique que proceso necesitas llevar a cabo. Una vez sostenido 
                por un lapso de 6 meses te volveremos a solicitar un test para conocer los avances.`)
            }
            else if ((resultado >= 0) && (resultado <= 7)) {
                setDevolucionee(`Lamentamos comunicarte que no estás preservando tu salud en el 
                estandar apropiado. Si tu objetivo es vivir muchos años y vivirlos bien, aquí van
                algunas sugerencias: 1 - Declara un basta a los hábitos que te han venido teniendo
                hasta aquí, haz un cambio concreto comenzando por declararlo detalladamente y 
                luego avanza. Una vez sostenido por un nivel completo te será más fácil sostenerlo
                e ir por otros. 2 - Haz ya mismo una consulta virtual a nuestra médico para recibir
                asistencia en cómo establecer un plan de vida saludable cuanto antes.`)
            }
            else if ((resultado >= 8) && (resultado <= 15)) {
                setDevolucionff(`Lamentamos comunicarte que no estás preservando tu salud en el estandar apropiado. Si tu objetivo es vivir muchos 
                años y vivirlos bien, aquí van algunas sugerencias: 1 - Declara un basta a los hábitos que te han venido teniendo
                hasta aquí, haz un cambio concreto comenzando por declararlo detalladamente y 
                luego avanza. Una vez sostenido por un nivel completo te será más fácil 
                sostenerlo e ir por otros. 2 - Haz ya mismo una consulta virtual a nuestra 
                médico para recibir asistencia en cómo establecer un plan de vida saludable 
                cuanto antes.`)
            }
            else if ((resultado >= 16) && (resultado <= 23)) {
                setDevoluciongg(`Si bien has desarrollado un estándar de vida saludable, puedes encontrar como superarte. Revisá los ítems 
                en los que obtuviste puntaje negativo, identifica las debilidades, reviértelas buscando información al respecto y adopta algunas de las sugerencias de 
                nuestros decálogos que te ayudan a cuidar la salud.`)
            }
            else if ((resultado >= 24) && (resultado <= 30)) {
                setDevolucionhh(`Si bien has desarrollado un estándar de vida saludable, puedes encontrar como superarte. Revisá los ítems 
                en los que obtuviste puntaje negativo, identifica las debilidades, reviértelas buscando información al respecto y adopta algunas de las sugerencias de 
                nuestros decálogos que te ayudan a cuidar la salud.`)
            }
            else if ((resultado >= 31) && (resultado <= 43)) {
                setDevolucionii(`Tenés una calidad de vida realmente saludable. Conservá tus hábitos bservando si hay alguno negativo que 
                puedes desactivar y recuerda que a medida que pasan los años deberás adaptar los cuidados e intensificarlos. El modo de lograrlo es continuar atento/a 
                a tus necesidades y mantener un estrecho contacto con tu cuerpo.`)
            }
            else if (resultado >= 44) {
                setDevolucionjj(`Tienes una calidad de vida realmente saludable. Conservá tus hábitos bservando si hay alguno negativo que 
                puedes desactivar y recuerda que a medida que pasan los años deberás adaptar los cuidados e intensificarlos. El modo de lograrlo es continuar atento/a 
                a tus necesidades y mantener un estrecho contacto con tu cuerpo.`)
            }
  
          const data = await db.collection("testSalud:" + props.user.email).get()
          const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          console.log(arrayData)
          setTests(arrayData)
          
        } catch (error) {
          console.log(error)
        }
  
      }
  
      obtenerDatos()
      setMensaje('')
  
    }, [props.user.email])
  
    const agregar = async (e) => {
      e.preventDefault()
      
      try {
  
        const nuevoTest = {
          tabaco: tabaco,
          vodka: vodka,
          vino: vino,
          drogas: drogas,
          toxicos: toxicos,
          sedentarismo: sedentarismo,
          estres: estres,
          cafe: cafe,
          sueno: sueno,
          estrenimento: estrenimento,
          comerCarne: comerCarne,
          dulces: dulces,
          sobrepeso: sobrepeso,
          embutidos: embutidos,
          horasExtra: horasExtra,
          sal: sal,
          hipertension: hipertension,
          macronutrientes: macronutrientes,
          ultraprocesados: ultraprocesados,
          plastico: plastico,
          insomnio: insomnio,
          dependencia: dependencia,
          autoestima: autoestima,
          sol: sol,
          vacunacion: vacunacion,
          dieta: dieta,
          bebidas: bebidas,
          protestar: protestar,
          television: television,
          siempreSi: siempreSi,
          descontrol: descontrol,
          postura: postura,
          zumos: zumos,
          automedicacion: automedicacion,
          automocion: automocion,
          comerDeprisa: comerDeprisa,
          noOrar: noOrar,
          
          ejercicio: ejercicio,
          cepillado: cepillado,
          ceplengua: ceplengua,
          ducha: ducha,
          higiene: higiene,
          tareasHogar: tareasHogar,
          cama: cama,
          equilibrio: equilibrio,
          sociales: sociales,
          sociedad: sociedad,
          frutas: frutas,
          agua: agua,
          descanso: descanso,
          respiracion: respiracion,
          pescado: pescado,
          comidas: comidas,
          sobrecarga: sobrecarga,
          revision: revision,
          naturaleza: naturaleza,
          glucosa: glucosa,
          tono: tono,
          alimentos: alimentos,
          ocio: ocio,
          bicicleta: bicicleta,
          paz: paz,
          suplementos: suplementos,
          focalizar: focalizar,
          manos: manos,
          seguridad: seguridad,
          deteccion: deteccion,
          comer: comer,
          leer: leer,
          abrazarse: abrazarse,
          padres: padres,
          hermanos: hermanos,
          visitar: visitar,
          invitados: invitados,
          huespedes: huespedes,
          indigentes: indigentes,
          propinas: propinas,
          prepararComida: prepararComida,
          fruta: fruta,
          plancha: plancha,
          cenar: cenar,
          comerModerado: comerModerado,
          jugar: jugar,
          transeuntes: transeuntes,
          musica: musica,
          movil: movil,
          suma: suma,
          positivo: positivo,
          resultado: suma + positivo,
          devolucionaa: devolucionaa,
          devolucionbb: devolucionbb,
          devolucioncc: devolucioncc,
          devoluciondd: devoluciondd,
          devolucionee: devolucionee,
          devolucionff: devolucionff,
          devoluciongg: devoluciongg,
          devolucionhh: devolucionhh,
          devolucionii: devolucionii,
          devolucionjj: devolucionjj,
          fecha: Date.now()
        }
        setResultado (suma + positivo)

        if ((suma + positivo) <= -24) {
            setDevoluciona('Consulta con Nuestro Coordinador Académico.')
        }
        else if (((suma + positivo) <= -16) && ((suma + positivo) > -24)) {
            setDevolucionb('Consulta con Nuestro Coordinador Académico.')
        }
        else if (((suma + positivo) <= -8) && ((suma + positivo) >= -15)) {
            setDevolucionc(`Tu cuerpo no tiene repuesto y tu vida es una sola. No estás 
            preservando tu salud como es necesario si tu objetivo es vivir muchos años y vivirlos bien, aquí van algunas sugerencias: `)
        }
        else if (((suma + positivo) <= -1) && ((suma + positivo) >= -7)) {
            setDevoluciond(`Tu cuerpo no tiene repuesto y tu vida es una sola. 
            No estás preservando tu salud como es necesario si tu objetivo es vivir muchos años y vivirlos bien, aquí van algunas sugerencias: `)
        }
        else if (((suma + positivo) >= 0) && ((suma + positivo) <= 7)) {
            setDevolucione(`Lamentamos comunicarte que no estás preservando tu salud en el estandar apropiado. Si tu objetivo es vivir muchos 
            años y vivirlos bien, aquí van algunas sugerencias: `)
        }
        else if (((suma + positivo) >= 8) && ((suma + positivo) <= 15)) {
            setDevolucionf(`Lamentamos comunicarte que no estás preservando tu salud en el 
            estandar apropiado. Si tu objetivo es vivir muchos años y vivirlos bien, aquí van 
            algunas sugerencias: `)
        }
        else if (((suma + positivo) >= 16) && ((suma + positivo) <= 23)) {
            setDevoluciong(`Si bien has desarrollado un estándar de vida saludable, puedes encontrar como superarte. Revisá los ítems 
            en los que obtuviste puntaje negativo, identifica las debilidades, reviértelas buscando información al respecto y adopta algunas de las sugerencias de 
            nuestros decálogos que te ayudan a cuidar la salud.`)
        }
        else if (((suma + positivo) >= 24) && ((suma + positivo) <= 30)) {
            setDevolucionh(`Si bien has desarrollado un estándar de vida saludable, puedes encontrar como superarte. Revisá los ítems 
            en los que obtuviste puntaje negativo, identifica las debilidades, reviértelas buscando información al respecto y adopta algunas de las sugerencias de 
            nuestros decálogos que te ayudan a cuidar la salud.`)
        }
        else if (((suma + positivo) >= 31) && ((suma + positivo) <= 43)) {
            setDevolucioni(`Tenés una calidad de vida realmente saludable. Conservá tus hábitos bservando si hay alguno negativo que 
            puedes desactivar y recuerda que a medida que pasan los años deberás adaptar los cuidados e intensificarlos. El modo de lograrlo es continuar atento/a 
            a tus necesidades y mantener un estrecho contacto con tu cuerpo.`)
        }
        else if ((suma + positivo) >= 44) {
            setDevolucionj(`Tienes una calidad de vida realmente saludable. Conservá tus hábitos bservando si hay alguno negativo que 
            puedes desactivar y recuerda que a medida que pasan los años deberás adaptar los cuidados e intensificarlos. El modo de lograrlo es continuar atento/a 
            a tus necesidades y mantener un estrecho contacto con tu cuerpo.`)
        }

        const data = await db.collection("testSalud:" + props.user.email).add(nuevoTest)
  
        setTests([
          ...tests,
          {...nuevoTest, id: data.id}
        ])
        
        setMensaje('El Test de Salud fue guardado correctamente.')

        
      } catch (error) {
        console.log(error)
      }
  
      console.log("Negativos: " + suma)
      console.log("Positivos: " + positivo)
      console.log("Resultado: " + (suma + positivo))
    }
      


    return (
        <main>
            <div className="contenedor">
        
            <div>
            <br/>
            <h5>Test de hábitos de Salud</h5>
            Repasá algunos hábitos cotidianos. Respondé con honestidad y descubrí cuán sano es 
            tu estilo de vida. Los resultados pueden ayudarte a seguir así o a impulsar cambios 
            cuanto antes.
            <br/><br/>
                    <ul className="lista">
                        {
                        tests.map(item => (         
                            <li key={item.id}>
                              <a className="btn btn-light" 
                                 data-toggle="collapse" 
                                 href={"#"+item.id} 
                                 role="button" 
                                 aria-expanded="false" 
                                 aria-controls="collapseExample">
                                   Fecha y Hora: <Moment format="DD-MM-YYYY">{item.fecha}</Moment>
                              </a>
                              <br /> 
                            <div className="collapse" id={item.id}>
                            <div className="card card-body">
                                Resultado: {item.resultado} <br />
                                {item.devolucionaa}
                                {item.devolucionbb}
                                {item.devolucioncc}
                                {item.devoluciondd}
                                {item.devolucionee}
                                {item.devolucionff}
                                {item.devoluciongg}
                                {item.devolucionhh}
                                {item.devolucionii}
                                {item.devolucionjj}
                            </div>
                            </div>
                            </li>
                        ))
                        }
                    </ul>
                    
        </div>
        <div>
                    <br/>
                    <h5>
                    <a className="btn btn-secondary" 
                       data-toggle="collapse" 
                       href="#collapseForm" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapseForm">
                          Realizar Test
                    </a>              
                    </h5>
                    <br/>
                    <div className="collapse" id="collapseForm">
                    <div className="card card-body">
        
                    <form onSubmit={agregar}>
                        <p>Tabaco (N° de cigarrillos por día):</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={tabaco === '0'} 
                                        onChange={e => setTabaco(e.target.value)}
                                    />
                                    0
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1 a 5" 
                                        checked={tabaco === '1 a 5'} 
                                        onChange={e => setTabaco(e.target.value)}
                                        onClick={e => setSuma(suma - 1)}
                                    />
                                    1 a 5
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="6 a 10" 
                                        checked={tabaco === '6 a 10'} 
                                        onChange={e => setTabaco(e.target.value)}
                                        onClick={e => setSuma(suma - 2)}
                                    />
                                    6 a 10
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="11 a 20" 
                                        checked={tabaco === '11 a 20'} 
                                        onChange={e => setTabaco(e.target.value)}
                                        onClick={e => setSuma(suma - 3)}
                                    />
                                    11 a 20
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="+ de 20" 
                                        checked={tabaco === '+ de 20'} 
                                        onChange={e => setTabaco(e.target.value)}
                                        onClick={e => setSuma(suma - 4)}
                                    />
                                    + de 20
                                </p>
                                </div>
                            </div>
                            <p>Vodka, wisky, etc. (dosis por semana):</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={vodka === '0'} 
                                        onChange={e => setVodka(e.target.value)}
                                    />
                                    0
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1 a 5" 
                                        checked={vodka === '1 a 5'} 
                                        onChange={e => setVodka(e.target.value)}
                                        onClick={e => setSuma(suma - 1)}
                                    />
                                    1 a 5
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="6 a 10" 
                                        checked={vodka === '6 a 10'} 
                                        onChange={e => setVodka(e.target.value)}
                                        onClick={e => setSuma(suma - 2)}
                                    />
                                    6 a 10
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="11 a 20" 
                                        checked={vodka === '11 a 20'} 
                                        onChange={e => setVodka(e.target.value)}
                                        onClick={e => setSuma(suma - 3)}
                                    />
                                    11 a 20
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="+ de 20" 
                                        checked={vodka === '+ de 20'} 
                                        onChange={e => setVodka(e.target.value)}
                                        onClick={e => setSuma(suma - 4)}
                                    />
                                    + de 20
                                </p>
                            </div> 
                        </div>   
                        <p>Vino y Cerveza (vasos por semana):</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={vino === '0'} 
                                        onChange={e => setVino(e.target.value)}
                                    />
                                    0
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1 a 5" 
                                        checked={vino === '1 a 5'} 
                                        onChange={e => setVino(e.target.value)}
                                        onClick={e => setSuma(suma - 1)}
                                    />
                                    1 a 5
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="6 a 10" 
                                        checked={vino === '6 a 10'} 
                                        onChange={e => setVino(e.target.value)}
                                        onClick={e => setSuma(suma - 2)}
                                    />
                                    6 a 10
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="11 a 20" 
                                        checked={vino === '11 a 20'} 
                                        onChange={e => setVino(e.target.value)}
                                        onClick={e => setSuma(suma - 3)}
                                    />
                                    11 a 20
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="+ de 20" 
                                        checked={vino === '+ de 20'} 
                                        onChange={e => setVino(e.target.value)}
                                        onClick={e => setSuma(suma - 4)}
                                    />
                                    + de 20
                                </p>
                            </div> 
                        </div>
                        <p>Drogas (dosis por semana):</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={drogas === '0'} 
                                        onChange={e => setDrogas(e.target.value)}
                                    />
                                    0
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1 a 5" 
                                        checked={drogas === '1 a 5'} 
                                        onChange={e => setDrogas(e.target.value)}
                                        onClick={e => setSuma(suma - 1)}
                                    />
                                    1 a 5
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="6 a 10" 
                                        checked={drogas === '6 a 10'} 
                                        onChange={e => setDrogas(e.target.value)}
                                        onClick={e => setSuma(suma - 2)}
                                    />
                                    6 a 10
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="11 a 20" 
                                        checked={drogas === '11 a 20'} 
                                        onChange={e => setDrogas(e.target.value)}
                                        onClick={e => setSuma(suma - 3)}
                                    />
                                    11 a 20
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="+ de 20" 
                                        checked={drogas === '+ de 20'} 
                                        onChange={e => setDrogas(e.target.value)}
                                        onClick={e => setSuma(suma - 4)}
                                    />
                                    + de 20
                                </p>
                            </div> 
                        </div>
                        <br />
                                <input 
                                type="checkbox"
                                onChange={e => setToxicos("Presentes")}
                                onClick={e => setSuma(suma - 1)}
                                /> Tóxicos ambientales (cosméticos, humos, aerosoles, moho en 
                                    el hogar) 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setSedentarismo("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Sedentarismo (progreso gradual a evitarlo) 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setEstres("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Estrés, Ansiedad, Depresión 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setCafe("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Café, Te, Mate (mas de 4 al día) 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setSueno("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Menos de 7 horas de sueño 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setEstrenimento("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Estreñimento y menos de 1 deposición por día 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setComerCarne("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Comer carne más de 4 veces por semana 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setDulces("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Dulces, bollos, pasteles: más de 3 por semana 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setSobrepeso("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Sobrepeso (ver *1 fórmula Molins) 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setEmbutidos("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Embutidos, margarinas, grasas hidrogenadas 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setHorasExtra("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Horas extra de trabajo 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setSal("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Exceso de sal en comidas 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setHipertension("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Hipertensión 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setMacronutrientes("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Eliminar macronutrientes en dieta (ver *2) 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setUltraprocesados("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Comidas ultraprocesadas 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setPlastico("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Productos en envase de plástico 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setInsomnio("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Insomnio 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setDependencia("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Dependencia emocional (pareja, hijos, reconocimiento) 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setAutoestima("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Baja autoestima 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setSol("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Sol: déficit/sin fotoprotección 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setVacunacion("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Evitar calendario de vacunación 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setDieta("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Dieta monótona sin estructurar 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setBebidas("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Beber bebidas azucaradas 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setProtestar("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Protestar / Quejarse / Criticar 
                        <br/><br/>
                        <p>Ver televisión (Horas por día):</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={television === '0'} 
                                        onChange={e => setTelevision(e.target.value)}
                                    />
                                    0
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1 a 5" 
                                        checked={television === '1 a 5'} 
                                        onChange={e => setTelevision(e.target.value)}
                                        onClick={e => setSuma(suma - 1)}
                                    />
                                    1 a 5
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="6 a 10" 
                                        checked={television === '6 a 10'} 
                                        onChange={e => setTelevision(e.target.value)}
                                        onClick={e => setSuma(suma - 2)}
                                    />
                                    6 a 10
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="11 a 20" 
                                        checked={television === '11 a 20'} 
                                        onChange={e => setTelevision(e.target.value)}
                                        onClick={e => setSuma(suma - 3)}
                                    />
                                    11 a 20
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="+ de 20" 
                                        checked={television === '+ de 20'} 
                                        onChange={e => setTelevision(e.target.value)}
                                        onClick={e => setSuma(suma - 4)}
                                    />
                                    + de 20
                                </p>
                            </div> 
                        </div>
                        <br/>
                        <input 
                                type="checkbox"
                                onChange={e => setSiempreSi("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Decir siempre que si (Aprende el "No") 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setDescontrol("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Descontrol horario 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setPostura("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Desajustes posturales (malas posturas indican una 
                                actitud muchas veces negativa) 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setZumos("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Zumos de fruta (evitarlos y cambiar por fruta entera) 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setAutomedicacion("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Automedicacion 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setAutomocion("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Automocion (usar vehiculo en lugar de caminar, ir en bicicleta) 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setComerDeprisa("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> Comer deprisa, deglutir sin masticar 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setNoOrar("Presente")}
                                onClick={e => setSuma(suma - 1)}
                                /> No reflexionar, meditar, orar 
                        <br/><br/>
                        <p className="peque">1* Fórmula Molins<br/>
                        Peso ideal mujer: Altura - el metro. Restar un 10%. Sumar 1Kg por 
                        cada década que pasa de 20. Dar un margen de +/- 2Kg en función de 
                        su corpulencia. Peso ideal Varón: Misma fórmula sin quitar el 10%.</p>

                        <p className="peque">2* Eliminar macronutrintes en dieta.<br/>
                        Me refiero a dietas muy restrictivas en las que eliminamos grasas o
                        hidratos de carbono. También los veganos que evitar proteínas 
                        animales.</p>
                        <br/>
                        Usted ha completado de revisar una lista de los hábitos negativos más comunes
                        que atentan contra nuestra salud.<br/><br/>
                        
                        <h5>Resultado 1ra etapa: {suma}</h5>
                        <br/>
                        Ahora repase una lista de hábitos que pueden asistirnos a recuperar
                        la salud. Responda con honestidad a cada uno de ellos y si le quedan
                        dudas no lo marque.
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setEjercicio("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Ejercicio cardiovascular 30' por día 
                        <br/><br/>
                        <p>Cepillado dental:</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={cepillado === '0'} 
                                        onChange={e => setCepillado(e.target.value)}
                                    />
                                    0
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1 vez" 
                                        checked={cepillado === '1 vez'} 
                                        onChange={e => setCepillado(e.target.value)}
                                        onClick={e => setPositivo(positivo + 1)}
                                    />
                                    1 vez
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2 veces" 
                                        checked={cepillado === '2 veces'} 
                                        onChange={e => setCepillado(e.target.value)}
                                        onClick={e => setPositivo(positivo + 2)}
                                    />
                                    2 veces
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3 o +" 
                                        checked={cepillado === '3 o +'} 
                                        onChange={e => setCepillado(e.target.value)}
                                        onClick={e => setPositivo(positivo + 3)}
                                    />
                                    3 o +
                                </p>
                            </div>
                        </div>
                        <br/>
                        <input 
                                type="checkbox"
                                onChange={e => setCeplengua("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Cepillado de lengua 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setDucha("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Ducha diaria 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setHigiene("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Higiene en el hogar 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setTareasHogar("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Ayudas en las tareas del hogar 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setCama("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Haces tu propia cama 
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setEquilibrio("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Equilibrio mental, Emocional y Cognitivo. Sensacion de paz interior.  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setSociales("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Actividades Sociales (1 vez por semana)  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setSociedad("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Realizar acciones solidarias (Ej: colaboración con la sociedad)  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setFrutas("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Comer 3 a 5 frutas o verduras por día  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setAgua("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Más de 1/2 litro de agua por día (no otros líquidos)  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setDescanso("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> 1 o más días de descanso semanal  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setRespiracion("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Respiración consciente a diario  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setPescado("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Comer pescado 2 veces por semana  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setComidas("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Horario regular de comidas  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setSobrecarga("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Gestión del "surmenage"/sobrecarga  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setRevision("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Revisión médica anual  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setNaturaleza("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Contacto con la naturaleza  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setGlucosa("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Control de glucosa y colesterol anual  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setTono("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Ejercicio de tono (3 veces por semana)  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setAlimentos("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Alimentos ricos en fibra  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setOcio("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Tiempo de ocio/diversión/risa  
                        <br/><br/>
                        <p>Usas bicicleta para movilizarte (veces por semana):</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="0" 
                                        checked={bicicleta === '0'} 
                                        onChange={e => setBicicleta(e.target.value)}
                                    />
                                    0
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1 vez" 
                                        checked={bicicleta === '1 vez'} 
                                        onChange={e => setBicicleta(e.target.value)}
                                        onClick={e => setPositivo(positivo + 1)}
                                    />
                                    1 vez
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2 veces" 
                                        checked={bicicleta === '2 veces'} 
                                        onChange={e => setBicicleta(e.target.value)}
                                        onClick={e => setPositivo(positivo + 2)}
                                    />
                                    2 veces
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3 veces" 
                                        checked={bicicleta === '3 veces'} 
                                        onChange={e => setBicicleta(e.target.value)}
                                        onClick={e => setPositivo(positivo + 3)}
                                    />
                                    3 veces
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="4 o +" 
                                        checked={bicicleta === '4 o +'} 
                                        onChange={e => setBicicleta(e.target.value)}
                                        onClick={e => setPositivo(positivo + 4)}
                                    />
                                    4 o +
                                </p>
                            </div>
                        </div>
                        <br/>
                        <input 
                                type="checkbox"
                                onChange={e => setPaz("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Paz espiritual  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setSuplementos("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Suplementación vitamino/mineral  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setFocalizar("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Soñar y focalizar/proyectar  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setSeguridad("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Seguridad laboral (ver *3)  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setDeteccion("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Programas de detección del cáncer  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setManos("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Lavado de manos frecuente  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setComer("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Comer en plato pequeño  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setLeer("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Leer más de 30 min por día  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setAbrazarse("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Abrazarse por mas de 15 seg por día  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setPadres("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Tienes buena relación con tus padres  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setHermanos("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Tienes buena relación con todos tus hermanos  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setVisitar("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Visitas a tus familiares y amigos cuando están internados  
                        <br/><br/>
                        <p>Recibes invitados para comer en tu casa (veces por semana):</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Nunca" 
                                        checked={invitados === 'Nunca'} 
                                        onChange={e => setInvitados(e.target.value)}
                                    />
                                    Nunca
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1 vez" 
                                        checked={invitados === '1 vez'} 
                                        onChange={e => setInvitados(e.target.value)}
                                        onClick={e => setPositivo(positivo + 1)}
                                    />
                                    1 vez
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2 veces" 
                                        checked={invitados === '2 veces'} 
                                        onChange={e => setInvitados(e.target.value)}
                                        onClick={e => setPositivo(positivo + 2)}
                                    />
                                    2 veces
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3 veces" 
                                        checked={invitados === '3 veces'} 
                                        onChange={e => setInvitados(e.target.value)}
                                        onClick={e => setPositivo(positivo + 3)}
                                    />
                                    3 veces
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="4 o +" 
                                        checked={invitados === '4 o +'} 
                                        onChange={e => setInvitados(e.target.value)}
                                        onClick={e => setPositivo(positivo + 4)}
                                    />
                                    4 o +
                                </p>
                            </div>
                        </div>
                        <br/>
                        <p>Recibes huéspedes para dormir en tu casa (veces por semana):</p>
                            <div className="radio">
                                <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="Nunca" 
                                        checked={huespedes === 'Nunca'} 
                                        onChange={e => setHuespedes(e.target.value)}
                                    />
                                    Nunca
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="1 vez" 
                                        checked={huespedes === '1 vez'} 
                                        onChange={e => setHuespedes(e.target.value)}
                                        onClick={e => setPositivo(positivo + 1)}
                                    />
                                    1 vez
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="2 veces" 
                                        checked={huespedes === '2 veces'} 
                                        onChange={e => setHuespedes(e.target.value)}
                                        onClick={e => setPositivo(positivo + 2)}
                                    />
                                    2 veces
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="3 veces" 
                                        checked={huespedes === '3 veces'} 
                                        onChange={e => setHuespedes(e.target.value)}
                                        onClick={e => setPositivo(positivo + 3)}
                                    />
                                    3 veces
                                </p>
                            </div>
                            <div className="radioin">
                                <p>
                                    <input 
                                        type="radio" 
                                        value="4 o +" 
                                        checked={huespedes === '4 o +'} 
                                        onChange={e => setHuespedes(e.target.value)}
                                        onClick={e => setPositivo(positivo + 4)}
                                    />
                                    4 o +
                                </p>
                            </div>
                        </div>
                        <br/>
                        <input 
                                type="checkbox"
                                onChange={e => setIndigentes("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Ayudas económicamente a indigentes  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setPropinas("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Eres generoso en tus propinas  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setPrepararComida("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Preparas tu propia comida  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setFruta("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Fruta de temporada y proximidad  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setPlancha("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Cocinar en plancha, vapor, horno, wok  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setCenar("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Cenar temprano (estilo Europa 7:30 - 8 de la tarde/noche)  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setComerModerado("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Comer cantidades moderadas  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setJugar("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Juegas con niños  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setTranseuntes("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Orientas a transeúntes  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setMusica("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Escuchar musica/bailar  
                        <br/><br/>
                        <input 
                                type="checkbox"
                                onChange={e => setMovil("Presente")}
                                onClick={e => setPositivo(positivo + 1)}
                                /> Apartar el móvil durante el sueño  
                        <br/><br/>
                        <p className="peque">3* Seguridad laboral<br/>
                        Me refiero a que las condiciones del lugar de trabajo, del entorno 
                        laboral, de los compañeros de trabajo, etc., lo hacen agradable.</p>
                        <br/>
                        <h5>Resultado 2da etapa: {positivo}</h5>
  
                        {
                        mensaje && (
                            <div className="alert alert-info">
                                {mensaje}
                                <br/>
                            </div>
                        )
                        }
                        {
                        resultado && (
                            <div className="alert alert-success">
                                <h5>Resultado del Test: {resultado}</h5>
                                <br/>
                            </div>
                        )
                        }
                        {
                        devoluciona && (
                            <div className="alert alert-light">
                                <img align="right" src='https://www.sonria.com/test/salud/img/00.jpg'></img>
                                {devoluciona}
                            </div>     
                        )
                        }
                        {
                        devolucionb && (
                            <div className="alert alert-light">
                                <img align="right" src='https://www.sonria.com/test/salud/img/0.jpg'></img>
                                {devolucionb}
                            </div>       
                        )
                        }
                        {
                        devolucionc && (
                            <div className="alert alert-light">
                                <img align="right" src='https://www.sonria.com/test/salud/img/1.jpg'></img>
                                {devolucionc}<br/>
                                1 - Declara un basta a los hábitos que te han venido teniendo hasta aquí, haz un
                                cambio concreto comenzando por declararlo detalladamente y luego avanzá.<br/> 
                                2 - Consultá ya mismo a nuestro médico virtual para que te indique que proceso 
                                necesitas llevar a cabo. <br/>
                                Una vez sostenido por un lapso de 6 meses te volveremos a solicitar un test 
                                para conocer los avances.
                            </div>       
                        )
                        }
                        {
                        devoluciond && (
                            <div className="alert alert-light">
                                <img align="right" src='https://www.sonria.com/test/salud/img/1-1.jpg'></img>
                                {devoluciond}<br/>
                                1 - Declara un basta a los hábitos que te han venido teniendo hasta aquí, haz un 
                                cambio concreto comenzando por declararlo detalladamente y luego avanzá. <br/>
                                2 - Consultá ya mismo a nuestro médico virtual para que te indique que proceso 
                                necesitas llevar a cabo. <br/>
                                Una vez sostenido por un lapso de 6 meses te volveremos a solicitar un test para 
                                conocer los avances.
                            </div>       
                        )
                        }
                        {
                        devolucione && (
                            <div className="alert alert-light">
                                <img align="right" src='https://www.sonria.com/test/salud/img/2.jpg'></img>
                                {devolucione}<br/>
                                1 - Declara un basta a los hábitos que te han venido teniendo hasta aquí,
                                haz un cambio concreto comenzando por declararlo detalladamente y luego 
                                avanzá. <br/>
                                Una vez sostenido por un nivel completo te será más fácil sostenerlo e ir 
                                por otros. <br/>
                                2 - Haz ya mismo una consulta virtual a nuestra médico para recibir 
                                asistencia en cómo establecer un plan de vida saludable cuanto antes.
                            </div>       
                        )
                        }
                        {
                        devolucionf && (
                            <div className="alert alert-light">
                                <img align="right" src='https://www.sonria.com/test/salud/img/3.jpg'></img>
                                {devolucionf}<br/>
                                1 - Declara un basta a los hábitos que te han venido teniendo hasta aquí, 
                                haz un cambio concreto comenzando por declararlo detalladamente y luego 
                                avanzá. <br/>
                                Una vez sostenido por un nivel completo te será más fácil sostenerlo e ir 
                                por otros. <br/>
                                2 - Haz ya mismo una consulta virtual a nuestra médico para recibir 
                                asistencia en cómo establecer un plan de vida saludable cuanto antes.
                            </div>       
                        )
                        }
                        {
                        devoluciong && (
                            <div className="alert alert-light">
                                <img align="right" src='https://www.sonria.com/test/salud/img/4.jpg'></img>
                                {devoluciong}
                            </div>       
                        )
                        }
                        {
                        devolucionh && (
                            <div className="alert alert-light">
                                <img align="right" src='https://www.sonria.com/test/salud/img/4-1.jpg'></img>
                                {devolucionh}
                            </div>       
                        )
                        }
                        {
                        devolucioni && (
                            <div className="alert alert-light">
                                <img align="right" src='https://www.sonria.com/test/salud/img/5.jpg'></img>
                                {devolucioni}
                            </div>       
                        )
                        }
                        {
                        devolucionj && (
                            <div className="alert alert-light">
                                <img align="right" src='https://www.sonria.com/test/salud/img/5-1.jpg'></img>
                                {devolucionj}
                            </div>       
                        )
                        }
                        <br/>
                        <div>
                        <button 
                        className='btn btn-dark btn-block mt-2'
                        type="submit"
                        >
                        Ver Resultado y Guardar Test
                        </button>
                        </div>
                        <br /><br />
                    </form>
                    </div>
                </div>
            </div> 
     </div>  
        </main>
    )
}

export default TestSalud