import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const Comprensivo = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])

    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <img src="https://app.sonria.tv/img/ternura-comprension.jpg" width="70px"></img><br/>
                <h4>Comprensión</h4>
                <b>¿Cuál es el Sentido de la Emoción que se nos dispara?</b><br/>
                El entendimiento nos libera del ego, es el centro a través del cual amamos, 
                del cual fluye la energía de la conexión de toda forma de vida. Cuanto más 
                presente está, mayor es nuestra capacidad de amar todo lo que observamos.
                    <br/><br/>
        
                <p className="peque"><i><b>Raíz de la palabra: </b>
                Del latín Comprehendere: entender, capturar completamente lo que se ha 
                dicho. Con = Unión, Prehendere = Atrapar.</i></p>
                <br/>
                <NavLink className="btn btn-info" href="/">Regresar</NavLink>
                </>
                )
            }
        </div>
        </main>
    )
}

export default withRouter(Comprensivo)