import React from 'react'
import {auth} from '../firebase'
import {withRouter} from 'react-router-dom'
import '../index.css';
import {NavLink} from 'react-bootstrap';

const Triste = (props) => {

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        if (auth.currentUser){
            console.log('Existe un usuario')
            setUser(auth.currentUser)
        } else {
            console.log('No existe un usuario')
            props.history.push('/login')
        }

    }, [props.history])

    return (
        <main>
        <div className="contenedor">
            {
                user && (
                <>
                <img src="https://app.sonria.tv/img/tristeza.jpg" width="70px"></img><br/>
                <h4>Tristeza</h4>
                <b>¿Cuál es el Sentido de la Emoción que se nos dispara?</b><br/>
                Estado natural o accidental de aflicción, autocompasión, melacolía, desaliento, 
                desesperanza, soledad, pena o duelo ante una pérdida.
                    <br/><br/>
    
                <p className="peque"><i><b>Raíz de la palabra: </b>
                Del Latín Tristis. Taeter (Ofensivo Repulsivo) según Pianigiani. Del Sánscrito 
                Trista (Rudo Áspero). El Lituano Trisztas (Nublado) y el Anglosajón 
                Theresostru (Oscuridad Melancolía).</i></p>
                <br/>
                <NavLink className="btn btn-info" href="/">Regresar</NavLink>
                </>
                )
            }
        </div>
        </main>
    )
}

export default withRouter(Triste)