import React from 'react'
import {auth, db, signInWithGoogle, signInWithFacebook} from '../firebase'
import {withRouter} from 'react-router-dom'

const Login = (props) => {

    const [email, setEmail] = React.useState('')
    const [pass, setPass] = React.useState('')
    const [error, setError] = React.useState(null)
    const [esRegistro, setEsRegistro] = React.useState(false)

    const procesarDatos = e => {
        e.preventDefault()
        if(!email.trim()) {
            console.log("Ingrese Email")
            setError('Ingrese Email')
            return
        }
        if(!pass.trim()) {
            console.log("Ingrese Contraseña")
            setError('Ingrese Contraseña')
            return
        }
        if(pass.length < 6) {
            console.log("La Contraseña es menor de 6 letras.")
            setError('La Contraseña es menor de 6 letras')
            return
        }
        console.log("Validado!")
        setError(null)

        if (esRegistro) {
            registrar()
        } else {
            login()
        }
    }

    const login = React.useCallback(async() => {
        try {
          const res = await auth.signInWithEmailAndPassword(email, pass)  
          console.log(res.user)
          setEmail('')
          setPass('')
          setError(null)
          props.history.goBack()
            
        } catch (error) {
            console.log(error)
            if(error.code === 'auth/invalid-email') {
                setError('Email no válido')
              }
            if(error.code === 'auth/user-not-found') {
                setError('Usuario no registrado')
              }
            if(error.code === 'auth/wrong-password') {
                setError('Contraseña incorrecta')
              }  
        }
    }, [email,pass,props.history])

    const registrar = React.useCallback(async() => {

        try {
          const res = await auth.createUserWithEmailAndPassword(email,pass)
          console.log(res.user)
          await db.collection('usuarios').doc(res.user.email).set({
              email: res.user.email,
              uid: res.user.uid
          })
          await db.collection(res.user.uid).add({
              name: "Meta de ejemplo",
              fecha: Date.now()
          })
          setEmail('')
          setPass('')
          setError(null)
          props.history.goBack()
            
        } catch (error) {
          console.log(error)
          if(error.code === 'auth/invalid-email') {
            setError('Email no válido')
          }
          if(error.code === 'auth/email-already-in-use') {
            setError('Email ya utilizado')
          } 
        }

    }, [email,pass,props.history])

    const accesoSocialg = React.useCallback(async() => {

        try {
            const res = await signInWithGoogle()
            console.log(res.user)
            await db.collection('usuarios').doc(res.user.email).set({
                email: res.user.email,
                uid: res.user.uid
            })
            
            
            setEmail('')
            setPass('')
            setError(null)
            setEsRegistro(esRegistro)
            props.history.goBack()
              
          } catch (error) {
            console.log(error)
          }
        
    }, [props.history])


    const accesoSocialf = React.useCallback(async() => {

        try {
            const res = await signInWithFacebook()
            console.log(res.user)
            await db.collection('usuarios').doc(res.user.email).set({
                email: res.user.email,
                uid: res.user.uid
            })
        
            setEmail('')
            setPass('')
            setError(null)
            setEsRegistro(esRegistro)
            props.history.goBack()
              
          } catch (error) {
            console.log(error)
          }
        
    }, [props.history])
    
    return (
        <main>
        <div className="contenedor">
            <h3 className="text-center">
            {
                esRegistro ? 'Registro de Usuario' : 'Acceso de Usuario'
            }    
            </h3> 
            <hr />
            <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-ux-4">
                <form onSubmit={procesarDatos}>
                    {
                        error && (
                            <div className="alert alert-danger">
                                {error}
                            </div>
                        )
                    }
                    <input 
                    type="email" 
                    placeholder="Ingresa un email" 
                    className="form-control mb-2"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    />
                    <input 
                    type="password" 
                    placeholder="Ingresa una contraseña" 
                    className="form-control mb-2"
                    onChange={e => setPass(e.target.value)}
                    value={pass}
                    />
                <button 
                className="btn btn-dark btn-lg btn-block" 
                type="submit"
                >
                    {
                        esRegistro ? 'Registrarse' : 'Acceder'
                    }
                </button>
                
                <button 
                className="btn btn-info btn-sm btn-block"
                type="button"
                onClick={() => setEsRegistro(!esRegistro)}
                >
                   {
                       esRegistro ? '¿Ya estás registrado?' : '¿No tienes cuenta?'
                   }
                </button>
                   {
                       !esRegistro ? (
                        <button 
                        className="btn btn-dark btn-sm btn-block" 
                        type="button"
                        onClick={() => props.history.push('/reset')}
                        >
                            Recuperar Contraseña
                        </button>

                       ) : null
                   }
                    {esRegistro ? (
                        <button 
                        className="btn btn-dark btn-danger btn-sm mt-2" 
                        type="button"
                        onClick={() => props.history.push('/reset')}
                        >
                            Recuperar Contraseña
                        </button>

                       ) : null
                    }
                   <br /> 
                   <button 
                   type="button" 
                   className="loginBtn loginBtn--google" 
                   onClick = {accesoSocialg}
                   //{signInWithGoogle}
                   >
                    Ingresar con Google
                    {console.log("Registrado: "+ esRegistro)}
                    
                   </button>
                   <button 
                   type="button" 
                   className="loginBtn loginBtn--facebook" 
                   onClick = {accesoSocialf}
                   //{signInWithFacebook}
                   >
                    Ingresar con Facebook
                    {console.log("Registrado: "+ esRegistro)}
                    
                   </button> 
                    
            
                </form>
                </div>
            </div>
        </div>
        </main>
    )
}

export default withRouter(Login)
